import { Platforms, Post, initRequestArgumentParamenters, slidersValue } from "../../utils/interfaces";
import { Avatar, Box, Button, Card, CardActions, CardHeader, Chip, Dialog, DialogActions, DialogTitle, Divider, IconButton, Slider, TextField, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material";
import PostEmbbding from "../Shared/PostEmbbeding";
import { useEffect, useState } from "react";
import { DesignIsraelChip, DesignViralChip, handleMaxRow, printVirality, recognizePlatform } from "../../utils/postUtils";
import FightIcon from '@mui/icons-material/Whatshot';
import { AnswerCardStyles, CardActionStyles } from "../Feed/FeedStyle";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { extractTwitterID } from "../../utils/PostCardHelperFunctions";
import { DescDialog } from "../PostsCard/FeedPostCard";
import RefreshIcon from '@mui/icons-material/Refresh';
import { generateCounterArgument } from "../../utils/generateContent";
import { useAuth0 } from "@auth0/auth0-react";
import { addLogsToDatabase, addPostToDatabase, addUserToDatabase } from "../../utils/webServices";
import SliderControls from "./SliderControls";
import { LOGSTYPE, POSTS_DB, POSTS_DB_CONTAINER, USERS_COMMENTS_CONTAINER } from "../../utils/constant";

interface Props {
    handleFlip: () => void,
    isMobile: boolean,
    post: Post,
    onDialogOpen: (state: boolean, id: number) => void,
    isOpen: boolean
}
interface AnswerLog {
    text: string,
    slidersValue: slidersValue
}
const FeedAnswerCard = ({ handleFlip, isMobile, isOpen, post, onDialogOpen }: Props) => {
    const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: theme.palette.common.white,
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: theme.shadows[1],
          fontSize: 11,
        },
      }));
      
    const { user, isAuthenticated } = useAuth0();
    const [responseText, setResponseText] = useState("Loading Response...");
    const [previousResponses, setPreviousResponses] = useState<AnswerLog[]>([]);

    let virality = printVirality(post.viralityScore || 0);
    const styles = CardActionStyles;

    const handleBackToPostClick = () => {
        handleFlip() // set the dialog to false
        onDialogOpen(false, post.id); // unblur everything
    }

    const [isLoading, setIsLoading] = useState(true);
    const [sliderValue, setSliderValue] = useState<slidersValue>({
        toneValue: 2,
        lengthValue: 1,
        languageStyleValue: 1,
        communicationToneValue: 2
    })

    const handlePostSent = (url: string, platform: string) => {
        if (isAuthenticated && user) {
            addPostToDatabase(
                {
                    ...post,
                    id: Date.now().toString(),
                    post_id: post.id.toString(),
                    responseText: responseText,
                    user: user ?? 'not found',
                    previousResponses: previousResponses
                }, USERS_COMMENTS_CONTAINER, POSTS_DB)

            addLogsToDatabase({
                ...post,
                id: Date.now().toString(),
                user: user,
                previousResponses: previousResponses,
                post_id: post.id.toString(),
                responseText: responseText}, LOGSTYPE.COMMENT)
            addUserToDatabase(user?.email ?? 'not found', POSTS_DB_CONTAINER, POSTS_DB, post.id)
        }

        if (platform == Platforms.instagram) window.open(url, "_blank")
        if (platform == Platforms.twitter) {
            url = "https://twitter.com/intent/tweet?in_reply_to="
                + extractTwitterID(url) + "&text=" + responseText;
            window.open(url, "_blank")
        }
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(responseText)
            .then(() => {
            })
            .catch(err => console.error('Failed to copy text: ', err));
    };

    const handleRegenerate = async () => {
        setIsLoading(true);
        setResponseText("Loading ...");
        try {
            const result = await generateCounterArgument(
                initRequestArgumentParamenters({
                    "post-text": post.text,
                    "response-length": sliderValue.lengthValue,
                    "response-audience": sliderValue.toneValue,
                    "response-is-pro-israel": post.proIsrael || false,
                    "platform-name": recognizePlatform(post.url),
                    "response-tone": sliderValue.communicationToneValue,
                    "response-formality": sliderValue.languageStyleValue,
                    "post-description": post.img_description ?? ""
                })
            );
            setResponseText(result);
            setPreviousResponses([...previousResponses,
                 {text:result, slidersValue: 
                {
                    toneValue: sliderValue.toneValue,
                    lengthValue: sliderValue.lengthValue,
                    languageStyleValue: sliderValue.languageStyleValue,
                    communicationToneValue: sliderValue.communicationToneValue
                 }}])
            console.log(previousResponses);
        } catch (error: any) {
            setResponseText(error as string);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isOpen && responseText === "Loading Response...") {
            console.log(`"post-text": ${post.text},
            "response-length": ${sliderValue.lengthValue},
            "response-audience": ${sliderValue.toneValue},
            "response-is-pro-israel": ${post.proIsrael} || false,
            "platform-name": ,
            "response-tone": ${sliderValue.communicationToneValue},
            "response-formality": ${sliderValue.languageStyleValue},
            "post-description": post.img_description ?? ""
        `)
            const fetchData = async () => {
                const data = await generateCounterArgument(initRequestArgumentParamenters({
                    "post-text": post.text,
                    "response-length": sliderValue.lengthValue,
                    "response-audience": sliderValue.toneValue,
                    "response-is-pro-israel": post.proIsrael || false,
                    "platform-name": recognizePlatform(post.url),
                    "response-tone": sliderValue.communicationToneValue,
                    "response-formality": sliderValue.languageStyleValue,
                    "post-description": post.img_description ?? ""
                }));
                setIsLoading(false);
                setResponseText(data);
                setPreviousResponses([...previousResponses, {text:data, slidersValue: sliderValue}])
            }
            fetchData()

        }
    }, [isOpen])

    const handleHelpPostClick = () => {
        copyToClipboard()
        post.isSent = true
        if(isMobile){ handleFlip()}
        onDialogOpen(false, post.id);
        setTimeout(() => {
            handlePostSent(post.url, recognizePlatform(post.url))
        }, 100);
    };

    const classes = AnswerCardStyles;
    return (
        <Box sx={isMobile ? {} : {
            padding: '5px', display: 'flex',
            justifyContent: 'space-around', backgroundColor: 'white'
        }}>
            
            <Box
            sx={isMobile ? classes.MobileOuterCard : classes.outerCard}>
                <Box>

                    <Box sx={isMobile ? {
                        display: 'flex' , width:'100%',flexDirection: "column",
                        alignItems: "flex-start", justifyContent: 'space-between'
                    } : classes.CardContent}>

                        <Box  sx ={isMobile ? {
                        
                        width:'100%'}: {}} >
                            {
                            isMobile && 
                            <Box sx = {{justifyContent:'space-between', display:'flex',flexDirection:'row' }}>

                            <Button onClick={handleBackToPostClick} startIcon={<ArrowBackIcon />} sx={{ color: 'black', fontWeight: 'bold', alignItems: "stretch", p: '15px' }}>See Post </Button>
                            {DescDialog(post, isMobile)}
                            
                            </Box>
                            }

                            {!isMobile && <Box>
                                <Chip
                                    icon={<FightIcon sx={{ color: DesignIsraelChip(post.proIsrael)[0] }} />}
                                    variant="outlined"
                                    label={post.proIsrael ? "Pro-Israel" : "Anti-Israel"}
                                    color="error"
                                    size="small"
                                    sx={{
                                        color: DesignIsraelChip(post.proIsrael)[0],
                                        background: DesignIsraelChip(post.proIsrael)[1],
                                        borderRadius: '3px',
                                        borderWidth: '0px',
                                        ml: 1,
                                    }}
                                />
                                <Chip
                                    icon={<FightIcon sx={{
                                        color: DesignViralChip(virality[0])[0]
                                    }} />}
                                    color="error"

                                    variant="outlined"
                                    label={virality[0]}
                                    size="small"
                                    sx={{
                                        color: DesignViralChip(virality[0])[0],
                                        background: DesignViralChip(virality[0])[1],
                                        borderRadius: '3px',
                                        borderWidth: '0px',
                                        ml: 1,
                                    }}
                                />
                            </Box>}
                        </Box>

                        <Box>
                        </Box >

                        <Box sx={isMobile ?
                            { width: '95%', padding: '10px 10px 0 10px', position: 'relative' } :
                            { padding: '10px 10px 0 10px', position: 'relative' }
                        }>
                            <TextField
                                sx={{
                                    width: '100%',
                                    ...(isLoading && {
                                        animation: 'blink 3s linear infinite',
                                        '@keyframes blink': {
                                            '0%': { opacity: 1 },
                                            '50%': { opacity: 0 },
                                            '100%': { opacity: 1 },
                                        },
                                    })
                                }}
                                inputProps={{ style: { fontSize: 14 } }}
                                label="Suggested Comment"
                                multiline
                                maxRows={handleMaxRow()}
                                minRows={handleMaxRow()}
                                value={isLoading ? "Loading Response..." : responseText}
                                onChange={(e) => setResponseText(e.target.value)}
                            />

                            <SliderControls isLoading={isLoading} sliderValue={sliderValue} setSliderValue={setSliderValue} />

                        </Box >
                    </Box>
                </Box>

                <CardActions sx={isMobile ? styles.AnswerCardActions : styles.cardActions}>

                    <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>

                        <Button
                            sx={{ height: '70%', fontSize: isMobile ? "10px" : "12px" }}
                            size="small"
                            variant="contained"
                            onClick={handleHelpPostClick}>
                            Copy & Engage
                        </Button>

                        <Button
                            variant="contained"
                            size="small"
                            sx={{ height: '70%', marginLeft:'20px', fontSize: isMobile ? "10px" : "12px" }} onClick={() => handleRegenerate()}
                            startIcon={!isMobile &&
                                <RefreshIcon />}>
                            Generate Response
                        </Button>
                    </Box>
                </CardActions>

            </Box >

            {!isMobile && <Box
                sx={classes.postEmbedContainer}>
                <Box className="custom-scrollbar" sx={classes.EmbededPost}>
                    <PostEmbbding key={post.url + "inside"} isAlone={true} url={post.url} />
                </Box>
            </Box>}

        </Box>
    );
}


export default FeedAnswerCard