import { Box, CircularProgress, Typography } from "@mui/material";

interface LoadingIndicatorProps {}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({})=>{
    return (
    <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: 'linear-gradient(45deg, #6CC1FF, #3A8DFF)',
        color: '#333333'
    }}>
        <Box sx={{
            padding: '20px',
            borderRadius: '10px',
            backgroundColor: 'rgba(255, 255, 255, 0.2)', // Semi-transparent white overlay
            backdropFilter: 'blur(5px)', // Frosted glass effect
            marginBottom: '20px',
            animation: 'pulse 2s ease-in-out infinite',
            '@keyframes pulse': {
                '0%, 100%': { transform: 'scale(1)', opacity: 1 },
                '50%': { transform: 'scale(1.1)', opacity: 0.7 },
            }
        }}>
            <Typography variant="h5" sx={{ textAlign: 'center', my: 4 }}>
                Loading posts...
            </Typography>
        </Box>
        <CircularProgress size={60} thickness={4} sx={{ color: '#2a3eb1' }} />
    </Box>)
}
export default LoadingIndicator;
