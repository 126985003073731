import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Post, TranslatedTexts, TranslationResponse } from "../../utils/interfaces";
import PostEmbbding from "../Shared/PostEmbbeding";
import TranslationSuggestion from "./TranslationSuggestion";
import { Languages } from "../../utils/constant";
import './styles.css';
import LanguageSelector from "./TranslationSelection";
import { fetchPostDetails } from "../../utils/webServices";
import { detectLanguage, generateTranslationFromEnglish, generateTranslationToEnglish } from "../../utils/generateContent";
import { getLanguageCode } from "../../utils/postUtils";

interface Props {
  post: Post;
  onSend: () => void;
  onSkip: () => void;
}

const TranslatePost = ({ onSend, onSkip, post }: Props) => {

  const emptyTranslation: TranslatedTexts = {
    Origin: {
      Language: "English",
      Text: "Error: not found"
    },
    Translations: {
      Language: '', // Initially empty, will be set after translation
      Text: ''
    }
  }

  const [isLoadingTranslation, setIsLoadingTranslation] = useState(false);
  const [translation, setTranslation] = useState(emptyTranslation);
  const [selectedLanguage, setSelectedLanguage] = useState('English'); // selected language is the language the user choost to translate to
  const [originLang, setOriginLanguage] = useState('English');//this holds the original language
  const languagesArray = Object.keys(Languages);

useEffect(() => {
    const fetchDetailsAndUpdateTranslation = async () => {
      setIsLoadingTranslation(true); 
      try {

        const details = (await fetchPostDetails(post.url)).text
        const detectedLanguage = await detectLanguage(details);
        const originLang = detectedLanguage.language; 
        
        let translationText = details;
        
        if (selectedLanguage !== detectedLanguage.language) {
          const translationResponse: TranslationResponse = selectedLanguage === 'English' 
          ? await generateTranslationToEnglish(details)
          : await generateTranslationFromEnglish(details, getLanguageCode(selectedLanguage));
          translationText = translationResponse.translation_text;
        }
        setTranslation({
          Origin: {
            Language: originLang,
            Text: details
          },
          Translations: {
            Language: selectedLanguage,
            Text: translationText
          }
        });

        post.TranslatedTexts = {
          Origin: {
            Language: detectedLanguage.language,
            Text: details
          },
          Translations: {
            Language: '', // Initially empty, will be set after translation
            Text: ''
          }
        };

        setOriginLanguage(detectedLanguage.language);
      
      } catch (error) {
        console.error('Error:', error);
        setTranslation(emptyTranslation);
      }
      setIsLoadingTranslation(false); // Reset loading state when done
    };
    fetchDetailsAndUpdateTranslation();
    
  }, [post, selectedLanguage]);
return (

  <Box sx={{ justifyContent: 'space-around', display: 'flex', flexDirection: { sm: 'row', xs: 'column' } }}>
    <Box
      className="custom-scrollbar" sx={{
        width: { xs: '500px', sm: '45%' },
        maxWidth: { xs: '95%', sm: '500px' },
        overflowY: 'scroll',
        overflowX: 'hidden'
      }}>
     <PostEmbbding key={post.id} isAlone = {true} url={post.url} />
    </Box>

    <Box sx={{
      width: { xs: '500px', sm: '45%' },
      maxWidth: { xs: '90%', sm: '500px' },
      alignSelf: { xs: 'center', sm: '' },
      display: 'flex',
      flexDirection: 'column', justifyContent: 'space-around'
    }}>
      <LanguageSelector
        originLang={originLang}
        setOriginLanguage={setOriginLanguage}
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
        languagesArray={languagesArray}
      />

      <TranslationSuggestion isLoadingTranslation = {isLoadingTranslation} onSend={onSend} TranslatedTexts={translation} SelectedLang={selectedLanguage} OriginLang={post.TranslatedTexts?.Origin.Language || 'English'} />
      <Box sx={{ margin: '3% 3% 3% 0%', display: 'flex' }}>
        <Button onClick={onSkip} variant="contained">Skip to Another Post</Button>
      </Box>

    </Box>
  </Box>
);
};

export default TranslatePost;
