import React, { useState, ChangeEvent } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Select, MenuItem, InputLabel, FormControl, Box, CircularProgress, SelectChangeEvent } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
interface AddReportModalProps {
    isReportModalOpen: boolean;
    REPORT_OPTIONS: string[];
    handleReport: (reportData: ReportData) => Promise<void>;
    closeReportModal: () => void;
}

export interface ReportData {
    option: string;
    description: string;
    file?: File | null;
}

const AddReportModal: React.FC<AddReportModalProps> = ({
    isReportModalOpen,
    REPORT_OPTIONS,
    handleReport,
    closeReportModal
}) => {
    const [selectedOption, setSelectedOption] = useState(REPORT_OPTIONS[0] || '');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState<File | null>(null);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [isUpload, setIsUpload] = useState(false);

    const handleOptionChange = (event: SelectChangeEvent<string>) => {
        setSelectedOption(event.target.value);
    };

    const handleCancel = ()=> {
        setDescription('');
        setFile(null);
        setIsLoadingSubmit(false);
        closeReportModal();
    }

    const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value);
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setFile(event.target.files[0]);
            console.log('saved');
            setIsUpload(true);
        }
    };

    const handleSubmit = async () => {
        setIsLoadingSubmit(true);
        console.log('sumbitting...');
        await handleReport({ option: selectedOption, description, file });
        console.log('sumbitting... succseeded');

        setSelectedOption(REPORT_OPTIONS[0]);
        setDescription('');
        setFile(null);
        setIsLoadingSubmit(false);
        setIsUpload(false)
        closeReportModal();
    };

    return (
        <Dialog 
        fullWidth={true}
        sx= {{'& .MuiDialog-paperFullWidth': {
            maxWidth:'450px'
        }}}
        open={isReportModalOpen} onClose={closeReportModal}>
            <DialogTitle>Report an Issue</DialogTitle>
            <DialogContent>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Report Reason</InputLabel>
                    <Select
                        value={selectedOption}
                        label="Report Reason"
                        onChange={handleOptionChange}
                    >
                        {REPORT_OPTIONS.map((option, index) => (
                            <MenuItem key={index} value={option}>{option}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    margin="dense"
                    id="description"
                    label="Describe the Issue"
                    type="text"
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    value={description}
                    onChange={handleDescriptionChange}
                />

                <Button
                    variant="contained"
                    component="label"
                    sx={{ mt: 2 }}
                >
                    Upload File
                    <input
                        type="file"
                        hidden
                        onChange={handleFileChange}
                    />
                </Button>
                {isUpload && <CheckCircleOutlineIcon sx = {{color:'green', ml:'15px', position:'fixed'}} />}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleSubmit} disabled={isLoadingSubmit}>
                    {isLoadingSubmit ? <CircularProgress size={24} /> : 'Submit'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddReportModal;
