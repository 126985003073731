import "./App.css";
import NavBar from "./components/Shared/NavBar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Translate from "./components/Translate/Translate";
import { Box } from "@mui/material";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import LoginPage from "./components/SitePages/LoginPage";
import Feed from "./components/Feed/Feed";
import AboutPage from "./components/AboutPage/AboutPage";
import LogsPage from "./components/LogsPage/LogsPage";
import MainSite from "./components/MainSite/MainSite";
import GPTAssistant from "./components/Shared/assistantPage";
import { POSTS_DB, POSTS_DB_CONTAINER } from "./utils/constant";
import CampaignPage from "./components/CampaignPage/components/CampaignPage";
import DemoCampaignPage from "./components/Demo/components/CampaignPage";

function App() {
  return (
    <Routes>
      <Route
        path="Translate"
        element={
          <Box sx={{ flexGrow: 1, overflow: "auto" }}>
            <ProtectedRoute>
              <NavBar />
              <Translate
                dataBaseUrlParam={{
                  container: "NovartisTweet",
                  database: POSTS_DB,
                }}
              />
            </ProtectedRoute>
          </Box>
        }
      />
      <Route path="About" element={<AboutPage />} />
      <Route
        path="AssistantText"
        element={
          <>
            <GPTAssistant />
          </>
        }
      />
      <Route
        path="/"
        element={
          <>
            <LoginPage />
          </>
        }
      />

      <Route
        path="Feed"
        element={
          <Feed
            dataSource={{
              dataBaseUrlParam: {
                container: POSTS_DB_CONTAINER,
                database: POSTS_DB,
              },
            }}
          />
        }
      />
      <Route
        path="MainSite"
        element={<CampaignPage userId="43efffe1-d5e0-4309-a0cd-295a79bdf64b" />}
      />
      <Route path="Demo" element={<DemoCampaignPage />} />
      <Route
        path="Logs"
        element={
          <ProtectedRoute>
            <LogsPage />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
export default App;
