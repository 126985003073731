import { theme } from "../../theme";
import { makeStyles } from "@mui/material";
export const useStyles = {
  appBar: {
    background: 'rgba(255,25,21,)',
  },
  title: {
    width: '100px',
    flexGrow: 1,
    fontWeight: 'bold',
    color: '#FFF'
  },
  navButton: {
    background: 'black',
    m: '5px',

    color: '#FFF',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
  },
};
export const CommentsStyle = {
  headerCell: {

  },
  tableContainer: {

  }, 
  row: {

  }, 
  detailText: {
    
  }
}
export const CardActionStyles = {
  outerCard: {
    borderRadius: 2,
    boxShadow: 3,
    m: 3,
    position: 'relative',
    width: { xs: '90vw', sm: '45vw', md: '30vw', lg: '25vw' }
  },

  postAnimation: {
    animation:
     'fadeIn 2s ease-in-out',
     '@keyframes fadeIn': {
       from: { opacity: 0 },
       to: { opacity: 1 },
     }


  },
    cardHeader: {
      padding: "10px",
      display: "flex",
      alignItems: "center"
    },
    cardActions: {
      
      justifyContent: "space-between"
    },

    AnswerCardActions: {
      display: 'flex',
    },

    likeSection: {
      display: 'flex',
      alignItems: 'center',
    },

    likeIcon: {
      marginRight: { xs: "1px", sm: "0px", md: "4px" },
      width: { xs: "10px", sm: "15px", md: "25px" }
    },

    platformTag: {
      display: 'inline-flex',
      alignItems: 'center',
      marginLeft: '8px',
      borderRadius: '16px',
      padding: '4px 8px',
      color: '#BAB9B9',
    },

    fightBackButton: {
      backgroundColor: '#2962ff',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#0039cb',
      },
      textTransform: 'none',
    },
    showCommentsButton: {
      backgroundColor: '#cdd6ee',
      color: '#000000',
      '&:hover': {
        backgroundColor: '#e2e2e4',
      },
      textTransform: 'none',
    },
    SubmitButton: {
      backgroundColor: 'black',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: 'rgb(73, 68, 68)',
      },
      marginRight: '5px',

    },
    ReportButton: {
      backgroundColor: 'rgb(43, 0, 255)',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: 'hsl(250, 100.00%, 67.50%)',
      },
      marginRight: '5px',
    },
    Chip: {
      borderRadius: '3px',
      borderWidth: '0px',
      ml: 1,
    },
    EmbededPost: {
      padding: '10px 10px 10px 10px',
      position: 'relative',
      width: "100%",
      height: { xs: `60vh`, sm: `55vh` }
    },
    EmbededResponsiveHeight: {
      height: { xs: `55vh`, sm: `55vh` },
      width: '95%',
      marginLeft: '2.5%'
    },
    ResponsiveHeight: {
      height: { xs: `55vh`, sm: `55vh` },
    }
  };
  export const dialogWidth = { xs: '70vw', sm: '65vw', md: '60vw', lg: '60vw' }

export const AnswerCardStyles = {
    textField: {
      fontSize: '50px'
    },
    sliderMarkStyle: {
      paddingTop: '5px',
      color: "#acaaaa",
      top: { xs: '35px', sm: '20px' }
    },
    outerCard: {
      display: 'flex',
      borderRadius: 2,
      boxShadow: 3,
      m: 3,
      width: '50%',
      flexDirection: "column",
      justifyContent: "space-between",
      maxWidth: '550px',
      marginButtom: '10px',
      height: '80%'
    },
    CardContent: {
      display: "flex",
      flexDirection: "column",
    },
    MobileOuterCard: {
      backgroundColor: 'white',
      borderRadius: 2,
      boxShadow: 3,
      m: 3,
      width: '90vw',
      
    },
    cardHeader: {
      padding: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around"
    },
    cardActions: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: '30px 16px'
    },
    postEmbedContainer: {
      width: '40%',
      m: '24px',
      height: '550px'
    },
    likeSection: {
      display: 'flex',
      alignItems: 'center',
    },

    likeIcon: {
      marginRight: '4px',
    },

    platformTag: {
      display: 'inline-flex',
      alignItems: 'center',
      marginLeft: '8px',
      borderRadius: '16px',
      padding: '4px 8px',
      color: '#BAB9B9',
    },

    fightBackButton: {
      backgroundColor: '#2962ff',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#0039cb',
      },
      textTransform: 'none',
    },

    SubmitButton: {
      backgroundColor: 'black',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: 'rgb(73, 68, 68)',
      },
      marginRight: '5px',
    },
    Chip: {
      fontSize: { xs: "", sm: "10px" },
      borderRadius: '3px',
      borderWidth: '0px',
      ml: 1,
    },
    EmbededPost: {
      height: '100%', width: "100%"
    },
    EmbededResponsiveHeight: {
      height: { xs: `60vh`, sm: `60vh` },
      width: '95%',
      marginLeft: '2.5%'
    },
    ResponsiveHeight: {
      height: { xs: `60vh`, sm: `60vh` },
    }
  }
