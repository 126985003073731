import { Box, Card, CardContent, Typography } from "@mui/material";
import PostEmbbding from "../../Shared/PostEmbbeding";
import {
  capitalizeFirstLetter,
  recognizePlatform,
} from "../../../utils/postUtils";
import { postCampaignCardStyles } from "./CampaignStyles";
import { FacebookPost } from "../../../utils/interfaces";

interface props {
  url: string;
  post: FacebookPost;
}

const PostCampaignCard = ({ url, post }: props) => {
  const styles = postCampaignCardStyles;

  return (
    <Card raised elevation={24} sx={styles.outerCard}>
      <CardContent sx={styles.cardContent}>
        <Box sx={styles.embededPost}>
          <Box className="custom-scrollbar">
            {url && <PostEmbbding key={url} url={url} post={post} />}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PostCampaignCard;
