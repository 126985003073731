import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme'; // Importing theme from the same folder
import { AppState, User } from '@auth0/auth0-react'; // If AppState and User are exported by @auth0/auth0-react

const domain = process.env.REACT_APP_AUTH0_DOMAIN as string;
const clientId = process.env.REACT_APP_AUTH0_CLIENT as string;
const onRedirectCallback = (appState?: AppState, user?: User) => {
  window.history.replaceState(
    {},
    document.title,
    appState?.returnTo || window.location.origin + '/Feed'
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider
        domain={domain} 
        clientId={clientId}
        onRedirectCallback={onRedirectCallback}
        authorizationParams={{
          redirect_uri: window.location.origin + '/Feed'
        }}
        >
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Auth0Provider>
    </BrowserRouter>
  </React.StrictMode>
);
