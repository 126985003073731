import { useEffect, useState } from "react";
import { FacebookPost, FetchedAgent, Platforms, slidersValue } from "../../../utils/interfaces";
import { Alert, Box, Button, Card, CardContent, CircularProgress, Snackbar, Stack, TextField, Typography } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import { sendFacebookComment } from "../SocialMediaService/facebookActions";
import { mapFacebookPost } from "../EndpointService/mapCommentSchema";
import SliderControls from "./CampaignSliderControls";
import { campaignAnswerStyles } from "./CampaignStyles";
import { recognizePlatform } from "../../../utils/postUtils";
import { extractTwitterID } from "../../../utils/PostCardHelperFunctions";
import { Agent } from "../CampaignInterface";
import { convertSliderValue, facebookPostToThread } from "../EndpointService/CampaignUtils";
import { generateResponse } from "../EndpointService/ResponseService";
import { USER_INSTRUCTIONS } from "../CampaignConst";

interface AnswerLog {
  text: string,
  slidersValue: slidersValue
}

interface prop {
  potentialAnswer: string | undefined,
  isPrepeared: boolean | undefined,
  campaignId: string;
  agent: Agent;
  commentId: string,
  isOpen: boolean,
  author: { name: string, id: string },
  content: string,
  post: FacebookPost
}

const CampaignAnswer = ({ isPrepeared, potentialAnswer, agent, campaignId, commentId, author, isOpen, content, post }: prop) => {
  const [isCommentSending, setIsCommentSending] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseText, setResponseText] = useState("");
  const [userInstructionText, setUserInstructionText] = useState("");


  const [sliderValue, setSliderValue] = useState<slidersValue>({
    toneValue: 2,
    lengthValue: 3,
    languageStyleValue: 1,
    communicationToneValue: 1
  });
  const [previousResponses, setPreviousResponses] = useState<AnswerLog[]>([]);
  const styles = campaignAnswerStyles;

  const handleRegenerate = async () => {
    console.log(userInstructionText);
    setIsLoading(true);
    setResponseText("Loading ...");
    try {

      //   if (isPrepeared && potentialAnswer) {
      //     setTimeout(() => { setResponseText(potentialAnswer) }, 3000
      //     )
      //   }

      // else {

      const result = await generateResponse({
        userId: agent.agentCreatorId,
        campaignId: campaignId,
        agentId: agent.agentId,
        threadId: 'no',
        provided_thread: facebookPostToThread(post),
        responseParameters: convertSliderValue(sliderValue, userInstructionText ? userInstructionText : USER_INSTRUCTIONS),
        targetId: commentId
      }
      )

      console.log(responseText)
      setResponseText(result.content);
      setPreviousResponses([...previousResponses, {
        text: result.content,
        slidersValue: {
          toneValue: sliderValue.toneValue,
          lengthValue: sliderValue.lengthValue,
          languageStyleValue: sliderValue.languageStyleValue,
          communicationToneValue: sliderValue.communicationToneValue
        }
      }]);
    } catch (error: any) {
      console.log(responseText)
      console.log(error)

      setResponseText(error as string);
    } finally {
      setIsLoading(false);
    }
  };


  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleHelpPostClick = () => {
    copyToClipboard()
    setTimeout(() => {
      handlePostSent(post.url, recognizePlatform(post.url))
    }, 100);
  };

  const handlePostSent = (url: string, platform: string) => {
    console.log(url);
    console.log(platform);

    if (platform == Platforms.instagram || platform == Platforms.facebook) window.open(url, "_blank")
    if (platform == Platforms.twitter) {
      url = "https://twitter.com/intent/tweet?in_reply_to="
        + extractTwitterID(url) + "&text=" + responseText;
      window.open(url, "_blank")
    }
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(responseText)
      .then(() => {
      })
      .catch(err => console.error('Failed to copy text: ', err));
  };


  const handleComment = () => {
    setIsCommentSending(true);
    sendFacebookComment(responseText, commentId, () => {
      setIsCommentSending(false);
      setSnackbarOpen(true);
    });
  };

  useEffect(() => {
    console.log(sliderValue)
  }, [sliderValue])
  return (
    <Card sx={styles.card}>
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="h6">Generate Response</Typography>
          <Box sx={styles.contentContainer}>
            <Box sx={styles.sliderControlsContainer}>
              <SliderControls isLoading={isLoading} sliderValue={sliderValue} setSliderValue={setSliderValue} />

              <TextField
                sx={{ ml: '7%', width: '80%', mt: '10px' }}
                label="Insert User Instructions"
                multiline
                placeholder={USER_INSTRUCTIONS} 
                focused 
                minRows={5}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  console.log(event.target.value);
                  setUserInstructionText(event.target.value)}} />
            </Box>
            <Box sx={styles.textFieldContainer}>
              <TextField
                sx={{

                  ...(isLoading && styles.loadingAnimation)
                }}
                inputProps={{ style: { fontSize: 12 } }}
                label="Press Generate Response To View Suggested Comment"
                multiline
                minRows={5}
                value={isLoading ? "Loading Response..." : responseText}
                onChange={(e: any) => setResponseText(e.target.value)}
              />
              <Box sx={styles.buttonContainer}>
                <Button
                  color="secondary"
                  size="small"
                  variant="contained"
                  sx={styles.generateButton}
                  onClick={handleRegenerate}
                  startIcon={<RefreshIcon />}
                >
                  Generate Response
                </Button>
                <Button
                  sx={styles.replyButton}
                  variant="contained"
                  size="small"
                  onClick={handleHelpPostClick}
                >
                  {isCommentSending ? <CircularProgress color="secondary" size={24} /> : "Reply"}
                </Button>
              </Box>
            </Box>
          </Box>
        </Stack>
      </CardContent>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Comment sent successfully!
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default CampaignAnswer;