import { useCallback, useState } from 'react';
import { FACEBOOK_POSTS } from '../../../../utils/constant';
import { FacebookPost } from '../../../../utils/interfaces';

export const useFetchPosts = () => {
    const [posts, setPosts] = useState<FacebookPost[]>(FACEBOOK_POSTS);
    const [isLoading, setIsLoading] = useState(false);

    const fetchPosts = useCallback(async (pageAccessToken: string, pageId: string) => {
        if(!pageAccessToken) {
            console.log('Access Token is required.');
            return;
        }
        console.log(`pageId is ${pageId}`);
        setIsLoading(true);
        window.FB.api(
            `/${pageId}/posts`,
            'GET',
            { access_token: pageAccessToken, fields: 'id, like_count, message, permalink_url, comments{message, id, from, like_count, comment_count, comments{message, id, from, like_count}}' },
            function (response: any) {
              if (response && response.data) {
                const fetchedPosts: FacebookPost[] = response.data.map((post: any) => ({
                  id: post.id,
                  message: post.message,
                  url: post.permalink_url,
                  commentsCount: post.comments ? post.comments.data.length : 0,
                  comments: post.comments ? post.comments.data.map((comment: any) => ({
                    content: comment.message,
                    id: comment.id,
                    author: comment.from ? { name: comment.from.name, id: comment.from.id } : { name: "", id: -1 },
                    likes: comment.like_count,
                    commentCount: comment.comments ? comment.comments.data.length : 0,
                    comments: comment.comments ? comment.comments.data.map((nestedComment: any) => ({
                      content: nestedComment.message,
                      id: nestedComment.id,
                      author: nestedComment.from ? { name: nestedComment.from.name, id: nestedComment.from.id } : { name: "", id: -1 },
                      likes: nestedComment.like_count,
                    })) : [],
                  })) : [],
                }));
                console.log(fetchedPosts);
                setPosts(fetchedPosts);
              } else {
                console.log('Failed to fetch posts.');
              }
              setIsLoading(false);
            }
          );
        }, []);
      

    return { fetchPosts, posts, isLoading };
};
