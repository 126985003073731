import React from 'react';
import { Autocomplete, Box, TextField } from "@mui/material";

interface LanguageSelectorProps {
  originLang: string;
  setOriginLanguage: (language: string) => void;
  selectedLanguage: string;
  setSelectedLanguage: (language: string) => void;
  languagesArray: string[];
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  originLang,
  setOriginLanguage,
  selectedLanguage,
  setSelectedLanguage,
  languagesArray
}) => {
  return (
    <Box sx={{
      marginBottom: '1%', 
      display: 'flex',
      flexDirection: {sm:'row', xs:'column'}, 
    }}>
      <Autocomplete
        key={1}
        value={originLang}
        onChange={(event: any, newValue: string | null) => {
          if (newValue) {
            setOriginLanguage(newValue);
          }
        }}
        inputValue={originLang}
        onInputChange={(event, newValue) => {
          setOriginLanguage(newValue);
        }}
        id="origin-lang-autocomplete"
        options={["English"]}
        sx={{ width:{sm:150,  xs:'90%'}, marginRight: {sm:'5%', xs:''}, marginBottom:{sm:'', xs:'5%'}}}
        renderInput={(params) => <TextField {...params} label="Origin Language" />}
      />

      <Autocomplete
        key={2}
        value={selectedLanguage}
        onChange={(event: any, newValue: string | null) => {
          if (newValue) {
            setSelectedLanguage(newValue);
          }
        }}
        inputValue={selectedLanguage}
        onInputChange={(event, newValue) => {
          setSelectedLanguage(newValue);
        }}
        id="selected-lang-autocomplete"
        options={languagesArray}
        sx={{ width:{sm:150, xs:'90%'} }}
        renderInput={(params) => <TextField {...params} label="Translate To" />}
      />
    </Box>
  );
};

export default LanguageSelector;
