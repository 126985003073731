import React from 'react';
import { Toolbar, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import logo from '../../assets/logo.png';
import { LogoutOptions } from '@auth0/auth0-spa-js';
import { useStyles } from '../Feed/FeedStyle';
import { useNavigate } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import { Login } from '@mui/icons-material';

interface Props {
    isMobile: boolean,
    logout: (options?: LogoutOptions | undefined) => Promise<void>
}

const AboutHeader = ({ isMobile, logout }: Props) => {
    const {isAuthenticated} = useAuth0();
    const theme = useTheme();
    const classes = useStyles; // If you have specific styles for the header, consider creating a separate style file.
    const navigate = useNavigate();
    return (
        <Toolbar>
            <Typography variant="h6" sx={classes.title}>
                <img style={{ width: `${isMobile ? '120px' : '200px'}` }} src={logo} alt="Logo"></img>
            </Typography>

            <Button size={isMobile ? 'small' : 'large'} onClick = {()=>navigate("/Feed")} sx={classes.navButton}>Back To {isAuthenticated? 'Feed' : 'Login'}</Button>
            {isAuthenticated && <Button size={isMobile ? 'small' : 'large'} onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
                sx={classes.navButton}>Log out</Button>}
        </Toolbar>
    );
};

export default AboutHeader;
