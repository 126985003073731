import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { FacebookComment, FacebookPost, FetchedAgent } from "../../../utils/interfaces";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import { treeCommentTableStyles } from "./CampaignStyles";
import { Agent } from "../CampaignInterface";

interface Props {
  post: FacebookPost;
  selectedAgent: Agent;
  campaignId: string;
  selectedComment: FacebookComment | null | undefined;
  setSelectedComment: React.Dispatch<React.SetStateAction<FacebookComment | null | undefined>>;
}

const renderTree = (comments: FacebookComment[]) =>
  comments.map((comment) => (
    <TreeItem key={comment.id} itemId={comment.id} label={
      <Box>
        <Typography component="span" variant="body1" sx={treeCommentTableStyles.authorName}>
          {comment.author?.name}:
        </Typography>
        <Typography component="span" variant="body1">
          {comment.content}
        </Typography>
      </Box>
    }>
      {comment.comments && Array.isArray(comment.comments) ? renderTree(comment.comments) : null}
    </TreeItem>
  ));

const CommentTable = ({ setSelectedComment, selectedComment, campaignId, post, selectedAgent }: Props) => {
  const [selectedCommentId, setSelectedCommentId] = useState<string>("");
  const styles = treeCommentTableStyles;

  const handleItemSelectionToggle = (
    event: React.SyntheticEvent,
    itemId: string,
    isSelected: boolean,
  ) => {
    if (isSelected) {
      setSelectedCommentId(itemId);
    }
  };

  const findCommentById = (comments: FacebookComment[], id: string): FacebookComment | null => {
    for (let comment of comments) {
      if (comment.id === id) {
        return comment;
      }
      if (comment.comments) {
        const found = findCommentById(comment.comments, id);
        if (found) return found;
      }
    }
    return null;
  };

  const tempSelectedComment = selectedCommentId ? findCommentById(post.comments, selectedCommentId) : null;
  setSelectedComment(selectedCommentId ? findCommentById(post.comments, selectedCommentId) : null);

  return (
    <Box sx={styles.container}>
      <SimpleTreeView onItemSelectionToggle={handleItemSelectionToggle}>
        {renderTree(post.comments)}
      </SimpleTreeView>
    </Box>
  );
};

export default CommentTable;