import { FacebookPage, FacebookPost, FetchedAgent, FetchedCampaign } from "../../../utils/interfaces";
import { Box, Button, Drawer, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FACEBOOK_APP_ID, LAMBDA_ENDPOINTS } from "../../../utils/constant";
import { useEffect, useState } from "react";
import IntroductionSection from "./IntroductionSectionCampaigns";
import { LogoutOptions } from "@auth0/auth0-spa-js";
import axios from "axios";
import CampaignPost from "./CampaignPost";
import { fetchAgentsByCampaignId, fetchCampaignsByUserId, getAgentByAgentId } from "../EndpointService/GenerateResponse";
import CampaignDisplay from "./CampaignDisplay";
import { campaignPageStyles } from "./CampaignStyles";
import useFacebookSdk from "../SocialMediaService/Hooks/useFacebookSdk";
import { useFacebookLogin } from "../SocialMediaService/Hooks/useFacebookLogin";
import { useFetchPosts } from "../SocialMediaService/Hooks/useFacebookFetchPagePosts";
import TestComponent from "./EndpointsTestComponent";
import { Agent, Campaign } from "../CampaignInterface";
import { getCampaignsByUserId } from "../EndpointService/CampaignService";
import { getAgentsByCampaignId } from "../EndpointService/AgentService";
import { ZACKS_THREADS } from "../CampaignConst";
import { transformThreadToFacebookPost } from "../EndpointService/CampaignUtils";

interface CampaignPageProp {
  userId: string;
}

const CampaignPage = ({ userId }: CampaignPageProp) => {

  const [showFacebookPost, setShowFacebookPost] = useState<boolean>(false)
  const [campaigns, setCampaigns] = useState<Campaign[]>([{ campaignName: "", campaignCreatorId: "", campaignId: "" }]);
  const [selectedCampaign, setSelectedCampaigns] = useState<Campaign>({ campaignName: "Hasbarah", campaignCreatorId: "43efffe1-d5e0-4309-a0cd-295a79bdf64b", campaignId: "95026e57-8caa-427d-ac1a-9552f6213424" });

  const [selectedPageId, setSelectedPageId] = useState<string>("");
  const [agents, setAgents] = useState<Agent[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<Agent>({
    agentName: "testAgent", campaignId: "95026e57-8caa-427d-ac1a-9552f6213424", agentId: "898d3b9a-8f38-4e42-9017-8164050b6ac4", agentCreatorId: "43efffe1-d5e0-4309-a0cd-295a79bdf64b", model: 'gpt-3.5'
  });

  const { fetchPosts, posts, isLoading } = useFetchPosts();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [postsToDisplay, setPostsToDisplay] = useState<FacebookPost[]>(posts)

  const zackPosts = ZACKS_THREADS.map(post => transformThreadToFacebookPost(post))

  const fetchCampaigns = async (userId: string) => {
    try {
      const response = await getCampaignsByUserId(userId);
      if (response) { setCampaigns(response); }
      console.log(response)
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
  };

  const updateAgents = async () => {
    if (selectedCampaign.campaignId) {
      await fetchAgents(selectedCampaign.campaignId);
    }
  };

  const fetchAgents = async (campaignId: string) => {
    try {
      const response = await getAgentsByCampaignId(campaignId)
      if (response) {
        setAgents(response);
        setSelectedAgent(response[0]);
      }
      console.log(response)
    } catch (error) {
      console.error("Error fetching agents:", error);
    }
  };

  const updateCampaigns = async () => {
    fetchCampaigns(userId)
  };

  useEffect(() => {
    fetchCampaigns(userId)
    if (selectedCampaign.campaignId) {
      fetchAgents(selectedCampaign.campaignId)
    }
  }, [userId]);

  useFacebookSdk(FACEBOOK_APP_ID);
  const { login, pages, isLoggedIn } = useFacebookLogin();

  const handleToggleChange = (event: React.MouseEvent<HTMLElement>, value: boolean) => {
    console.log(value);
    setPostsToDisplay((previous) => value ? zackPosts : posts)
    setShowFacebookPost(value)
  };


  useEffect(() => {
    if (selectedPageId !== "" && pages.length > 0) {
      const selectedPage = pages.find((page) => page.id === selectedPageId);
      if (selectedPage) {
        fetchPosts(selectedPage.access_token, selectedPageId);
      }
    }
  }, [selectedPageId]);

  const loggendInPlaceholder = true;

  const handlePageChange = (event: SelectChangeEvent<string>) => {
    setSelectedPageId(event.target.value as string);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = campaignPageStyles;

  return (
    <>
      <IntroductionSection
        isMobile={isMobile}
        logout={function (options?: LogoutOptions | undefined): Promise<void> {
          throw new Error("Function not implemented.");
        }}
        setIsReportModalOpen={function (state: boolean): void {
          throw new Error("Function not implemented.");
        }}
      />
      <Stack direction="column" alignItems="center" sx={styles.container}>
        {!loggendInPlaceholder && (
          <Button sx={styles.loginButton} variant="contained" onClick={login}>
            Login with Facebook
          </Button>
        )}
        <Box sx={styles.contentContainer}>
          <Stack direction={'row'} alignItems={'flex-end'} padding={2} >
            
            {loggendInPlaceholder && (
              <>
                <Button variant="contained" onClick={toggleDrawer}>
                  Manage Campaigns
                </Button>
              </>
            )}
            
            <Stack direction={'column'} alignItems={'right'} sx={{ ml: 2 }}>
              <Box sx={{mb:1, fontSize: '14px' }}>
                Choose Inputs
              </Box>

              <ToggleButtonGroup size="small" exclusive value={showFacebookPost} onChange={handleToggleChange} aria-label="Choose Input">
                <ToggleButton color="primary" value={false} aria-label="logical">
                  Facebook
                </ToggleButton>
                <ToggleButton color="primary" value={true} aria-label="aggressive">
                  other
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>

          </Stack>
          <Box>
            {postsToDisplay.map((post, index) => (
              <CampaignPost selectedAgent={selectedAgent} selectedCampaign={selectedCampaign} index={index} post={post} />
            ))}
          </Box>
        </Box>
        <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer} >
          <Box sx={styles.drawerContent}>
            <CampaignDisplay
              updateCampaigns={updateCampaigns}
              selectedAgent={selectedAgent}
              setSelectedAgent={setSelectedAgent}
              setAgents={setAgents}
              agents={agents}
              fetchAgents={fetchAgents}
              campaigns={campaigns}
              setCampaigns={setCampaigns}
              selectedCampaign={selectedCampaign}
              setSelectedCampaign={setSelectedCampaigns}
            />

            {/* <FormControl sx={styles.pageSelect} margin="normal">
                    <InputLabel id="page-select-label">Select Page</InputLabel>
                    <Select
                      labelId="page-select-label"
                      id="page-select"
                      value={selectedPageId}
                      label="Select Page"
                      onChange={handlePageChange}
                    >
                      {pages.map((page: FacebookPage) => (
                        <MenuItem key={page.id} value={page.id}>
                          {page.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
          </Box>
        </Drawer>
      </Stack>
    </>
  );
};

export default CampaignPage;