import React from 'react';
import { Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import background from '../../assets/background3.png'; // Adjust the path as necessary
import { LogoutOptions } from '@auth0/auth0-spa-js';
import { CardActionStyles } from './FeedStyle';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import Header from './Header';

interface Props {
    isMobile: boolean,
    logout: (options?: LogoutOptions | undefined) => Promise<void>,
    setIsReportModalOpen: (state: boolean) => void
}

const IntroductionSection = ({ isMobile, logout, setIsReportModalOpen }: Props) => {
    const theme = useTheme();
    const styles = CardActionStyles;

    return (
        <Box sx={{
            backgroundImage: `url(${background})`,
            backgroundAttachment: 'fixed',
            backgroundSize: 'cover', zIndex: 0
        }}>

            <Tooltip title="Report an issue to the team">
                <IconButton
                    sx={{ ...styles.ReportButton, position: 'fixed', zIndex: 100, top: '93%', left: "30px" }} onClick={() =>
                        setIsReportModalOpen(true)}>
                    <ReportGmailerrorredOutlinedIcon />
                </IconButton>
            </Tooltip>

            <Box sx={{ backgroundColor: 'rgba(32, 5, 240, 0.43)', color: 'white', zIndex: 1, height: { xs: '50vh', sm: '45vh' } }}>
                <Header logout={logout} isMobile={isMobile} />
                <Box sx={{ mt: { xs: '7%', sm: '1%' }, padding: { xs: '25px', sm: "20px" } }}>
                    <Typography
                        sx={{
                            fontSize: {
                                xs: theme.typography.h4.fontSize,
                                sm: theme.typography.h3.fontSize,

                            }
                        }}
                        gutterBottom
                    >
                        Use AI to fight back For Israel
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: {
                                xs: theme.typography.h6.fontSize,
                                sm: theme.typography.h5.fontSize,

                            }
                        }}
                    >
                        Engage with pro and anti-Israel comments to combat misinformation and hatred
                    </Typography>
                </Box>

            </Box>
        </Box>
    )

};

export default IntroductionSection;