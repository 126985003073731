import { Autocomplete, Box, Button, Paper, TextField, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import TranslatePostCard from './TranslatePost';
import { Platforms, Post, dataSource } from '../../utils/interfaces';
import { Languages} from '../../utils/constant';
import { buildSentText } from '../../utils/postUtils';
import { initializePosts } from '../../utils/postFetching';
import { useAuth0 } from '@auth0/auth0-react';



const Translate = (dataSource: dataSource) => {
  const {user} = useAuth0();
  const [Posts,setPosts] = useState<Post[]>([]);
  useEffect(() => {

    initializePosts(dataSource.dataBaseUrlParam, user?.email).then(fetchedPosts => {
      setPosts(fetchedPosts);
    
    }).catch(error => {
        console.error('Error:', error);
    });
}, []);

  const LanguagesArray = Object.keys(Languages);
  const [postId, setPostId] = useState(0);
  const [currentPost, setCurrentPost] = useState(Posts[0]);
  
  const incrementPostId = () => {
    setPostId(postId + 1);

  };

  const onSkip = ()=> {
    incrementPostId();
  };

  useEffect(() => {
    setCurrentPost(Posts[postId])
  }, [postId]); 
  const onSend = () => {
    const sent_text = buildSentText();
    document.body.appendChild(sent_text);
    setTimeout(() => {
      document.body.removeChild(sent_text);
    }, 1000);

    incrementPostId();
  };

  const renderContent = () => {
    if (postId < Posts.length) {
      return (
        <Box sx={{ marginTop: "2%" }}>
          <TranslatePostCard key={currentPost.id} onSend={onSend}
            onSkip={onSkip} post={currentPost} />
        </Box>
      );
    }
    else {
      return (
        <Box sx={{ marginTop: "2%" }}>
          <div>No More Posts To Show</div>
        </Box>
      );
    }
  };

  // The render method uses the renderContent function to determine what should be displayed.
  return (
    <>
      {renderContent()}
    </>
  );
};

export default Translate;
