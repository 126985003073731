export interface TranslationResponse {
  translation_text: string;
  confidence: string;
}
export interface FilterOptions {
  platform: string;
  position: "pro" | "anti";
}

export interface DetectResponse {
  language: string;
  confidence: string;
}

export interface User {
  userId: string;
  userName: string;
}
export interface Message {
  id: string;
  userId: number;
  content: string;
  timestamp: Date;
}

export interface Thread {
  id: string;
  messages: Message[];
}
export interface FetchedCampaign {
  campaign_id: string;
  name: string;
}
export interface Campaign {
  nameOfTheCampaign: string;
  reasonForCampaignExistence: string;
  campaignStrengths?: string | null;
  campaignWeaknesses?: string | null;
  campaignOpportunities?: string | null;
  campaignThreats?: string | null;
  campaignGuidelines: string;
  targetAudience: string;
  campaignCreatorId: string;
  campaignID: string;
  campaign_id?: string;
  name?: string;
}

export interface Agent {
  agentName: string;
  agentDescription: string;
  agentDomain: string;
  platformName: string;
  campaignObjective: string;
  model: string;
}

export interface FetchedAgent {
  name: string;
  agent_id: string;
}
export interface RequestArgumentParamenters {
  "post-text": string;
  "response-length": number;
  "response-audience": number;
  "response-is-pro-israel": boolean;
  "response-tone": number;
  "response-formality": number;
  "platform-name": string;
  "post-description": string;
}
export interface dataBaseUrlParam {
  container: string;
  database: string;
  query?: string;
}

export interface dataSource {
  dataBaseUrlParam: dataBaseUrlParam;
}
export const initRequestArgumentParamenters = (
  partialRequest?: Partial<RequestArgumentParamenters>
): RequestArgumentParamenters => {
  const DEFUALT_VALUES = {
    "post-text": "",
    "response-length": 1,
    "response-audience": 3,
    "response-is-pro-israel": false,
    "response-tone": 3,
    "response-formality": 3,
    "platform-name": Platforms.twitter,
    "post-description": "",
  };
  return {
    ...DEFUALT_VALUES,
    ...partialRequest,
  };
};

export const Platforms = {
  instagram: "instagram",
  tiktok: "tiktok",
  facebook: "facebook",
  twitter: "twitter",
  x_com: "x.com",
};
export interface metaData {
  AnswerTone: string;
}
export interface WorkerResponse {
  text: string;
}
export interface DetectLanguageApiResponse {
  "language-code"?: string;
  confidence?: string;
}

export interface slidersValue {
  toneValue: number; //Audience
  lengthValue: number;
  languageStyleValue: number; //Formality
  communicationToneValue: number; //Tone
}

export interface Post {
  id: number;
  url: string;
  text: string;
  viralityScore?: number;
  likes?: number;
  image_url?: string;
  date?: string;
  video_url?: string;
  shares?: number;
  labels?: string[];
  proIsrael?: boolean;
  TranslatedTexts?: TranslatedTexts;
  isSent?: boolean;
  img_description?: string;
  userAdded?: boolean;
  answeredBy?: string[];
  bookmarkBy?: string[];
}
export interface suggestedAnswer {
  id?: number;
  text: string;
  answerTone: string;
}

export interface TranslatedTexts {
  Origin: TranslatedText;
  Translations: TranslatedText;
}

export interface TranslatedText {
  Language: string;
  Text: string;
}

export interface FacebookCommentJson {
  author: { name: string; id: string } | null;
  id: string | null;
  content: string | null;
  likes: number | null;
  reply_url: string | null;
  message_url: string | null;
}

export interface FacebookComment {
  id: string;
  author: { name: string; id: string };
  content: string;
  likes: number;
  reply_url?: string;
  message_url?: string;
  isPrepeared?: { answer: string; isPrepeared: boolean };
  commentCount?: number;
  comments?: FacebookComment[];
  sentiment?: "positive" | "neutral" | "negative";
}

export interface FacebookPost {
  potentialAnswer?: string;
  id: string;
  message: string;
  url: string;
  commentsCount: number;
  comments: FacebookComment[];
  topic?: string;
}

export interface FacebookPage {
  id: string;
  access_token: string;
  name: string;
}
