import { useState, useEffect } from 'react';
import { recognizePlatform } from '../../utils/postUtils';
import { FilterPosition } from './Feed';
import { Post } from '../../utils/interfaces';


const FilterAndSortPosts = (posts: Post[], sortCriteria: string, filterPlatform: string, filterPosition: FilterPosition) => {
    console.log("activated sorting ", posts, sortCriteria, filterPlatform, filterPosition)
    console.log(sortCriteria !== ' ')
    if(filterPlatform != 'all' || !filterPosition['pro'] || !filterPosition['anti'] || sortCriteria != ' '){
        console.log('initiate sorting ... ')
        const recalculated_post = posts
        .filter(post => {
            let platformMatch = true;
            let positionMatch = true;
            // Filter by platform if not set to 'all' or 'user'
            if (filterPlatform !== 'all' && filterPlatform !== 'user') {
                platformMatch = recognizePlatform(post.url) === filterPlatform;
            }

            // Filter by user-added if 'user' is selected
            if (filterPlatform === 'user') {
                platformMatch = post.userAdded ?? false;
            }

            // Filter by position (pro-Israel or anti-Israel)
            if (!filterPosition['pro'] || !filterPosition['anti']) {
                if (filterPosition['pro']) {
                    positionMatch = post.proIsrael ?? false;
                } else if (filterPosition['anti']) {
                    positionMatch = !post.proIsrael;
                }
            }

            return platformMatch && positionMatch;
        })
        .sort((a, b) => {
            if (sortCriteria === 'likes') {
                return (b.likes ? b.likes : 0) - (a.likes ? a.likes : 0);
            }

            if (sortCriteria === 'date') {
                
                let dateA = new Date(a.date?? "1923-12-12").getTime();
                let dateB = new Date(b.date?? "1923-12-12").getTime();
                return dateB - dateA;

            }
            return 0;
        });

        console.log(`applied filter, the result is` ,recalculated_post);
        return recalculated_post; 
    }

    console.log(`no filter selected `);
    return posts;  
    
};

export default FilterAndSortPosts;