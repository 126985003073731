import { RawCampaign, Thread } from "./CampaignInterface";

// export const AWS_SERVER_URL = 'http://localhost:80'
export const AWS_SERVER_URL = ''

export const newCampaign: RawCampaign = {
    campaignName: 'New Campaign',
    worldSetup: 'Fantasy World',
    reasonForCampaignExistence: 'To save the world',
    campaignGuidelines: 'Be brave',
    targetAudience: 'Adults',
    campaignStrengths: 'Strong heroes',
    campaignWeaknesses: 'Tough villains',
    campaignOpportunities: 'Exploration',
    campaignThreats: 'Dangerous foes'
};

export const updatedCampaign: RawCampaign = {
    campaignName: 'Updated Campaign',
    worldSetup: 'Sci-fi World',
    reasonForCampaignExistence: 'To explore the universe',
    campaignGuidelines: 'Stay curious',
    targetAudience: 'Everyone',
    campaignStrengths: 'Innovative technology',
    campaignWeaknesses: 'Unknown dangers',
    campaignOpportunities: 'Scientific discoveries',
    campaignThreats: 'Hostile aliens'
};
export const USER_INSTRUCTIONS = 'Write a pro-Israel comment on my behalf to the thread above. Make sure my response is based on up-to-date information.'
export const ZACKS_THREADS: Thread[] = [
    {  
        "threadId": "Should the anti-semitism bill pass?",
        "messages": [
            {
                "role": "user",
                "name": "Timnit Gebru",
                "content": "There is nothing more just than the protests happening around the country right now.\nThe Israeli government is committing genocide against the Palestinian people, and we must stand up against this injustice.\n#FreePalestine"
                
            },
            {
                "role": "user",
                "name": "Alex Hanna",
                "content": "You're completely right! Bibi has the blood of innocent children on his hands. He's willing to pay with their blood to save his political career..."
            },
            {
                "role": "user",
                "name": "Timnit Gebru",
                "content": "I just hope this new anti-semitism bill doesn't pass. It's a direct attack on our freedom of speech and our ability to stand up against injustice." 
            } 
        ]
    },
    {
        "threadId": "Are IDF Airstrikes self defense?",
        "messages": [
            {
                "role": "user",
                "name": "Rachel",
                "content": "The IDF's airstrikes are a form of self-defense against terrorism."
            },
            {
                "role": "user",
                "name": "Ahmed",
                "content": "But what about the innocent lives lost in Gaza? How can that be justified?"
            },
            {
                "role": "user",
                "name": "Rachel",
                "content": "It's tragic, but Hamas uses civilians as human shields. It's a complex situation."
            },
            {
                "role": "user",
                "name": "Fatima",
                "content": "The root cause is the occupation and blockade. Address that, and the violence will stop."
            },
            {
                "role": "user",
                "name": "Rachel",
                "content": "The security of Israel is non-negotiable. There has to be a way to balance security and justice."
            }
        ]
    },
    {
        "threadId": "Should Israel invade Rafah?",
        "messages": [
            {
                "role": "user",
                "name": "Daniel Levi",
                "content": "The situation in Rafah is critical. Israel's military operation is necessary to dismantle Hamas' stronghold. According to The Independent, this is essential to prevent further attacks on Israeli soil."
            },
            {
                "role": "user",
                "name": "Aisha Rahman",
                "content": "But Daniel, the humanitarian cost is devastating. The UN warns that the invasion will disrupt aid and lead to significant civilian casualties. The conditions in Muwasi are already dire."
            },
            {
                "role": "user",
                "name": "Yitzhak Weiss",
                "content": "I understand the concerns, Aisha. As a soldier, I see the need to neutralize threats, but it must be done with minimal harm to civilians. The IDF has expanded humanitarian zones to provide aid and safety."
            },
            {
                "role": "user",
                "name": "Nadia Al-Masri",
                "content": "The so-called 'humanitarian zones' are insufficient, Yitzhak. People are living in squalid conditions, struggling for basic necessities. We need to prioritize humanitarian aid and cease military operations."
            },
            {
                "role": "user",
                "name": "Daniel Levi",
                "content": "I agree that humanitarian aid is crucial, but Hamas uses these areas to shield their operations. It's a complex situation where security and humanitarian needs clash."
            },
            {
                "role": "user",
                "name": "Aisha Rahman",
                "content": "The international community, including President Biden, is urging for a ceasefire to protect lives. Continuing this operation will only fuel more violence and resentment."
            },
            {
                "role": "user",
                "name": "Yitzhak Weiss",
                "content": "A ceasefire sounds ideal, but Hamas has repeatedly violated such agreements. Israel needs to ensure long-term security, which sometimes requires difficult decisions."
            },
            {
                "role": "user",
                "name": "Nadia Al-Masri",
                "content": "The cost is too high, Yitzhak. We need a sustainable solution that addresses the root causes of this conflict, not just temporary military gains."
            },
            {
                "role": "user",
                "name": "Daniel Levi",
                "content": "Ultimately, we need both security and humanitarian efforts to work hand in hand. The international community should support efforts to rebuild Gaza while ensuring Hamas cannot rearm."
            }
        ]
    },
    {
        "threadId": "Is Yoav Gallant's criticism of Netanyahu valid?",
        "messages": [
            {
                "role": "user",
                "name": "Adam Goldstein",
                "content": "Yoav Gallant's criticism of Netanyahu for lacking a 'day-after' plan is spot on. The military efforts in Gaza need a clear exit strategy, otherwise, we risk long-term instability."
            },
            {
                "role": "user",
                "name": "Maya Elbaz",
                "content": "Absolutely, Adam. The Times of Israel highlighted that Gallant emphasized the need for political actions to follow military campaigns. Without a concrete post-war strategy, the sacrifices made by our soldiers could be in vain."
            },
            {
                "role": "user",
                "name": "Eitan Levy",
                "content": "I disagree. Netanyahu's plan is realistic given the security challenges we face. As reported by Yahoo News, maintaining security control is essential to prevent Hamas from rearming and posing a future threat."
            },
            {
                "role": "user",
                "name": "Adam Goldstein",
                "content": "I agree that security is crucial, but we need a balanced approach that includes political solutions. Netanyahu's plan, as outlined by Yahoo News, focuses on maintaining control but lacks provisions for genuine governance and development in Gaza."
            },
            {
                "role": "user",
                "name": "Maya Elbaz",
                "content": "Moreover, the failure to include a robust plan for Gaza's reconstruction and governance could lead to a resurgence of extremism. Gallant’s push for a clear and sustainable strategy is essential for long-term peace."
            },
            {
                "role": "user",
                "name": "Eitan Levy",
                "content": "Netanyahu's focus on security first is pragmatic. According to The Jewish Chronicle, his plan includes working with local leaders in Gaza who aren't affiliated with Hamas. This is a step towards sustainable governance."
            },
            {
                "role": "user",
                "name": "Adam Goldstein",
                "content": "The lack of a comprehensive post-war plan undermines the efforts of international mediators and the potential for a lasting ceasefire, as mentioned by Ynetnews. We need a clear vision for the future, not just military control."
            },
            {
                "role": "user",
                "name": "Maya Elbaz",
                "content": "For true peace, we need to address the root causes of the conflict. This includes ensuring that any governance in Gaza post-conflict involves local, non-extremist leaders who can rebuild the community effectively."
            },
            {
                "role": "user",
                "name": "Eitan Levy",
                "content": "Netanyahu's plan is a necessary start. Security must come first to ensure any future political solutions are built on a foundation of stability. Without security, no political plan can succeed."
            }
        ]
    },
    {
        "threadId": "Is the scope of college protests justified?",
        "messages": [
            {
                "role": "user",
                "name": "Isa Johnson",
                "content": "I was really looking forward to my graduation ceremony, but the protests have completely changed the atmosphere on campus. It's so disappointing that USC had to cancel the main event."
            },
            {
                "role": "user",
                "name": "Omar Khalil",
                "content": "I understand your frustration, Isa. But these protests are crucial for raising awareness about the situation in Gaza. Universities like USC need to take a stand and divest from companies that support the occupation."
            },
            {
                "role": "user",
                "name": "Rachel Bloom",
                "content": "I get that the cause is important, Omar, but there needs to be a balance. At the University of Michigan, the protests during the ceremony were really disruptive. We came to celebrate our children's achievements, not get caught in the middle of political protests."
            },
            {
                "role": "user",
                "name": "Isa Johnson",
                "content": "Exactly, Rachel. While I support the right to protest, I just wish it didn't have to ruin such a significant day for so many of us. It feels like the university administration didn't handle the situation well."
            },
            {
                "role": "user",
                "name": "Omar Khalil",
                "content": "The administration's response has been lacking, but the protests need to be visible to make an impact. At Columbia University, the main ceremony was canceled due to safety concerns, which shows how serious the situation is. These actions are meant to highlight that life can't go on as usual while such injustices occur."
            },
            {
                "role": "user",
                "name": "Rachel Bloom",
                "content": "I appreciate the need to make a statement, but there must be other ways to protest that don't overshadow these important milestones. At Duke, the protests during Jerry Seinfeld's speech were mixed with boos and cheers, creating a really tense atmosphere. It's just not fair to the graduates."
            },
            {
                "role": "user",
                "name": "Isa Johnson",
                "content": "We need better planning from both protest organizers and university administrations. There has to be a way to support important causes without completely disrupting such significant events."
            },
            {
                "role": "user",
                "name": "Omar Khalil",
                "content": "You're right, Isa. Finding that balance is key. Maybe we can work towards more coordinated efforts that allow for both meaningful protests and memorable graduations."
            }
        ]
    },
    {
        "threadId": "What should Israel do given the deteriorating relationship with the US?",
        "messages": [
            {
                "role": "user",
                "name": "Lior Ben-David",
                "content": "Netanyahu is right to stand firm against Biden's pressures. Israel must ensure its own security, even if it means acting independently. The situation in Gaza is too critical to leave to external influence."
            },
            {
                "role": "user",
                "name": "Hana Weiss",
                "content": "But Lior, the relationship between Netanyahu and Biden is at an all-time low. Biden is withholding key military supplies because of concerns over civilian casualties in Gaza. This isn't just about security, it's about humanitarian responsibility."
            },
            {
                "role": "user",
                "name": "Ahmed Rahman",
                "content": "Exactly, Hana. Biden's stance reflects growing frustration with Netanyahu's refusal to heed calls for restraint. The civilian death toll in Gaza is alarming, and many Americans, including key voter groups, are losing patience with unconditional support for Israel's military actions."
            },
            {
                "role": "user",
                "name": "Lior Ben-David",
                "content": "Security must come first. Netanyahu's plan to maintain control over Gaza is necessary to prevent Hamas from rearming. Biden's approach of using military aid as leverage could undermine Israel's ability to defend itself effectively."
            },
            {
                "role": "user",
                "name": "Hana Weiss",
                "content": "But ignoring Biden's warnings could isolate Israel further. The U.S. has been a critical ally, and straining this relationship risks broader geopolitical consequences. Diplomatic solutions need to be prioritized alongside security measures."
            },
            {
                "role": "user",
                "name": "Ahmed Rahman",
                "content": "The focus should be on de-escalation and humanitarian aid. Biden's reluctance to provide certain weapons is a necessary step to prevent further escalation. The international community needs to push for a ceasefire and long-term peace negotiations."
            }
        ]
    }
]