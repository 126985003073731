import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import axios from 'axios';
import { AMAZON_CAMPAIGN_ENDPOINTS2 } from '../../../utils/constant';
import { Agent, Campaign } from '../CampaignInterface';
import { addAgent } from '../EndpointService/AgentService';

interface AddAgentDialogProps {
  open: boolean;
  onClose: () => void;
  setAgents: (agents: Agent[]) => void;
  agents: Agent[];
  selectedCampaign: Campaign;
}

const AddAgentDialog: React.FC<AddAgentDialogProps> = ({ open, onClose, setAgents, agents, selectedCampaign }) => {
  const [newAgent, setNewAgent] = useState<Agent>({
    agentName: '',
    agentDescription: '',
    agentDomain: '',
    platformName: '',
    model: '',
    campaignId: '',
    agentId: '',
    agentCreatorId: ''
  });

  const handleAgentChange = (prop: keyof Agent) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewAgent({ ...newAgent, [prop]: event.target.value });
  };

  const handleAddAgent = async () => {
    try {
      const response = await addAgent({userId: selectedCampaign.campaignCreatorId, campaignId: selectedCampaign.campaignId, rawAgent: newAgent})

      setAgents([...agents, response]);
      setNewAgent({
        agentName: '',
        agentDescription: '',
        agentDomain: '',
        platformName: '',
        model: '',
        campaignId: '',
        agentId: '',
        agentCreatorId: ''
      });
      onClose();
    } catch (error) {
      console.error('Error adding agent:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add a New Agent</DialogTitle>
      <DialogContent>

        <TextField
          autoFocus
          margin="dense"
          id="agentName"
          label="Agent Name"
          fullWidth
          variant="standard"
          value={newAgent.agentName}
          onChange={handleAgentChange('agentName')}
        />
        <TextField
          margin="dense"
          id="agentDescription"
          label="Agent Description"
          fullWidth
          variant="standard"
          value={newAgent.agentDescription}
          onChange={handleAgentChange('agentDescription')}
        />
        <TextField
          margin="dense"
          id="agentDomain"
          label="Agent Domain"
          fullWidth
          variant="standard"
          value={newAgent.agentDomain}
          onChange={handleAgentChange('agentDomain')}
        />
        <TextField
          margin="dense"
          id="platformName"
          label="Platform Name"
          fullWidth
          variant="standard"
          value={newAgent.platformName}
          onChange={handleAgentChange('platformName')}
        />

        <TextField
          margin="dense"
          id="model"
          label="Model"
          fullWidth
          variant="standard"
          value={newAgent.model}
          onChange={handleAgentChange('model')}
        /></DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleAddAgent}>Add</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAgentDialog;