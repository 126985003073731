import { Platforms } from "./interfaces"
import { recognizePlatform } from "./postUtils";

export const getUrlForEmbbeding = (url: string) => {
    const socialMedia = recognizePlatform(url)
    let tempUrl = new URL(url);
    if (socialMedia === Platforms.instagram) {
        return `${tempUrl.origin}${tempUrl.pathname}`;
    }
    if (socialMedia === Platforms.facebook) {
        return url;
    }
    return url
}

export const buildLoadScript = (socialPlatform:string) => {
    const script = document.createElement('script');
    script.crossOrigin = 'anonymous';
    script.src = loadSocialMediaScript(socialPlatform);
    script.async = true;
    script.charset ="utf-8";
    if (socialPlatform === Platforms.instagram) {
        script.defer = true;
        script.addEventListener('load',handleInstagramScriptLoad);
    }
    return script;
}

export const loadScriptToDocument = (url: string, defer: boolean = false) => {
    const script = document.createElement('script');
    script.defer = defer;
    script.crossOrigin = 'anonymous';
    script.src = url;
    script.async = true;
    return script;
}

export const handleInstagramScriptLoad = () => {
    if (window.instgrm && window.instgrm.Embeds) {
        window.instgrm.Embeds.process();

        // After the Instagram embeds have been processed, override the min-width style.
        Array.from(document.getElementsByClassName("instagram-media instagram-media-rendered")).forEach((element) => {
            // Assert that the element is an HTMLElement before accessing the style property
            if (element instanceof HTMLElement) {
                element.style.minWidth = "";
            }
        });
    }
};

export const loadSocialMediaScript = (socialPlatform: string) => {
    switch (socialPlatform) {
        case Platforms.twitter:
            return 'https://platform.twitter.com/widgets.js';
            break;
        case Platforms.facebook:
            return `https://connect.facebook.net/he_IL/sdk.js#xfbml=1&version=v18.0`;
            break;
        case Platforms.instagram:
            return 'https://www.instagram.com/embed.js';
            break;
    }
    return '';
}
