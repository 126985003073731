import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { Campaign, RawCampaign } from '../CampaignInterface';
import { addCampaign } from '../EndpointService/CampaignService';

interface AddCampaignDialogProps {
  userId: string;
  open: boolean;
  onClose: () => void;
  setCampaigns: React.Dispatch<React.SetStateAction<Campaign[]>>;
  campaigns: Campaign[];
}

const AddCampaignDialog: React.FC<AddCampaignDialogProps> = ({userId, open, onClose, setCampaigns, campaigns }) => {
  const [newCampaign, setNewCampaign] = useState<RawCampaign>({
    campaignName: '',
    reasonForCampaignExistence: '',
    campaignStrengths: '',
    campaignWeaknesses: '',
    campaignOpportunities: '',
    campaignThreats: '',
    campaignGuidelines: '',
    targetAudience: '',
    worldSetup: ''
  });

  const handleChange = (prop: keyof RawCampaign) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewCampaign({ ...newCampaign, [prop]: event.target.value });
  };

  const handleAddCampaign = async () => {
    try {
      const response = await addCampaign({ userId, rawCampaign: newCampaign });
      setCampaigns([...campaigns, response]);
      onClose();
    } catch (error) {
      console.error('Error adding campaign:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add a New Campaign</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="campaignName"
          label="Campaign Name"
          fullWidth
          variant="standard"
          value={newCampaign.campaignName}
          onChange={handleChange('campaignName')}
        />
        <TextField
          margin="dense"
          id="worldSetup"
          label="World Setup"
          fullWidth
          variant="standard"
          value={newCampaign.worldSetup}
          onChange={handleChange('worldSetup')}
        />
        <TextField
          margin="dense"
          id="reasonForCampaignExistence"
          label="Reason for Campaign"
          fullWidth
          variant="standard"
          value={newCampaign.reasonForCampaignExistence}
          onChange={handleChange('reasonForCampaignExistence')}
        />
        <TextField
          margin="dense"
          id="campaignStrengths"
          label="Campaign Strengths"
          fullWidth
          variant="standard"
          value={newCampaign.campaignStrengths}
          onChange={handleChange('campaignStrengths')}
        />
        <TextField
          margin="dense"
          id="campaignWeaknesses"
          label="Campaign Weaknesses"
          fullWidth
          variant="standard"
          value={newCampaign.campaignWeaknesses}
          onChange={handleChange('campaignWeaknesses')}
        />
        <TextField
          margin="dense"
          id="campaignOpportunities"
          label="Campaign Opportunities"
          fullWidth
          variant="standard"
          value={newCampaign.campaignOpportunities}
          onChange={handleChange('campaignOpportunities')}
        />
        <TextField
          margin="dense"
          id="campaignThreats"
          label="Campaign Threats"
          fullWidth
          variant="standard"
          value={newCampaign.campaignThreats}
          onChange={handleChange('campaignThreats')}
        />
        <TextField
          margin="dense"
          id="campaignGuidelines"
          label="Campaign Guidelines"
          fullWidth
          variant="standard"
          value={newCampaign.campaignGuidelines}
          onChange={handleChange('campaignGuidelines')}
        />
        <TextField
          margin="dense"
          id="targetAudience"
          label="Target Audience"
          fullWidth
          variant="standard"
          value={newCampaign.targetAudience}
          onChange={handleChange('targetAudience')}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleAddCampaign}>Add</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCampaignDialog;
