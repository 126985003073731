import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import logo from '../../assets/LogoSingleColor.png';
import { Grid } from '@mui/material';
import { useNavigate } from "react-router-dom";
import LoginButton from './LoginButton';
import { useAuth0 } from '@auth0/auth0-react';
import { LogoutButton } from '../SitePages/LoginPage';

const pages = ['Respond', 'Translate'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];



interface props {
    setAnchorElNav: () => void;
    setAnchorElUser: () => void;

}

const NavBar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [logoClickCount, setLogoClickCount] = React.useState(0);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const { isAuthenticated } = useAuth0();
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };


    const LoginButton = () => {
        const { loginWithRedirect } = useAuth0();
        return <button onClick={() => loginWithRedirect()}>Log In</button>;
    };


    const handleLogoClick = () => {
        const newCount = logoClickCount + 1;
        if (newCount >= 2) {
            setLogoClickCount(0);
            navigate("/Feed");
        } else {
            setLogoClickCount(newCount);
        }
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
        setAnchorElNav(null);
    };

    const navigate = useNavigate();

    const handleClick = (path: string) => {
        navigate("/" + path);
    }

    //80 100 151
    return (
        <AppBar style={{
            backgroundColor: "white", position: 'sticky',
            top: 0,
            height: '50px',
            zIndex: 1000,
        }} >
            <Box display='flex' justifyContent="space-between" flexDirection='row' alignItems="center">
                <Box sx=
                    {{
                        display: { xs: 'flex', sm: 'none' }
                    }}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"

                        onClick={handleOpenNavMenu}
                        color='default'
                    >

                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseUserMenu}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                        }}
                    >
                        {pages.map((page) => (
                            <MenuItem key={page} onClick={() => {
                                handleClick(page)
                                handleCloseUserMenu()
                            }}>
                                <Typography textAlign="center">{page}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
                <img height='50px' src={logo} onClick={handleLogoClick} />
                <Box width="60%" justifyContent="space-around" alignItems="center"
                    sx={{ display: { xs: 'none', sm: 'flex' } }} flexDirection='row'>
                    {pages.map((page) => (
                        <Button variant="text" onClick={() => handleClick(page)} key={page} style={{ color: "black" }}>{page} </Button>
                    ))}
                </Box>
                {isAuthenticated && LogoutButton()}
                <Box marginRight={'5px'} sx={{ flexGrow: 0 }}>
                    <Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>

                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        {settings.map((setting) => (
                            <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                <Typography textAlign="center">{setting}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            </Box>
        </AppBar>
    );
}
export default NavBar;