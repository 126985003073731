import { AWS_SERVER_URL } from "../CampaignConst";
import { User } from "../CampaignInterface";

export function snakeToCamel(obj: any): any {
    if (Array.isArray(obj)) {
        return obj.map(v => snakeToCamel(v));
    } else if (obj != null && obj.constructor === Object) {
        return Object.keys(obj).reduce((result: any, key: string) => {
            const newKey = key.replace(/(_\w)/g, matches => matches[1].toUpperCase());
            result[newKey] = snakeToCamel(obj[key]);
            return result;
        }, {});
    }
    return obj;
}

export function camelToSnake(obj: any): any {
    if (Array.isArray(obj)) {
        return obj.map(v => camelToSnake(v));
    } else if (obj != null && obj.constructor === Object) {
        return Object.keys(obj).reduce((result: any, key: string) => {
            const newKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
            result[newKey] = camelToSnake(obj[key]);
            return result;
        }, {});
    }
    
    return obj;
}

export async function listUsers(): Promise<User[]> {
    const lambdaUrl = 'https://xfyxt3oux7qrnt6wjko2alilmu0nwewz.lambda-url.us-east-1.on.aws/'
    const response = await fetch(lambdaUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error(`Error fetching users: ${response.statusText}`);
    }
    const finalResponse = await response.json();
    return snakeToCamel(finalResponse);
}

interface AddUserRequest {
    userName: string;
    userId?: string;
    userDescription?: string;
    userStances?: string;
    bucketName?: string;
}

export async function addUser(user: AddUserRequest): Promise<User> {
    const snakeCaseUser = camelToSnake(user);
    const response = await fetch(`${AWS_SERVER_URL}/users/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(snakeCaseUser)
    });

    if (!response.ok) {
        throw new Error(`Error adding user: ${response.statusText}`);
    }

    const finalResponse = await response.json();
    return snakeToCamel(finalResponse);
}



export async function getUser(userId: string): Promise<User> {
    const response = await fetch(`${AWS_SERVER_URL}/users/${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error(`Error fetching user: ${response.statusText}`);
    }

    const finalResponse = await response.json();
    return snakeToCamel(finalResponse);
}

export async function editUser(userId: string, user: User): Promise<User> {
    const snakeCaseUser = camelToSnake(user);
    const response = await fetch(`${AWS_SERVER_URL}/users/${userId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(snakeCaseUser)
    });

    if (!response.ok) {
        throw new Error(`Error editing user: ${response.statusText}`);
    }
    const finalResponse = await response.json();
    return snakeToCamel(finalResponse);
}

export async function deleteUser(userId: string): Promise<{ message: string }> {
    const response = await fetch(`${AWS_SERVER_URL}/users/${userId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error(`Error deleting user: ${response.statusText}`);
    }

    const finalResponse = await response.json();
    return snakeToCamel(finalResponse);
}

