import { Button, Card, CardActions, CardContent, IconButton, Skeleton, TextField } from "@mui/material"
import { TranslatedTexts } from "../../utils/interfaces";
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { off } from "process";
import { useEffect, useState } from "react";
interface Props {
    isLoadingTranslation: boolean;
    TranslatedTexts: TranslatedTexts;
    SelectedLang: string;
    OriginLang: string;

    onSend: () => void;
}
const TranslationSuggestion = ({ isLoadingTranslation, TranslatedTexts, OriginLang, SelectedLang, onSend }: Props) => {
    const [translationText, setTranslationText] = useState('');
    // Set isTextLoading to true if original text is loading, or marked with an error / empty.
    const isTextLoading = !TranslatedTexts.Origin.Text ||
     isLoadingTranslation || 
     TranslatedTexts.Origin.Text === "Error: not found";

    useEffect(() => {
        setTranslationText(TranslatedTexts.Translations.Text);
    }, [TranslatedTexts.Translations.Text]);

    const handleTranslationChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTranslationText(event.target.value);
    };

    return (<>
        <Card raised sx={{ mt: 3 }}>
            <h1
                style={{ margin: ' 10px 100px 10px 20px', textAlign: "left", fontSize: 14 }}
            >{"Source:  " + OriginLang}
            </h1>
            <CardContent>
                {isTextLoading ?
                    (
                        <Skeleton variant="rectangular" height={118} />
                    ) :
                    (<TextField
                        multiline
                        disabled={false}
                        minRows={1}
                        maxRows={8}
                        value={TranslatedTexts.Origin.Text}
                        sx={{ width: "100%", overflow: 'hidden', fontSize: '16px' }}
                    />)

                }
            </CardContent>
            <CardActions>
                <IconButton aria-label="delete" size="small">
                    <RefreshRoundedIcon />
                </IconButton>
            </CardActions>
        </Card>

        <Card raised sx={{ mt: 3 }}>
            <h1
                style={{ margin: ' 10px 100px 10px 20px', textAlign: "left", fontSize: 14 }}
            >{"Translation To: " + TranslatedTexts.Translations.Language}
            </h1>
            <CardContent>
                {isTextLoading ?
                    (
                        <Skeleton variant="rectangular" height={118} />
                    )
                    :
                    (
                        <TextField
                            multiline
                            disabled={false}
                            minRows={5}
                            maxRows={10}
                            value={TranslatedTexts.Translations.Text}
                            onChange={handleTranslationChange}
                            variant="outlined"
                            sx={{ width: "100%", overflow: 'hidden', fontSize: '16px' }}
                        />
                    )}
            </CardContent>
            <CardActions>
                <Button sx={{ marginBottom: '3%' }} size="small" onClick={onSend}>Send Translation</Button>
            </CardActions>
        </Card>

    </>)

}

export default TranslationSuggestion