import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  useTheme,
  Button,
  Modal,
  TextField,
  Typography,
  Paper,
  InputAdornment,
} from "@mui/material";
import IntroductionSection from "./IntroductionSectionCampaigns";
import { LogoutOptions } from "@auth0/auth0-spa-js";
import { campaignPageStyles } from "./CampaignStyles";
import { ZACKS_THREADS } from "../CampaignConst";
import { transformThreadToFacebookPost } from "../EndpointService/CampaignUtils";
import SendIcon from "@mui/icons-material/Send";
import { newConversation } from "../conversations";
import CampaignPost from "./CampaignPost";
import CustomConversation from "./CustomConversation";

const DemoCampaignPage = () => {
  const [showCustomConversation, setShowCustomConversation] =
    useState<boolean>(false);
  const [apiKey, setApiKey] = useState<string>("");
  const [isApiKeyModalOpen, setIsApiKeyModalOpen] = useState<boolean>(false);

  const zackPosts = ZACKS_THREADS.map((post) =>
    transformThreadToFacebookPost(post)
  );

  const [postsToDisplay, setPostsToDisplay] = useState(newConversation);

  const handleToggleChange = (
    event: React.MouseEvent<HTMLElement>,
    value: boolean
  ) => {
    setShowCustomConversation(value);
    setPostsToDisplay(value ? newConversation : newConversation);
  };

  const handleApiKeySubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsApiKeyModalOpen(false);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const styles = campaignPageStyles;

  return (
    <>
      <IntroductionSection
        isMobile={isMobile}
        logout={function (options?: LogoutOptions | undefined): Promise<void> {
          throw new Error("Function not implemented.");
        }}
        setIsReportModalOpen={function (state: boolean): void {
          throw new Error("Function not implemented.");
        }}
      />
      <Stack direction="column" alignItems="center" sx={styles.container}>
        <Box sx={styles.contentContainer}>
          <Stack direction="row" alignItems="flex-end" padding={2}>
            <Stack direction="column" alignItems="right" sx={{ ml: 2 }}>
              <Box sx={{ mb: 1, fontSize: "14px" }}>Choose View</Box>
              <ToggleButtonGroup
                size="small"
                exclusive
                value={showCustomConversation}
                onChange={handleToggleChange}
                aria-label="Choose View"
              >
                <ToggleButton
                  color="primary"
                  value={false}
                  aria-label="facebook"
                >
                  Twitter Posts
                </ToggleButton>
                <ToggleButton color="primary" value={true} aria-label="custom">
                  Custom Conversation
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsApiKeyModalOpen(true)}
              sx={{ ml: 2 }}
            >
              Set API Key
            </Button>
          </Stack>
          <Box>
            {showCustomConversation ? (
              <CustomConversation apiKey={apiKey} />
            ) : (
              postsToDisplay.map((post, index) => (
                <CampaignPost
                  key={index}
                  index={index}
                  post={post}
                  apiKey={apiKey}
                />
              ))
            )}
          </Box>
        </Box>
      </Stack>
      <Modal
        open={isApiKeyModalOpen}
        onClose={() => setIsApiKeyModalOpen(false)}
        aria-labelledby="api-key-modal-title"
        aria-describedby="api-key-modal-description"
      >
        <Paper
          elevation={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            maxWidth: "90%",
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h5" component="h2" gutterBottom>
            Enter API Key
          </Typography>
          <form onSubmit={handleApiKeySubmit}>
            <TextField
              fullWidth
              label="API Key"
              variant="outlined"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
            <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                startIcon={<SendIcon />}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Paper>
      </Modal>
    </>
  );
};

export default DemoCampaignPage;
