import { AWS_SERVER_URL } from "../CampaignConst";
import { Agent, RawAgent } from "../CampaignInterface";
import { camelToSnake, snakeToCamel } from "./UsersService";

interface AddAgentRequest {
    userId: string;
    campaignId: string;
    rawAgent: RawAgent;
    bucketName?: string;
}

export async function addAgent(request: AddAgentRequest): Promise<Agent> {
    const lambdaUrl = 'https://7epe5iymaukgwqpytrhkwlw57q0rgjar.lambda-url.us-east-1.on.aws/'
    request.rawAgent.model ? request.rawAgent.model = request.rawAgent.model : request.rawAgent.model = 'gpt-4o';
    const snakeCaseRequest = camelToSnake(request);
    const response = await fetch(`${lambdaUrl}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(snakeCaseRequest)
    });

    if (!response.ok) {
        throw new Error(`Error adding agent: ${response.statusText}`);
    }
    const finalResponse = await response.json();
    console.log(finalResponse)
    return snakeToCamel(finalResponse);
}


export async function getAgentsByCampaignId(campaignId: string): Promise<Agent[]> {
    const lambdaUrl = 'https://t3yjudtfiur2zk4houdpcfw43i0ivhoc.lambda-url.us-east-1.on.aws/'

    const response = await fetch(`${lambdaUrl}?campaign_id=${campaignId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error(`Error fetching agents: ${response.statusText}`);
    }
    const finalResponse = await response.json();
    return snakeToCamel(finalResponse);
}


export async function getAgentById(agentId: string): Promise<Agent> {


    const response = await fetch(`${AWS_SERVER_URL}/agents/by-id/${agentId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error(`Error fetching agent: ${response.statusText}`);
    }
    const finalResponse = await response.json();
    return snakeToCamel(finalResponse);
}


export async function editAgent(agentId: string, updateRequest: RawAgent): Promise<Agent> {
    const lambdaUrl = 'https://j27xqrqoyin2l7k4xysyewfgdu0rhqzm.lambda-url.us-east-1.on.aws/'
    const snakeCaseRequest = camelToSnake(updateRequest);
    const response = await fetch(`${lambdaUrl}?agent_id=${agentId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(snakeCaseRequest)
    });

    if (!response.ok) {
        throw new Error(`Error updating agent: ${response.statusText}`);
    }
    const finalResponse = await response.json();
    console.log(finalResponse)
    return snakeToCamel(finalResponse);
}

export async function deleteAgent(agentId: string): Promise<{ message: string }> {
    const lambdaUrl = 'https://xdmm75xqybsl3xvaswgdgehmcq0yospc.lambda-url.us-east-1.on.aws/'
    const response = await fetch(`${lambdaUrl}?agent_id=${agentId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error(`Error deleting agent: ${response.statusText}`);
    }
    const finalResponse = await response.json();
    return snakeToCamel(finalResponse);
}
