import React, { useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { campaignDisplayStyles } from './CampaignStyles';
import { Agent, Campaign } from '../CampaignInterface';
import CampaignSelector from './CampaignSelector';
import AgentSelector from './AgentSelector';
import AddCampaignDialog from './AddCampaignDialog';
import AddAgentDialog from './AddAgentDialog';

interface CampaignDisplayProps {
  selectedAgent: Agent;
  setSelectedAgent: React.Dispatch<React.SetStateAction<Agent>>;
  setAgents: (agents: Agent[]) => void;
  agents: Agent[];
  fetchAgents: (campaignId: string) => Promise<void>;
  campaigns: Campaign[];
  setCampaigns: React.Dispatch<React.SetStateAction<Campaign[]>>;
  selectedCampaign: Campaign;
  updateCampaigns:  () => Promise<void>;
  setSelectedCampaign: React.Dispatch<React.SetStateAction<Campaign>>;
}

const CampaignDisplay: React.FC<CampaignDisplayProps> = ({
  selectedAgent,
  setSelectedAgent,
  setAgents,
  agents,
  fetchAgents,
  updateCampaigns,
  campaigns,
  setCampaigns,
  selectedCampaign,
  setSelectedCampaign
}) => {
  const [openAddCampaignDialog, setOpenAddCampaignDialog] = useState(false);
  const [openAddAgentDialog, setOpenAddAgentDialog] = useState(false);
  const styles = campaignDisplayStyles;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenAddCampaignDialog = () => setOpenAddCampaignDialog(true);
  const handleCloseAddCampaignDialog = () => setOpenAddCampaignDialog(false);

  const handleOpenAddAgentDialog = () => setOpenAddAgentDialog(true);
  const handleCloseAddAgentDialog = () => setOpenAddAgentDialog(false);
  const updateAgents = async () => {
    if (selectedCampaign.campaignId) {
      await fetchAgents(selectedCampaign.campaignId);
    }
  };

  return (
    <Box sx={{ ...styles.container, flexDirection: isMobile ? 'column' : 'row' }}>
      <Box sx={{ pr: isMobile ? 0 : 2 }}>
        <CampaignSelector
          selectedCampaign={selectedCampaign}
          setSelectedCampaign={setSelectedCampaign}
          campaigns={campaigns}
          fetchAgents={fetchAgents}
          onAddCampaign={handleOpenAddCampaignDialog}
          updateCampaigns = {updateCampaigns}
        />
      </Box>
      <Box sx={{ pl: isMobile ? 0 : 2, mt: isMobile ? 2 : 0 }}>
        <AgentSelector
          updateAgents={updateAgents}
          selectedAgent={selectedAgent}
          setSelectedAgent={setSelectedAgent}
          agents={agents}
          selectedCampaign={selectedCampaign}
          onAddAgent={handleOpenAddAgentDialog}
        />
      </Box>
      <AddCampaignDialog
        userId={selectedCampaign.campaignCreatorId}
        open={openAddCampaignDialog}
        onClose={handleCloseAddCampaignDialog}
        setCampaigns={setCampaigns}
        campaigns={campaigns}
      />
      <AddAgentDialog
        open={openAddAgentDialog}
        onClose={handleCloseAddAgentDialog}
        setAgents={setAgents}
        agents={agents}
        selectedCampaign={selectedCampaign}
      />
    </Box>
  );
};

export default CampaignDisplay;