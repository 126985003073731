import React, { useState, useEffect } from 'react';
import { DATABASE_BASEURL, LOGS_DB_CONTAINER, POSTS_DB } from '../../utils/constant';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, CircularProgress, Button, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useStyles } from '../Feed/FeedStyle';
import RefreshIcon from '@mui/icons-material/Refresh';

export const logStyles = {
    tableContainer: {
        maxWidth: 800,
        margin: 'auto',
        marginTop: 4,
        boxShadow: 3,
    },
    headerCell: {
        backgroundColor: '#007bff',
        color: 'white',
        fontSize: 16,
    },
    row: {
        '&:hover': {
            backgroundColor: '#f5f5f5',
            cursor: 'pointer',
        },
    },
    detailText: {
        fontFamily: 'monospace',
        padding: 2,
        backgroundColor: '#f7f7f7',
        borderRadius: 2,
        margin: 1,
    },
    header: {
        textAlign: 'center',
        margin: 2,
    },
};

interface User {
    given_name: string;
    family_name: string;
    nickname: string;
    name: string;
    picture: string;
    locale: string;
    updated_at: string;
    email: string;
    email_verified: boolean;
    sub: string;
}

interface LogEntry {
    user: User;
    id: string;
    type: string;
    timestamp: string;
    // Add additional fields from your JSON format as needed
    // For the 'comment' type log, include fields specific to that type
    url?: string;
    likes?: number;
    image_url?: string;
    date?: string;
    video_url?: string;
    shares?: number;
    proIsrael?: boolean;
    text?: string;
    img_description?: string;
    userAdded?: boolean;
    answeredBy?: any[];
    bookmarkBy?: any[];
    viralityScore?: number;
    isSent?: boolean;
    previousResponses?: any[];
    post_id?: string;
    responseText?: string;
    _rid?: string;
    _self?: string;
    _etag?: string;
    _attachments?: string;
    _ts?: number;
}


const LogsPage = () => {
    const [logs, setLogs] = useState<LogEntry[]>([]);
    const [expandedLog, setExpandedLog] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const baseUrl = `${DATABASE_BASEURL}/get_posts?container=${LOGS_DB_CONTAINER}&database=${POSTS_DB}`;
    const fetchLogs = () => {
        setLoading(true);
        fetch(baseUrl)
            .then(response => response.json())
            .then((data: LogEntry[]) => {
                setLogs(data.reverse());
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching logs:', error);
                setLoading(false);
            });
    };
    useEffect(() => {
        fetchLogs();
    }, []);

    const handleRowClick = (logId: string) => {
        setExpandedLog(expandedLog === logId ? null : logId);

    };
    const navigate = useNavigate();
    const classes = useStyles;
    console.log(logs)
    return (
        
        <Box>
        <Button  onClick = {()=>navigate("/Feed")} sx={classes.navButton}>Back To Feed</Button>
            
            <Typography variant="h4" sx={logStyles.header}>Logs</Typography>
            {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                            <CircularProgress />
                        </Box>
                    ) :
            <TableContainer component={Paper} sx={logStyles.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={logStyles.headerCell}>Type</TableCell>
                            <TableCell sx={logStyles.headerCell}>User</TableCell>
                            <TableCell sx={logStyles.headerCell}>Mail</TableCell>
                            <TableCell sx={logStyles.headerCell}>Timestamp</TableCell>
                            <Box sx={logStyles.headerCell}>
                            <IconButton onClick={fetchLogs} sx={{mb:'6px',color:'white', height: '50px', width: '50px'}}><RefreshIcon />
                            </IconButton>
                            </Box>
                        </TableRow>
                        
                    </TableHead>
                    
                        <TableBody>
                            {logs.map(log => (
                                <React.Fragment key={log.id}>
                                    <TableRow sx={logStyles.row} onClick={() => handleRowClick(log.id)}>
                                        <TableCell>{log.type}</TableCell>
                                        <TableCell>{log.user?.name? log.user?.name : " "}</TableCell>
                                        <TableCell>{log.user?.email? log.user?.email : " "}</TableCell>
                                        <TableCell>{log.timestamp? log.timestamp : " "}</TableCell> 

                                    </TableRow>
                                    {expandedLog === log.id && (
                                        <TableRow>
                                            <TableCell colSpan={3} sx={logStyles.detailText}>
                                                <pre>{JSON.stringify(log, null, 2)}</pre>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </React.Fragment>
                            ))}
                        </TableBody>
                </Table>
            </TableContainer> 
            }
        </Box>
    );
};


export default LogsPage;