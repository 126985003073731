import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, Chip, Dialog, DialogActions, DialogTitle, Divider, IconButton, SxProps, Theme, ToggleButton, ToggleButtonGroup, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material";
import PostEmbbding from "../Shared/PostEmbbeding";
import { Post } from "../../utils/interfaces";
import { useEffect, useState } from "react";
import { mobile_card_height, feed_regular_card_height, POSTS_DB_CONTAINER, POSTS_DB } from "../../utils/constant";
import { DesignIsraelChip, DesignViralChip, capitalizeFirstLetter, printVirality, recognizePlatform } from "../../utils/postUtils";
import FavoriteIcon from '@mui/icons-material/FavoriteBorderRounded';
import FightIcon from '@mui/icons-material/Whatshot';
import { CardActionStyles, dialogWidth } from "../Feed/FeedStyle";
import FeedAnswerCard from "../AnswerCard/FeedAnswerCard";
import CloseIcon from '@mui/icons-material/Close';
import { useAuth0 } from "@auth0/auth0-react";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { addBookmarkForUser, removeBookmarkForUser } from "../../utils/webServices";

interface PostCardProps {
    handleFlip: () => void,
    post: Post;
    onDialogOpen: (state: boolean, id: number) => void;
    blur: boolean,
    isMobile: boolean,
    isIsrael: boolean

}

const FeedPostCard = ({ isIsrael, handleFlip, isMobile, post, onDialogOpen, blur }: PostCardProps) => {
    const { user, isAuthenticated } = useAuth0();

    let virality = printVirality(post.viralityScore || 0);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDescDialog, setOpenDescDialog] = useState(false);

    const userBookmarked = post.bookmarkBy ? post.bookmarkBy.includes(user?.email ? user.email : 'no user') : false;

    const [isBookmarked, setIsBookmarked] = useState<boolean>(userBookmarked);
    if (post.proIsrael) virality[1] = "#82e97d";
    const styles = CardActionStyles;

    const toggleButtonStyle: SxProps<Theme> = {
        border: 'none', // Remove the border
        padding: 0, // Remove padding to reduce size
        '&:hover': {
            backgroundColor: 'transparent', // Keep background transparent on hover
        },
        '&.Mui-selected': {
            backgroundColor: 'transparent', // Keep background transparent when selected
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
    };

    const handleHelpPostClick = () => {
        if (isMobile) {
            handleFlip()
        }
        else { setOpenDialog(true); }
        onDialogOpen(true, post.id); //blurs all other posts
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        onDialogOpen(false, post.id);
    }
    const handleCloseDescDialog = () => {
        setOpenDescDialog(false);
    }


    const handleBookmarkClick = () => {
        const newBookmarkState = !isBookmarked;
        setIsBookmarked(newBookmarkState);
        newBookmarkState
            ? addBookmarkForUser(user?.email ?? 'not found', POSTS_DB_CONTAINER, POSTS_DB, post.id)
            : removeBookmarkForUser(user?.email ?? 'not found', POSTS_DB_CONTAINER, POSTS_DB, post.id);
    };

    const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));

    return (
        <>
            <Card
                raised
                elevation={24}
                sx={() => ({
                    ...styles.outerCard,
                    filter: openDialog ? 'blur(14px)' : blur ? 'blur(14px)' : 'none', // blurs the original posts + all the others
                    opacity: post.isSent ? '0.3' : '1',
                })
                }>
                <Box sx={styles.cardHeader}>
                    <Box sx={styles.likeSection}>
                        <Tooltip title="Bookmark this post ">
                            <ToggleButton sx={toggleButtonStyle}
                                selected={isBookmarked}
                                color="primary"
                                value='book'
                                onClick={handleBookmarkClick}
                                aria-label="left aligned">
                                <BookmarkIcon />
                            </ToggleButton>
                        </Tooltip>

                        <Box sx={styles.platformTag}>
                            <Typography sx={{
                                fontSize: { xs: "10px", sm: '10px', md: "12px" },
                            }} fontWeight={'bold'} variant="subtitle2">{capitalizeFirstLetter(recognizePlatform(post.url))}</Typography>
                        </Box>

                        {<Box>
                            <Chip
                                icon={<FightIcon sx={{ color: DesignIsraelChip(post.proIsrael)[0] }} />}
                                variant="outlined"
                                label={post.proIsrael ? "Pro-Israel" : "Anti-Israel"}
                                color="error"
                                size="small"
                                sx={
                                    () => ({
                                        ...styles.Chip,
                                        fontSize: { xs: "10px", sm: '10px', md: "12px" },
                                        color: DesignIsraelChip(post.proIsrael)[0],
                                        background: DesignIsraelChip(post.proIsrael)[1],
                                    })
                                }
                            />
                            <Chip
                                icon={<FightIcon sx={{
                                    color: DesignViralChip(virality[0])[0]
                                }} />}
                                color="error"

                                variant="outlined"
                                label={virality[0]}
                                size="small"
                                sx={() => ({
                                    ...styles.Chip,
                                    fontSize: { xs: "8px", sm: '10px', md: "12px" },
                                    color: DesignViralChip(virality[0])[0],
                                    background: DesignViralChip(virality[0])[1],
                                })

                                }
                            /></Box >
                        }

                    </Box>
                </Box>
                <CardContent sx={styles.ResponsiveHeight}>
                    <Box sx={styles.EmbededPost}>
                        <Box className="custom-scrollbar" sx={styles.ResponsiveHeight}>
                            <PostEmbbding key={post.url} url={post.url} />
                        </Box>
                    </Box>
                </CardContent>

                <CardActions disableSpacing sx={styles.cardActions}>
                    <Tooltip title="Press to respond to this post">
                        <Button
                            onClick={handleHelpPostClick}
                            variant="contained"
                            sx={styles.fightBackButton}
                            startIcon={<FightIcon />}
                        >
                            {post.proIsrael ? "Help Post" : "Fight back"}
                        </Button>
                        
                    </Tooltip>

                    {(post.userAdded ?? false) && <Chip
                        icon={<StarBorderIcon sx={{
                            color: "blue"
                        }} />}

                        variant="outlined"
                        label='Added By User'
                        size="small"
                        sx={() => ({
                            ...styles.Chip,
                            fontSize: { xs: "8px", sm: '10px', md: "12px" },
                        })
                        }
                    />}
                    <Box sx={{
                        ml: '5px', color: "white",
                        fontSize: '12px', '&:active': {
                            color: "black",
                        },
                    }}>post ID: {post.id}</Box>
                
                </CardActions>
               
            </Card >

            <Dialog sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: dialogWidth,  // Set your width here
                    },
                },
            }} open={openDialog} onClose={() => handleCloseDialog()}>

                <Box sx={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'row', }}>
                    <IconButton
                        aria-label="close"
                        onClick={() => handleCloseDialog()}
                        sx={{ height: '20px', width: '20px', ml: '5px', mt: '5px' }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {DescDialog(post, isMobile)}
                </Box>
                <FeedAnswerCard isMobile={isMobile} handleFlip={() => setOpenDialog(prev => !prev)} post={post} onDialogOpen={onDialogOpen} isOpen={openDialog} />
            </Dialog>

        </>

    );
}

export const DescDialog = (post: Post, isMobile: boolean) => {
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <Button sx={{ width: '150px', fontSize: '9px' }} onClick={handleOpenDialog} color="primary">
                Click to see what we see
            </Button>

            <Dialog
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: isMobile ? '95vw' : dialogWidth,
                            borderRadius: '16px', // Added rounded corners
                        },
                    },
                }}
                open={openDialog}
                onClose={handleCloseDialog}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDialog}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>

                <Card elevation={5}> {/* Elevated card for a nicer look */}
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Post Content
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {post.img_description}
                        </Typography>
                    </CardContent>
                </Card>

            </Dialog></>

    );
}

export default FeedPostCard