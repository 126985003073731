
import { Campaign, FacebookPost, Post } from "./interfaces"

export const USERS_COMMENTS_CONTAINER = 'CommentPosts'
export const USERS_REPORTS_CONTAINER = 'UserReports'
export const POSTS_DB_CONTAINER = 'DemoPostsDB'

export const FACEBOOK_APP_ID = '2432061427183374'

export const development = false;
export const AMAZON_CAMPAIGN_ENDPOINTS2 = development ? 'https://10.0.0.4:3000/api' : 'https://13.48.1.182:8000'

export const LAMBDA_ENDPOINTS = {
    GET_AGENTS_BY_CAMPAIGN: "https://7hb4dibchewvmcc5k3l36v567m0hwusi.lambda-url.eu-north-1.on.aws?campaign_id=",
    GET_CAMPAINGS_BY_USER: 'https://vcr2oghmltw6ukffbi6vbsifsu0kgdut.lambda-url.eu-north-1.on.aws?user_id=',
    GET_CAMPAIGN_BY_CAMAPIGN_ID: 'arn:aws:lambda:eu-north-1:347914412720:function:getCampaignById',
    GENERATE_RESPONSE: 'https://jcbaxj2tik6vwd5pciwhi2akcu0rscnh.lambda-url.eu-north-1.on.aws',
    REGISTER_USER: 'https://upuaigfdsjtfrndjnuflu4fugm0rztxg.lambda-url.eu-north-1.on.aws/',
    GET_AGENT_BY_ID: 'https://jftqe5wtkqelu6nqhaihjv2vzm0dvnee.lambda-url.eu-north-1.on.aws/',
    CREATE_AGENT: 'https://4qfvh5a5nvbitxgeaeijvgtfuy0skqpu.lambda-url.eu-north-1.on.aws/',
    CREATE_CAMPAIGN: 'https://ipgjt3wxrkyufazryy7bawmtye0xlqnx.lambda-url.eu-north-1.on.aws/',
}
export const LOGS_DB_CONTAINER = 'UserLogs'

export const POSTS_DB = 'Website'

export const card_height = 1200;
export const post_to_show = 10;
export const BOOKMARK = 'bookmark'
export const SENT = 'sent';
export const DATABASE_BASEURL = `https://rhetoric-ai-database.azurewebsites.net/api/`;

export const createQueryForBookmarked = (mail: string) => `SELECT * FROM c WHERE IS_DEFINED(c.bookmarkBy) AND ARRAY_CONTAINS(c.bookmarkBy, '${mail}')`

const ThreadIDs = ["f634db09-1f71-48d8-bd54-08dfaee905d3",
"0352c251-70e3-4e59-afb7-2b7be03dfb08",
"032e4a9a-83f7-403a-bbd7-62165b5700c1",
`805d5eb8-8513-444e-b473-5bb112d7cdac`,
"ff36b670-fbd5-4d74-9cb5-0e2bf452da9a"
]
export const FACEBOOK_POSTS: FacebookPost[] = [
    {
        id: '123213241234566',
        url: 'https://www.facebook.com/ajplusenglish/posts/pfbid0ZkcjjqUWuE87vv6AVRtVmYCNSq7qUBdbLMcK9zx9jqQa7HzfV5evxzEb1EsNkw7zl',
        message: `Israel’s attacks in Gaza have killed at least 100 Palestinian journalists and media workers. Others have been severely wounded, forcibly displaced or lost entire families. These journalists report on Israel's attacks, the resulting starvation and humanitarian crisis in Gaza, all while experiencing it themselves.
        Now, Palestinian journalists are calling on U.S. reporters to boycott the White House Correspondents’ Dinner in protest of the Biden administration’s actions toward Gaza. “It is unacceptable to stay silent out of fear or professional concern while journalists in Gaza continue to be detained, tortured, and killed for doing our jobs,” the journalists wrote in a letter calling for the boycott.
        As press organizations continue to raise concerns about Israel’s deliberate targeting of journalists, the Biden administration has made over one hundred military sales to Israel since Oct. 7.`,
        commentsCount: 11,
        comments: [
            {
                isPrepeared: { isPrepeared: true, answer: `Christa, while I share your concerns about the Israeli government's actions, it's inaccurate and inflammatory to call Israel a "terrorist regime." The situation is complex, and the U.S. has a responsibility to defend Israeli civilians from rocket attacks, which is why supporting the Iron Dome is important. At the same time, the Biden administration is right to take a firm stance against the Israeli government's most extreme and provocative policies in order to protect Palestinian rights and preserve the possibility of a two-state solution. Painting this as Biden being controlled by Israel is a gross oversimplification that ignores the nuances of the U.S.-Israel relationship and the valid security concerns on both sides.` },
                id: "1",
                author: { name: "Christa Van Vuuren", id: "1" },
                content: "Supporting such a sadistic, cruel and vile regime makes Biden complicit and it will be his legacy.\n\nHe pretends to be a Christian and a good man, but actions speak louder than words.\n\nHe's sacrificing the lives of innocents, his presidency, America and relative peace for WHAT and WHY?\n\nThe President of the most powerful nation on earth and such a small terrorist regime has all the power over him?",
                likes: 6,
                comments: [
                    {
                        id: "2",
                        author: { name: "James Hendrick", id: "2" },
                        content: "Christa Van Vuuren Hello dear, please can I ask you a question?",
                        likes: 0
                    },
                    {
                        id: "3",
                        author: { name: "Ray Albert", id: "3" },
                        content: "Christa Van Vuuren Warm greetings, It's nice to meet you, It's always nice when we make new friends. I find your publication interesting, sensitive and with a good sense of humor. I really want to send you a friend request but it isn't appropriate with…\n\nSee more",
                        likes: 0
                    }
                ]
            },
            {
                id: "4",
                author: { name: "Matt Taylor", id: "4" },
                content: `"Journalists and media workers" LoL\n\nJust because Al Jazeera gives out press credentials to terrorists doesn't magically give them immunity from military action."`,
                likes: 7,
                comments: [
                    {
                        id: "5",
                        author: { name: "Laura Sluijter", id: "5" },
                        content: "Matt Taylor the terrorists are the zionists. The nazis of today.",
                        likes: 0
                    },
                    {
                        id: "6",
                        author: { name: "Oliva Ava", id: "6" },
                        content: "Matt Taylor I was just going through a post on a page, than your profile caught my attention. you seem worth talking to so I couldn't pass without saying hello. hope I didn't do wrong?",
                        likes: 0
                    },
                    {
                        id: "7",
                        author: { name: "Saif Rahman", id: "7" },
                        content: "Matt Taylor yea when you have your family and children being killed mercilessly and when your being called terrorists then u ll understand well",
                        likes: 0
                    }
                ]
            },
            {
                id: "8",
                author: { name: "Post America", id: "8" },
                content: "Muslims must heed a dire warning as they navigate their electoral choices amidst the complexities of Joe Biden's stance on Israel and the looming possibility of a Donald Trump victory, which could echo past discriminatory measures against their communi…\n\nSee more",
                likes: 0
            },
            {
                id: "9",
                author: { name: "Rhys Turley", id: "9" },
                content: ` "maybe AJ shouldn't employ terrorists as "journalists"\n\nhttps://nationalpost.com/.../al-jazeera-journalist-outed..."`,
                likes: 0,
                comments: [
                    {
                        id: "10",
                        author: { name: "Laura Sluijter", id: "10" },
                        content: "Rhys Turley obvious you don't have morals and a heart.",
                        likes: 0
                    },
                    {
                        id: "11",
                        author: { name: "Rhys Turley", id: "11" },
                        content: "Laura Sluijter\n\nYou are literally acting as an apologist for a terrorist organisation that rounded toddlers into a kindergarten and set it on fire …\n\nSee more",
                        likes: 0
                    }
                ]
            },
            {
                id: "12",
                author: { name: "Mark Farahani", id: "12" },
                content: "The Israeli has no right to any land W. Western nations knows that , Stop misleading theWorld , the Israeli just killed over 200, journalists, the Israeli Jews Leader officially announced Palestinian people are ANIMAL and they should be killed, Neta…\n\nSee more",
                likes: 0
            },
            {
                id: "13",
                author: { name: "Abrafi Sanyika", id: "13" },
                content: "Biden is going to have to answer for this atrocious complicity in genocide. There's no way around it. The US is shipping military supplies? While Israel is blocking food and humanitarian supplies to Gaza?\n\nGod is watching....",
                likes: 0
            },
            {
                id: "14",
                author: { name: "Carol Ashcraft", id: "14" },
                content: "",
                likes: 0
            },
            {
                id: "15",
                author: { name: "Di No", id: "15" },
                content: "Arabian countries they're are coward",
                likes: 0
            },
            {
                id: "16",
                author: { name: "Laurie Ware", id: "16" },
                content: "Stop giving Israel anything!",
                likes: 7,
                comments: [
                    {
                        id: "17",
                        author: { name: "William L Henderson", id: "17" },
                        content: "Laurie Ware Hello there, I am William by name what do I call you? Please if you don't mind can we be friends?",
                        likes: 0
                    }
                ]
            },
            {
                id: "18",
                author: { name: "Oban Louka", id: "18" },
                content: "You wanted a war ? Well. Now, assume it .\n\nWelcome into the real world",
                likes: 0
            },
            {
                id: "19",
                author: { name: "Jimmy Queso", id: "19" },
                content: "#FuckAmeriKKKa\n\n#FreePalestine\n\n#zionismisnazism",
                likes: 0
            },
            {
                id: "20",
                author: { name: "Disman Muzz", id: "20" },
                content: "For all this genocide US... EU Are to be blamed for financial and weapons support and wven tbey give white phosphorus bombs to Reduce Palestinians population......",
                likes: 0
            },
            {
                id: "21",
                author: { name: "Usman Munir", id: "21" },
                content: "Now trying to distract you with Iran, while they continue with their onslaught on the Palestinians...",
                likes: 0
            }
        ]
    },

    {
        id: '1243215513251325',
        url: 'https://www.facebook.com/ajplusenglish/posts/pfbid02mwyChHzcX7xZCkSM49cVHQFWRqD8Xeq45W1fHTay3NsSeb5TpSKQQaKsz7GUbntol',
        message: `Germany is increasingly cracking down on pro-Palestine voices, often claiming antisemitism when critics call out Israel’s attacks on Gaza. In addition to arresting increasing numbers of protesters, over 200 police officers shut down a pro-Palestine conference, the Palestine Congress, on April 12. Two speakers for the event, a British Palestinian surgeon and the former Greek finance minister, were banned from entering Germany or addressing the German public via livestream or video call.  ⁣
       Dr. Ghassan Abu Sittah worked to save Palestinian lives in Gaza during the first two months of Israel’s assault, and has been cited in South Africa’s case accusing Israel of genocide. Ex-Greek finance minister Yanis Varoufakis is an EU citizen who once ran as a candidate for the European Parliament in Germany. ⁣`,
        commentsCount: 52,
        comments: [
            {
                "id": "1",
                "author": { "name": "Lester Chin", "id": "lester1" },
                "content": "Good job germany!!",
                "likes": 20
            },
            {
                "id": "10",
                "author": { "name": "Ali Yahya Raee", "id": "ali1" },
                "content": "The German government has genocidal tendencies in their blood. A leopard will never change its spots.",
                "likes": 14,
                isPrepeared: {
                    isPrepeared: true,

                    answer: `Ali, your comment about Germany having "genocidal tendencies in their blood" is an offensive stereotype that has no place in this discussion. While reasonable people can disagree on Israel-Palestine issues, Germany is right to take a strong stance against antisemitism given their history. Protests that cross the line into anti-Jewish bigotry and extremism should not be tolerated.\n\nAt the same time, it's important that legitimate criticism of Israeli government policies not be conflated with antisemitism. Banning speakers like Dr. Abu Sittah and Mr. Varoufakis from even virtual participation seems like an overreach that stifles needed debate. Germany can support Israel's right to self-defense while still allowing open discussion about the conflict and its humanitarian toll.\n\n These are complex issues, but painting Germany as inevitably genocidal and shutting down all pro-Palestinian advocacy is wrong. What's needed is more dialogue, not less, and a commitment from all sides to reject bigotry and work toward a just, peaceful solution.`},

                "comments": [
                    {
                        "id": "10-1",
                        "author": { "name": "Kingsford-hails Samuel", "id": "kingsford1" },
                        "content": "do u even have those rights in Muslims countries?",
                        "likes": 6,
                        "comments": [
                            {
                                "id": "10-1-1",
                                "author": { "name": "Ali Yahya Raee", "id": "ali1" },
                                "content": "No, we dont have genocidal tendencies.",
                                "likes": 7
                            },
                            {
                                "id": "10-1-2",
                                "author": { "name": "Kingsford-hails Samuel", "id": "kingsford1" },
                                "content": "why didn't u protest when Syrian president killed thousands of of his people, Saudi Arabia bomb a hell out of Yemen for the past 9years?",
                                "likes": 9
                            }
                        ]
                    }
                ]
            },
            {
                "id": "2",
                "author": { "name": "Tom Bezigian Sr.", "id": "tom1" },
                "content": "Germany is over-the-top on its guilt-trip over what happened during the war. I give the country great credit for its reformation, but banning another EU citizen is remarkable. Being a republic, each state has its own rules, but this seems excessive.",
                "likes": 15,
                "comments": [
                    {
                        "id": "2-1",
                        "author": { "name": "Justin Joslin", "id": "justin1" },
                        "content": "It’s political theatre. They’re not redacted. They know exactly what they’re doing",
                        "likes": 5
                    }
                ]
            },
            {
                "id": "3",
                "author": { "name": "Diana", "id": "diana1" },
                "content": "Colonizer supports colonizer, not surprised at all.",
                "likes": 10
            },
            {
                "id": "4",
                "author": { "name": "মুহাম্মাদ আফজালুর রাহমান", "id": "muhammad1" },
                "content": "German 🇩🇪 government was wrong in 1945 and doing wrong again in 2023-2024. They didn’t learn from history. Shame on German Government.",
                "likes": 18
            },
            {
                "id": "5",
                "author": { "name": "Brittany Cintron", "id": "brittany1" },
                "content": "Germany on the wrong side of history AGAIN. SMH",
                "likes": 12
            },
            {
                "id": "6",
                "author": { "name": "Kolawole Ibiyemi", "id": "kolawole1" },
                "content": "Israel is not only killing Palestinians. Israel is killing freedom of speech and free media, democracy, human rights, and UN. This conflict is not new. It is a conflict that exist for 75 years now. How come Israel is called “the only democracy in the M…",
                "likes": 22
            },
            {
                "id": "7",
                "author": { "name": "Azra Begić", "id": "azra1" },
                "content": "No way… this can’t be real life!!since when are war heroes are treated worse than the actual war perpetrators!!",
                "likes": 25
            },
            {
                "id": "8",
                "author": { "name": "Anowar M. Datucali", "id": "anowar1" },
                "content": "Democracy no longer exists in the western countries.",
                "likes": 13
            },
            {
                "id": "9",
                "author": { "name": "Karima Bo", "id": "karima1" },
                "content": "Nazi methods: crack down every critical voice. Spread fake or one-sided news.",
                "likes": 11
            },

            {
                "id": "11",
                "author": { "name": "Joan Crounse", "id": "joan1" },
                "content": "These demonstrations aren’t pro-Palestinian. They are pro-Hamas and anti-Israel.",
                "likes": 17
            },
            {
                "id": "12",
                "author": { "name": "Scott Campbell", "id": "scott1" },
                "content": "You would have thought that 14000 murdered babies and children would be enough to turn the tide against Israel. The result of 70 years of planning..",
                "likes": 19
            },
            {
                "id": "13",
                "author": { "name": "Nihal Chebbo", "id": "nihal1" },
                "content": "Surprised? Anyone?",
                "likes": 8
            },
            {
                "id": "14",
                "author": { "name": "Jude Jeremy Walawski", "id": "jude1" },
                "content": "Old Nazis helping out the new up and coming Nazis",
                "likes": 16
            }
        ]
        ,

    },

    {
        id: '12342561436143',
        url: 'https://www.facebook.com/ajplusenglish/posts/pfbid024T654hPcBbkYBMiFdHFDBU9nPz4rEpMYnk3bd52yV7R64AwCXKacw8LLRo5MvzZil',
        message: `Today is Palestinian Prisoner’s Day. There are currently over 9,500 Palestinians being held in Israeli jails or prisons, including hundreds of children.  ⁣
        ⁣
       Nael Barghouti is one of those thousands and the longest-serving Palestinian prisoner in Israel. He has been in Israeli prisons for a total of 44 years. ⁣
        ⁣
       Nael was initially released in 2011 after serving 34 years in prison. He was freed as part of a prisoner exchange. During his three years of freedom, he married Eman Nafe, who herself had spent 10 years in Israeli prisons.  ⁣
        ⁣
       But in 2014, Israeli forces re-arrested him and about 70 other released Palestinians, and later re-instated and extended his life sentence based on “secret evidence.” ⁣
        ⁣
       Nael’s story is one of thousands of Palestinians subject to Israeli occupation and unequal Israeli military courts. Since Oct. 7, thousands of other Palestinians in Gaza and the occupied West Bank have been detained or arrested – many without a trial or charges – and subject to abuse and torture in custody. This includes hundreds of children as well.  ⁣`,
       commentsCount:9,
       potentialAnswer: `Israel is one of America's closest allies and shares our democratic values. Like us, they have suffered from horrific terrorist attacks and have an obligation to protect their citizens. While detaining minors is far from ideal, we cannot ignore the reality that Palestinian extremist groups have used children to carry out suicide bombings and other attacks on innocent civilians. Israel must take strong action against terrorism to prevent more violence and bloodshed.
       At the same time, Israel should provide due process, treat prisoners humanely, and minimize detentions of minors as much as possible. Ultimately, there needs to be a negotiated peace agreement. But until Palestinian leaders recognize Israel's right to exist and crack down on terrorist groups, Israel has little choice but to use tough measures to defend itself.
       I've aimed to empathize with Israel's very real security concerns in a way that aligns with traditional conservative foreign policy views, while still acknowledging the need for adherence to rule of law and human rights. I've avoided delving into domestic US partisan disagreements. Let me know if this strikes a more appropriate balance for your intended audience. I'm happy to further refine the response as needed.`,
       comments: [
        {
            "id": "1",
            "author": { "name": "Syed Saif Mohiuddin", "id": "syed1" },
            "content": "May Allah swt protect them and make way for their release",
            "likes": 30,
            "comments": [
                {
                    "id": "1-1",
                    "author": { "name": "Pauline Rafferty", "id": "pauline1" },
                    "content": "Amen 🙏🙏❤️❤️🇵🇸🇵🇸✊",
                    "likes": 25
                }
            ]
        },
        {
            "id": "2",
            "author": { "name": "Sammie Glasper", "id": "sammie1" },
            "content": "1000⭐️⭐️⭐️⭐️⭐️💖",
            "likes": 15
        },
        {
            "id": "3",
            "author": { "name": "Jerry Wendenburg", "id": "jerry1" },
            "content": "A politically correct extermination and oppression",
            "likes": 20
        },
        {
            "id": "4",
            "author": { "name": "Mark Farahani", "id": "mark1" },
            "content": "As long as we receive more information about Jews, century shows they are dangerous and you can not trust them that why Hitler try to get rid of them, Israeli has no right for any land W. Western nations knows, stop misleading the World, Israeli Jew…",
            "likes": 10
        },
        {
            "id": "5",
            "author": { "name": "Deb McNeill Franchois", "id": "deb1" },
            "content": "and netanyahu trying to play the victim makes me physically ill",
            "likes": 22
        },
        {
            "id": "6",
            "author": { "name": "Jimmy Queso", "id": "jimmy1" },
            "content": "#FreePalestine 🇵🇸\n#zionismisnazism",
            "likes": 18
        },
        {
            "id": "7",
            "author": { "name": "Ayaz Raj", "id": "ayaz1" },
            "content": "#StopGenocide\n#StopGenocideOfPalestinians\n#StopGenocideInGaza …",
            "likes": 26
        },
        {
            "id": "8",
            "author": { "name": "Bukenya Mac JB", "id": "bukenya1" },
            "content": "Mayanja Fredrick please",
            "likes": 9
        }
    ]
    
    },


    {
        id: "221231234123553124",
        url: 'https://www.facebook.com/ajplusenglish/posts/pfbid0MWRXMtS7iK6TFZC7e9EvQpzQzoRFPxKSMT8Lt1uoNmyytttU3vvsvMrDEah6bZXRl',
        message: `South Asian American Muslim valedictorian Asna Tabassum has been banned from speaking at her graduation at the University of Southern California (USC) due to “security” concerns. ⁣
        ⁣
       Asna Tabassum was vocal in her support for the Palestinian people and received hate from pro-Israel groups on her campus, calling her “antisemitic.”  ⁣
        ⁣
       “Anti-Muslim and anti-Palestinian voices have subjected me to a campaign of racist hatred because of my uncompromising belief in human rights for all… I was hoping to use my commencement speech to inspire my classmates with a message of hope. By canceling my speech, USC is only caving to fear and rewarding hatred,” Tabassum said in a statement. ⁣
        ⁣
       The Council on American-Islamic Relations (CAIR-LA) has defended Tabassum and calls on USC to allow her to speak. ⁣
        ⁣
       This is the first time in USC history where a student has been banned from speaking at the commencement ceremony. ⁣
       ⁣
       In a written statement, Tabassum said, “As your class valedictorian, I implore my USC classmates to think outside the box—to work towards a world where cries for equality and human dignity are not manipulated to be expressions of hatred. I challenge us to respond to ideological discomfort with dialogue and learning, not bigotry and censorship. And I urge us to see past our deepest fears and recognize the need to support justice for all people, including the Palestinian people.” ⁣`,
        commentsCount: 20,
        comments: [
            {
                "id": "12.1.1.1.1.1.1.1.1.1",
                "author": {
                    "name": "Shoshana Sigar",
                    "id": ""
                },
                "content": "Jlizi Melek you have no idea of what goes on here, but anyway good to know how well your country is doing. Keep free",
                "likes": 0,
                "commentCount": 1,
                "comments": [
                    {
                        "id": "12.1.1.1.1.1.1.1.1.1.1",
                        "author": {
                            "name": "Jlizi Melek",
                            "id": ""
                        },
                        "content": "Shoshana Sigar how sweet, she's looking for empathise, try to show how much she suffer in order to justify her genocidal support, what a wonderful era we live in",
                        "likes": 0,
                        "commentCount": 6,
                        "comments": [
                            {
                                "id": "12.1.1.1.1.1.1.1.1.1.1.1",
                                "author": {
                                    "name": "Shoshana Sigar",
                                    "id": ""
                                },
                                "content": "Jlizi Melek I don't look for anything except to watch how miserable those supporters are. Nice to see those cleaning",
                                "likes": 0,
                                "commentCount": 5,
                                "comments": [
                                    {
                                        "id": "12.1.1.1.1.1.1.1.1.1.1.1.1",
                                        "author": {
                                            "name": "Jlizi Melek",
                                            "id": ""
                                        },
                                        "content": "Shoshana Sigar do you mean by ckeaning a ethnic cleansing that zionist doing in palestine, watch and trust me Justice will prevail for them if not in this life then in the afterlife, and then you will be the one that look so so miserable",
                                        "likes": 0,
                                        "commentCount": 4,
                                        "comments": [
                                            {
                                                "id": "12.1.1.1.1.1.1.1.1.1.1.1.1.1",
                                                "author": {
                                                    "name": "Shoshana Sigar",
                                                    "id": ""
                                                },
                                                "content": "Jlizi Melek you know Egypt is a really nice friendly place, just with a very low income, any idea why they don't seek a shelter there?",
                                                "likes": 0,
                                                "commentCount": 0
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "id": "13",
                "author": {
                    "name": "Amila Mulic",
                    "id": ""
                },
                "content": "Shoshana Sigar who said that, said the Nazi",
                "likes": 0,
                "commentCount": 1,
                "comments": [
                    {
                        "id": "13.1",
                        "author": {
                            "name": "Shoshana Sigar",
                            "id": ""
                        },
                        "content": "Amila Mulic oh another well developed corner, is it possible to travel from there?",
                        "likes": 0,
                        "commentCount": 0
                    }
                ]
            },
            {
                "id": "14",
                "author": {
                    "name": "Kyle Daniels",
                    "id": ""
                },
                "content": "The protests for Asna Tabassum are a complete joke and a distraction from the daily routines of censorship with assassinations of violent crime victims by US universities with students.\nI was knocked unconscious suddenly and repeatedly to cause bruis… See more",
                "likes": 0,
                "commentCount": 0
            },
            {
                "id": "15",
                "author": {
                    "name": "Gustavo Ortiz",
                    "id": ""
                },
                "content": "El país de la libertad...😐 es bueno que suceda estas cosas, para que la máscara vaya cayendo 🐺",
                "likes": 0,
                "commentCount": 0
            },
            {
                "id": "16",
                "author": {
                    "name": "Lucas Beekhof",
                    "id": ""
                },
                "content": "Why give a platform to radical anti-Semitism? She should have been kicked out of the institution.",
                "likes": 0,
                "commentCount": 0
            },
            {
                "id": "17",
                "author": {
                    "name": "Cole Liland",
                    "id": ""
                },
                "content": "She is 🗑️",
                "likes": 0,
                "commentCount": 0
            },
            {
                "id": "18",
                "author": {
                    "name": "Merek Salva",
                    "id": ""
                },
                "content": "Some history for you guys: Before 1917, the area was under Ottoman occupation and colonial rule, and was therefore considered Ottoman sovereign territory. The part of today's Israel and West Bank and parts of today's Jordan were known to everyone as Is… See more",
                "likes": 0,
                "commentCount": 0
            },
            {
                "id": "19",
                "author": {
                    "name": "Juan Montaño",
                    "id": ""
                },
                "content": "Good. She doesn't belong here anyway.",
                "likes": 0,
                "commentCount": 0
            },
            {
                "id": "20",
                "author": {
                    "name": "Erica MacLeod",
                    "id": ""
                },
                "content": "Good. Supporters of terrorists shouldn't be giving speeches. Ever.",
                "likes": 0,
                "commentCount": 1,
                "comments": [
                    {
                        "id": "20.1",
                        "author": {
                            "name": "Marie Haych",
                            "id": ""
                        },
                        "content": "Erica MacLeod she's pro Palestinian not terrorist israel !",
                        "likes": 0,
                        "commentCount": 1,
                        "comments": [
                            {
                                "id": "20.1.1",
                                "author": {
                                    "name": "Erica MacLeod",
                                    "id": ""
                                },
                                "content": "Marie Haych oh so you support hamas as well as her? Congrats! You're a terrorist lover too!",
                                "likes": 0,
                                "commentCount": 0
                            }
                        ]
                    }
                ]
            },
            {
                "id": "21",
                "author": {
                    "name": "Risyda Haerudin",
                    "id": ""
                },
                "content": "Freedom of speech only for them, not for they don't like. So hypocrite.",
                "likes": 0,
                "commentCount": 1,
                "comments": [
                    {
                        "id": "21.1",
                        "author": {
                            "name": "Shoshana Sigar",
                            "id": ""
                        },
                        "content": "Risyda Haerudin try to express it in Gaza",
                        "likes": 0,
                        "commentCount": 1,
                        "comments": [
                            {
                                "id": "21.1.1",
                                "author": {
                                    "name": "Risyda Haerudin",
                                    "id": ""
                                },
                                "content": "Shoshana Sigar u afraid peoples listen to her, and more peoples support Palestinians and know the truth that Israhell are evil zionist who doing genocide of Palestinians, it's happening everywhere. Truth can't be hidden forever.",
                                "likes": 0,
                                "commentCount": 1,
                                "comments": [
                                    {
                                        "id": "21.1.1.1",
                                        "author": {
                                            "name": "Kingsford-hails Samuel",
                                            "id": ""
                                        },
                                        "content": "Risyda Haerudin do u even have freedom of speech in Islamic countries?their using freedom of speech against the very society who help them",
                                        "likes": 0,
                                        "commentCount": 0
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "id": "22",
                "author": {
                    "name": "Terry Ray Gist",
                    "id": ""
                },
                "content": "No. She supports the destruction of Israel.\nShouldn't even be here let alone valedictorian.",
                "likes": 0,
                "commentCount": 0
            },
            {
                "id": "23",
                "author": {
                    "name": "Steven Bontjes",
                    "id": ""
                },
                "content": "Let her go to Palestine and give her speech. . Oh forgot she can't she has no rights there as a woman",
                "likes": 0,
                "commentCount": 0
            },
            {
                "id": "24",
                "author": {
                    "name": "Juana Jane Guerrero",
                    "id": ""
                },
                "content": "USC Trojans shame on you!",
                "likes": 0,
                "commentCount": 0
            },
            {
                "id": "25",
                "author": {
                    "name": "Brian And Jasmen English",
                    "id": ""
                },
                "content": "A anti-war music video: https://youtu.be/In3mqD3hIro",
                "likes": 0,
                "commentCount": 0
            },
            {
                "id": "26",
                "author": {
                    "name": "Brian Hoover",
                    "id": ""
                },
                "content": "I'm thrilled to announce that I've won the lottery, and I want to use this opportunity to help those in need. I'm looking for ways to make a positive impact actually made a promise to God to help the needy no matter what",
                "likes": 0,
                "commentCount": 0
            },
            {
                "id": "27",
                "author": {
                    "name": "Victor Marsella",
                    "id": ""
                },
                "content": "So, freedom of speech and freedom of exception, no longer apply if you dare speak against the glorious genocidal regime of Israel?",
                "likes": 0,
                "commentCount": 0
            },
            {
                "id": "28",
                "author": {
                    "name": "Jimmy Queso",
                    "id": ""
                },
                "content": "#zionismisnazism",
                "likes": 0,
                "commentCount": 0
            },
            {
                "id": "29",
                "author": {
                    "name": "Michel Madsac",
                    "id": ""
                },
                "content": "USC doesn't need antisemitic address !!",
                "likes": 0,
                "commentCount": 0
            },
            {
                "id": "30",
                "author": {
                    "name": "Chris Chris",
                    "id": ""
                },
                "content": "🔥🕋🔥",
                "likes": 0,
                "commentCount": 0
            },
            {
                "id": "31",
                "author": {
                    "name": "Kingsford-hails Samuel",
                    "id": ""
                },
                "content": "Anti Muslims my foot....",
                "likes": 0,
                "commentCount": 0
            },
            {
                "id": "32",
                "author": {
                    "name": "Casanova Hunching Ra-Na",
                    "id": ""
                },
                "content": "Lund😂😂",
                "likes": 0,
                "commentCount": 0
            },
            {
                "id": "33",
                "author": {
                    "name": "MD Sowrob",
                    "id": ""
                },
                "content": "Hi",
                "likes": 0,
                "commentCount": 0
            },
            {
                "id": "34",
                "author": {
                    "name": "Christian Santiago",
                    "id": ""
                },
                "content": "Thanks you wouldn't be enough for things you have done for me. I have made over $94,000 since I began investing with ma'am catherine https://www.facebook.com/ariana.anthony01",
                "likes": 0,
                "commentCount": 0
            },
            {
                "id": "35",
                "author": {
                    "name": "Voja Voja",
                    "id": ""
                },
                "content": "Honestly receiving $72,500 after investing $7,000 is what i never expected. Thanks for putting undying smile on my face https://www.facebook.com/ariana.anthony01",
                "likes": 0,
                "commentCount": 0
            },
            {
                "id": "36",
                "author": {
                    "name": "Sundeep Doyal",
                    "id": ""
                },
                "content": "Are you interested investing in crypto. Kindly contact ma'am Jessica Jenkins for more information how to get started\nhttps://www.facebook.com/Jessica.Jenkins.09?mibextid=kFxxJD",
                "likes": 0,
                "commentCount": 0
            },
            {
                "id": "37",
                "author": {
                    "name": "Mark Farahani",
                    "id": ""
                },
                "content": "Israeli has no right to any land W. Western nations knows, STOP MISLEADING THE WORLD ,\nIsraeli Jews Leader officially announced Palestinian people are ANIMAL and they should be killed, Netanyahu and his Jews soldiers killed over 49,000 innocent Women… See more",
                "likes": 0,
                "commentCount": 0
            }
        ]
    },

    {

        "id": "256029224255177_122102283212226747",
        "message": " Hamas has demanded a “clear written commitment” that Israel would withdraw from the besieged Gaza Strip during the second of a three-phase ceasefire deal. LIVE updates: aje.io/35fpkp",
        "url": "https://www.facebook.com/aljazeera/posts/pfbid0gY6dwQJnwX6gweAr9b46BYGbV8evPjgBaEmV7sz2PRHPcCeekSCpABJcPqmGvXsZl",
        "commentsCount": 5,
        "comments": [
            {
                "id": "1",
                "author": { "name": "Adc Tamanna Kabir", "id": "adc1" },
                "content": "Best leader ait ulla Khamenei ❤️",
                "likes": 10,
                "comments": [
                    {
                        "id": "1-1",
                        "author": { "name": "Nassirou Anglefati", "id": "nassirou1" },
                        "content": "I love you adc🤣🤣",
                        "likes": 2,
                        "comments": [
                            {
                                "id": "1-1-1",
                                "author": { "name": "Frans Mintoff", "id": "frans1" },
                                "content": "🖕",
                                "likes": 5
                            },
                            {
                                "id": "1-1-2",
                                "author": { "name": "Daniel Fango", "id": "daniel1" },
                                "content": "get a tent…",
                                "likes": 3
                            }
                        ]
                    },
                    {
                        "id": "1-2",
                        "author": { "name": "Daniel Fango", "id": "daniel1" },
                        "content": "“leader”😂😂😂 just another Islamic moron…",
                        "likes": 1
                    },
                    {
                        "id": "1-3",
                        "author": { "name": "Splahy Angel", "id": "splahy1" },
                        "content": "https://t.me/+GeFawUTGZHcyNDk0",
                        "likes": 4,
                        "message_url": "https://t.me/+GeFawUTGZHcyNDk0"
                    },
                    {
                        "id": "1-4",
                        "author": { "name": "Jillian A. Smith", "id": "jillian1" },
                        "content": "dont let him see you without a modesty hat",
                        "likes": 2
                    },
                    {
                        "id": "1-5",
                        "author": { "name": "Nicholas Chalaw", "id": "nicholas1" },
                        "content": "In defending his Generals like Soleimani we suppose!!!",
                        "likes": 6
                    },
                    {
                        "id": "1-6",
                        "author": { "name": "Prince Alio", "id": "prince1" },
                        "content": "He’s a serial masturbator and nose picker!",
                        "likes": 7
                    },
                    {
                        "id": "1-7",
                        "author": { "name": "Franklin Armstrong", "id": "franklin1" },
                        "content": "VOTE FOR KOOL JOE 😎\n💙 VOTE BIDEN IN 2024.",
                        "likes": 8
                    },
                    {
                        "id": "1-8",
                        "author": { "name": "S Bhattacharjee", "id": "sb1" },
                        "content": "The COWARD 😄",
                        "likes": 9
                    }
                ]
            },
            {
                "id": "2",
                "author": { "name": "Nicholas Chalaw", "id": "nicholas1" },
                "content": "The hostages first... Nothing less. If all the hostages aren't released then wait for the F 15 fighter jets in Rafa",
                "likes": 5,
                "comments": [
                    {
                        "id": "2-1",
                        "author": { "name": "Gilmore Taraya", "id": "gilmore1" },
                        "content": "mexican stand off?",
                        "likes": 3
                    },
                    {
                        "id": "2-2",
                        "author": { "name": "Andrei Negru", "id": "andrei1" },
                        "content": "a bit, yes https://www.facebook.com/share/p/XYUvmHNApvhMmWTk/",
                        "likes": 2,
                        "message_url": "https://www.facebook.com/share/p/XYUvmHNApvhMmWTk/"
                    }
                ]
            },
            {
                "id": "3",
                "author": { "name": "Jonathan Isaac", "id": "jonathan1" },
                "content": "😅😂😅😂",
                "likes": 2,
                "commentCount": 1,
                "comments": [
                    {

                        "id": "3-1",
                        "author": { "name": "Muhammad Jibreel Ong", "id": "muhammad1" },
                        "content": "ok defence minister Nicholas",
                        "likes": 3
                    },
                    {
                        "id": "3-2",
                        "author": { "name": "Splahy Angel", "id": "splahy1" },
                        "content": "https://t.me/+GeFawUTGZHcyNDk0",
                        "likes": 4,
                        "message_url": "https://t.me/+GeFawUTGZHcyNDk0"
                    },
                    {
                        "id": "3-3",
                        "author": { "name": "Hussain Rashid", "id": "hussain1" },
                        "content": "shut the f**** up…",
                        "likes": 5
                    },
                    {
                        "id": "3-4",
                        "author": { "name": "Mushab Sheriff", "id": "mushab1" },
                        "content": "find a shelter theres a muslim country wakes up already.... go n hide",
                        "likes": 6
                    }
                ]
            }
        ]

    },
   
    {
        id: '1232141377453',
        url: 'https://www.facebook.com/ajplusenglish/posts/pfbid02VH2HudvxtwhEpwWJASyYmscFWCRhzosP35CTuPrgmE4Aq7VMsWxeba3qaUUc9PmWl',
        message: `Hundreds of bodies have been found at al-Shifa Hospital, after Israeli forces finally withdrew after weeks of attacks.
        Israel’s latest raid on the hospital left it non-functional, with scores of civilians dead. Health and aid workers are now pulling bodies from the rubble.`,
        commentsCount: 23,
        comments: [
            {
                id: "1",
                author: { name: "Mohammad Bozorgnia", id: "mohammad123" },
                content: "If crime against humanity had a face...",
                likes: 10,
                comments: [
                    {
                        id: "1-1",
                        author: { name: "Precious Sophia", id: "precious456" },
                        content: "Mohammad Bozorgnia https://t.me/+57AqOEVdKxY0NDM0",
                        likes: 5,
                        message_url: "https://t.me/+57AqOEVdKxY0NDM0"
                    }
                ]
            },
            {
                id: "2",
                author: { name: "Tahir Yousafzai", id: "tahir789" },
                content: "So sad, very cruel act of I s r a i l",
                likes: 8,
                comments: [
                    {
                        id: "2-1",
                        author: { name: "Tanya Cardona", id: "tanya101" },
                        content: "That's just sick asf.",
                        likes: 4
                    },
                    {
                        id: "2-2",
                        author: { name: "Jewish Voice for Truth", id: "jvt102" },
                        content: "The bodies of Hamas ISIS terrorists who returned to Shifa hospital after the IDF left were found. The Hamas ISIS terrorists thought they would be safe in the hospital. This is the barbaric nature of Hamas ISIS to hide in hospitals, schools…",
                        likes: 2,
                        comments: [
                            {
                                id: "2-2-1",
                                author: { name: "Ahmad Askar", id: "ahmad103" },
                                content: "Jewish Voice for Truth 1. Hamas and isis literally have no relation 😂 2. Prove there were Hamas fighters in the hospitals (you can't and you won't, just like the claimed \"tunnels under the hospital\")",
                                likes: 15,
                                comments: [
                                    {
                                        id: "2-2-1-1",
                                        author: { name: "Jewish Voice for Truth", id: "jvt102" },
                                        content: "We don't have to prove anything. Israel will destroy Hamas ISIS",
                                        likes: 1,
                                        comments: [
                                            {
                                                id: "2-2-1-1-1",
                                                author: { name: "Ahmad Askar", id: "ahmad103" },
                                                content: "Jewish Voice for Truth again with the \"Hamas isis\" 😂😂😂 Stay in your delusions lol",
                                                likes: 22,
                                                comments: [
                                                    {
                                                        id: "2-2-1-1-1-1",
                                                        author: { name: "Jewish Voice for Truth", id: "jvt102" },
                                                        content: "It is true, Hamas are barbarians and murderers just like ISIS. No difference, both of them are terrorists, barbaric and extremists moslem",
                                                        likes: 3
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                id: "3",
                author: { name: "Safir Rafiq", id: "safir104" },
                content: "This European settler state must be brought to justice for genocide, war crimes and crimes against humanity. 😡",
                likes: 12,
                comments: [
                    {
                        id: "3-1",
                        author: { name: "Ch Zahid", id: "chzahid105" },
                        content: "The most moral army in the world could do it. And the ones who are abetting this horrific genocide by supplying weapons; UK, US, France, Germany....the proponents of democracy, freedom, social justice!",
                        likes: 7
                    },
                    {
                        id: "3-2",
                        author: { name: "Monique Stroop", id: "monique106" },
                        content: "So sick..and the most repulsive thing is how the rest of the world Just do not care.",
                        likes: 9
                    }
                ]
            }, {
                id: "4",
                author: { name: "Janice Blue", id: "janice107" },
                content: "The Euro-Med Human Rights Monitor said that the massacre at al-Shifa was one of the largest in Palestinian history, estimating that at least 1,500 people had been killed, about half of whom were women and children. The organization also confirms that...",
                likes: 11,
                comments: [
                    {
                        id: "4-1",
                        author: { name: "Jewish Voice for Truth", id: "jvt102" },
                        content: "Yes, yes, of course, more lies of Palestinian palewood",
                        likes: 6
                    }
                ]
            },
            {
                id: "5",
                author: { name: "Charlotte Eng", id: "charlotte108" },
                content: "The Euro-Med Human Rights Monitor said that the massacre at al-Shifa was one of the largest in Palestinian history, estimating that at least 1,500 people had been killed, about half of whom were women and children. The organization also confirms that...",
                likes: 14,
                comments: [
                    {
                        id: "5-1",
                        author: { name: "Nemuel Bar-roka", id: "nemuel109" },
                        content: "Nobody but the sick and injured were in that hospital. And they were murdered.",
                        likes: 17
                    },
                    {
                        id: "5-2",
                        author: { name: "Amar Desai", id: "amar110" },
                        content: "But the US thinks no war crimes have been committed🤮🤮🤮",
                        likes: 21,
                        comments: [
                            {
                                id: "5-2-1",
                                author: { name: "Precious Delight", id: "precious111" },
                                content: "USA legislature and executive is stupid to say the least 😭😭😭",
                                likes: 12
                            }
                        ]
                    }
                ]
            },
            {
                id: "6",
                author: { name: "Rhys Turley", id: "rhys112" },
                content: "It’s horrific that Hamas would turn a hospital into a battlefield",
                likes: 9,
                comments: [
                    {
                        id: "6-1",
                        author: { name: "Sharif Hossain Sumon", id: "sharif113" },
                        content: "Lame excuse...",
                        likes: 20,
                        comments: [
                            {
                                id: "6-1-1",
                                author: { name: "Rhys Turley", id: "rhys112" },
                                content: "Israel has every right to defend themselves",
                                likes: 8,
                                comments: [
                                    {
                                        id: "6-1-1-1",
                                        author: { name: "Sharif Hossain Sumon", id: "sharif113" },
                                        content: "With occupying other people's land, and murdering people or genocide! It is still a lame excuse.",
                                        likes: 25,
                                        comments: [
                                            {
                                                id: "6-1-1-1-1",
                                                author: { name: "Rhys Turley", id: "rhys112" },
                                                content: "Gaza wasn’t occupied And there’s no genocide Please stop lying…",
                                                likes: 5
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                id: "6-1-2",
                                author: { name: "Eashika Naznin", id: "eashika114" },
                                content: "dont reply to this dog, it keeps barking in support of genociders even after knowing everything. Its just a paid dog by IDF who keeps commenting under every post. If we dont ignore these comments will get precedence in algorithm.",
                                likes: 15
                            }
                        ]
                    }
                ]
            }
            // Additional comments can be added following the same structure.
        ]

    }
]


export const ISAREL = "israel"
export const createQueryForAnswered = (mail: string) => `SELECT * FROM c WHERE IS_DEFINED(c.answeredBy) AND ARRAY_CONTAINS(c.answeredBy, '${mail}')`
export const EMPTY_CAMPAIGNs: Campaign[] = [{
    nameOfTheCampaign: "Campaign1",
    reasonForCampaignExistence: "",
    campaignGuidelines: "",
    targetAudience: "",
    campaignCreatorId: "",
    campaignID: ""
}, {
    nameOfTheCampaign: "Campaign2",
    reasonForCampaignExistence: "",
    campaignGuidelines: "",
    targetAudience: "",
    campaignCreatorId: "",
    campaignID: ""
}]
export const LOGSTYPE = {
    COMMENT: 'comment',
    ENTER: 'enter',
    REPORT: 'report',
    ADD: 'added post'
} as const;
export type LogsType = typeof LOGSTYPE[keyof typeof LOGSTYPE];
export const PAGE_SIZE = 6;
export const mobile_card_height = 500;
export const regular_card_height = 700;

export const feed_regular_card_height = { xs: 400, lg: 700 };
export const Languages = {
    English: 'English',
    Hebrew: 'Hebrew',
    Hindi: 'Hindi',
    French: 'French',
    Spanish: 'Spanish',
    Chinese: 'Chinese',
    Arabic: 'Arabic',
    Portuguese: 'Portuguese',
    Russian: 'Russian',
    Japanese: 'Japanese',
    Korean: 'Korean',
    Indonesian: 'Indonesian',
    Malay: 'Malay',
    German: 'German',
    Bengali: 'Bengali',
    Turkish: 'Turkish',
    Vietnamese: 'Vietnamese',
    Thai: 'Thai',
    Persian: 'Persian',
    Italian: 'Italian',
    Polish: 'Polish'
};
export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 320, sm: 500 }, // Adjust the width as necessary
    bgcolor: 'background.paper', // This sets a non-transparent background
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const modalBackgroundStyle = {
    backdropFilter: "blur(3px)", // This will give a blur effect to the background
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // This sets the modal overlay's background with some transparency
};
export const LanguageCodes: { [key: string]: string } = {
    en: 'English',
    he: 'Hebrew',
    hi: 'Hindi',
    fr: 'French',
    es: 'Spanish',
    zh: 'Chinese',
    ar: 'Arabic',
    pt: 'Portuguese',
    ru: 'Russian',
    ja: 'Japanese',
    ko: 'Korean',
    id: 'Indonesian',
    ms: 'Malay',
    de: 'German',
    bn: 'Bengali',
    tr: 'Turkish',
    vi: 'Vietnamese',
    th: 'Thai',
    fa: 'Persian',
    it: 'Italian',
    pl: 'Polish'
    // Add more mappings as needed
};

let globalPostEmbeddingId = -1;
export const moveEmbedId = () => {
    globalPostEmbeddingId++;
    return globalPostEmbeddingId;
}
export const showEmbedId = () => {
    return globalPostEmbeddingId;
}

export const REPORT_OPTIONS = [
    "Incorrect or Inaccurate Response Generation",
    "Other Response Quality Issues (please specify)",
    "Layout, Display or Design Issues",
    "Feedback or Suggestions for Improvement",
    "Other Technical Issues"
];

export const urls =
    [
        'https://www.instagram.com/p/Cx00clqIrgE/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA%3D%3D',
        'https://twitter.com/historyinmemes/status/1720180772508704871',
        'https://twitter.com/historyinmemes/status/1720180772508704871',]

export const TestPosts: Post[] = [
    {
        "id": 2,
        "url": "https://www.instagram.com/p/CyYWP4bICMu",
        "text": "Allah apna reham ferma 🤲\n\n#Palestine #Innocentpeople #Palestinianpeople #Gazaunderattack #FreeGaza"
    },
    {
        "id": 3,
        "url": "https://www.instagram.com/p/CyWqz0KSIl8",
        "text": "Link in bio to donate⚠️Trigger warning….Calling this a war crime is not enough. Israel is committing a genocide and USA & Canada are complicit. \n\nThey turned Gaza into an extermination camp.\nSpeak up to our governments before there’s none of us left.\n\n#ethniccleansing #palestine #israel #freepalestine #freeisrael #yisrael #israël #canadastandswithisrael #justintrudeau #jagmeetsingh #biden #gaza #freegaza #prayforgaza #gazaunderattack"
    },
    {
        "id": 4,
        "url": "https://www.instagram.com/p/CyTaJSpsYe_",
        "text": "A farewell beneath the rubble!\nوداع تحت الأنقاض !\n\n#GazaUnderAttack #غزه_تحت_القصف"
    }, {
        "id": 67,
        "url": "https://twitter.com/1318537581185896448/status/1713697652137726298",
        "text": "BREAKING| Israeli occupation fighter jets have just killed a number of civil defense members after bombing a civil defense station west of Gaza. https://t.co/rlE75QZxXD"
    },
    {
        "id": 68,
        "url": "https://twitter.com/1267418873177034753/status/1714164918973333610",
        "text": "Heart-wrenching: Palestinian father, oblivious to his own wounds, tends to his son’s lifeless body slaughtered by Israeli occupation missiles. \n\n#GazaGenocide #gazaunderattack https://t.co/QdpkFA5Euv"
    },
    {
        "id": 69,
        "url": "https://twitter.com/918082298/status/1714065048652435630",
        "text": "my aunt sent me this video from occupied khalil. israeli occupation soldiers invaded her neighbor’s home at 2:30 in the morning, handcuffed and blindfolded him before abducting him. they just abducted another neighbor. https://t.co/MB2KxWS4Ub"
    },
    {
        "id": 70,
        "url": "https://twitter.com/1541424185402081281/status/1714168542243836018",
        "text": "🔥FIRST ATACMS ATTACK ???🔥\n\nOn the night of October 17, the Armed Forces of Ukraine carried out successful strikes against helicopters and securing airfields of the Russian occupation forces in the vicinity temporarily occupied Luhansk and Berdyansk\n\n❗️MANAGED TO CAUSE… https://t.co/SSZftZLhYs"
    },
    {
        "id": 6,
        "url": "https://www.instagram.com/p/CyRZDQnLEmD",
        "text": "الليل.. كلمة الرعب في #غزة !\nNight… a word of terror in #Gaza !\n\n#GazaUnderAttack #غزه_تحت_القصف"
    },
    {
        "id": 7,
        "url": "https://www.instagram.com/p/CyZSNHvhK1E",
        "text": "💔 Palestinian children writing their names and ID numbers on their hands to get identified after being bombed.\n.\n.\n.\n.\n.\n#تايبوغرافي #عبارة #مقولة #اقتباسات #اقتباس #خط  #تصميم  #فلسطين #غزة #غزة_تحت_القصف \n #palestine #posterdesign #poster #graphicdesigner #typeposter  #logodesigner #logo #arabictypography #illustrationdesign  #typography  #illustrator #photoshop #adobe  #arabicdesigner #digitalart #graphicdesign #illustrations #illustrationposter #palestine #gaza #gazaunderattack"
    }]
export const Allposts: Post[] = [
    {
        "id": 8,
        "url": "https://www.instagram.com/p/CyQwlbLsHi8",
        "text": "غزة.. حُكم بالإعدام !\n#Gaza is sentenced to death!\n#GazaUnderAttack #غزه_تحت_القصف"
    },
    //   {
    //       "id": 9,
    //       "url": "https://www.instagram.com/p/CyRLExQLNt9",
    //       "text": "🇵🇸🚨IT’S GENOCIDE🚨🇵🇸\n\n#freepalestine #palestine #gaza #palestinewillbefree #savepalestine #palestineunderattack #gazaunderattack #gazapalestine #gazastrip #gaza_under_attack #savegaza #france #usa #uk #brazil #mexico #india #saudiarabia"
    //   },
    //   {
    //       "id": 12,
    //       "url": "https://www.instagram.com/p/CyWNYf0gd18",
    //       "text": "This is the history of genocide you read about in textbooks. God is watching. Be on the right side of history.\n\n#palestine🇵🇸 #palestine #gazaunderattack #gaza #genocide"
    //   },
    {
        "id": 15,
        "url": "https://www.instagram.com/p/CyLRpsMseQ6",
        "text": "The occupation’s massacre of entire families and the bombing of homes over their inhabitants is an unceasing aggression in Gaza.\n\nمجازر الاحتلال بحق عائلات بأكملها وقصف البيوت فوق رؤوس ساكنيها عدوان لا يتوقف في غزة. \n#GazaUnderAttack #غزه_تحت_القصف"
    }, {
        "id": 67,
        "url": "https://twitter.com/1318537581185896448/status/1713697652137726298",
        "text": "BREAKING| Israeli occupation fighter jets have just killed a number of civil defense members after bombing a civil defense station west of Gaza. https://t.co/rlE75QZxXD"
    },
    // {
    //     "id": 68,
    //     "url": "https://twitter.com/1267418873177034753/status/1714164918973333610",
    //     "text": "Heart-wrenching: Palestinian father, oblivious to his own wounds, tends to his son’s lifeless body slaughtered by Israeli occupation missiles. \n\n#GazaGenocide #gazaunderattack https://t.co/QdpkFA5Euv"
    // },
    {
        "id": 69,
        "url": "https://twitter.com/918082298/status/1714065048652435630",
        "text": "my aunt sent me this video from occupied khalil. israeli occupation soldiers invaded her neighbor’s home at 2:30 in the morning, handcuffed and blindfolded him before abducting him. they just abducted another neighbor. https://t.co/MB2KxWS4Ub"
    },
    {
        "id": 70,
        "url": "https://twitter.com/1541424185402081281/status/1714168542243836018",
        "text": "🔥FIRST ATACMS ATTACK ???🔥\n\nOn the night of October 17, the Armed Forces of Ukraine carried out successful strikes against helicopters and securing airfields of the Russian occupation forces in the vicinity temporarily occupied Luhansk and Berdyansk\n\n❗️MANAGED TO CAUSE… https://t.co/SSZftZLhYs"
    },
    {
        "id": 16,
        "url": "https://www.instagram.com/p/CybOqsnNdpE",
        "text": "@T3Vakfi:\n\nEğitim, bilim ve teknolojiyle büyümesi gereken çocukların, bombalar altında insanlık dışı bir zulme maruz bırakılmasını şiddetle kınıyoruz!\n\nTürkiye Teknoloji Takımı Vakfı\n\n#GazaUnderAttack\n\nYüzyıllar boyunca insanların kardeşçe yaşadığı bir şehir, en temel insani ihtiyaçlardan yoksun bir açık hava hapishanesine dönüşmüş durumda. Masum insanların her gün bombardıman altında kaldığına ve tüm dünyanın gözü önünde insanlık suçu işlendiğine şahit oluyoruz!\n\nTürkiye Teknoloji Takımı Vakfı olarak bölgede bir an evvel barış ve huzurun temin edilmesi için çağrı yapıyoruz. Eğitim, bilim ve teknolojiyle büyümesi gereken çocukların, bombalar altında insanlık dışı bir zulme maruz bırakılmasını şiddetle kınıyoruz!"
    },
    {
        "id": 18,
        "url": "https://www.instagram.com/p/CyYvGrdqEtD",
        "text": "Duruşumuz Filistin’dir..\nDuruşumuz Gazze’dir..\n\nDuruşumuz zulme karşıdır..\nDuruşumuz iki yüzlü batıya karşıdır..\n\n#BüyükFilistinYürüyüşü\n#ÖzgürKudüs\n#FreeGaza"
    },
    {
        "id": 19,
        "url": "https://www.instagram.com/p/CyUFM1NAfo8",
        "text": "Another war crime confirmed. For days Palestinians have been reporting chemical reactions to the bombing. And now it’s confirmed Israel, with full US backing, is using chemical weapons on children all while preventing even water from Gaza. Despicable.\n\nRead more via link in bio. \n#palestine #palestine🇵🇸 #gazaunderattack"
    },
    {
        "id": 20,
        "url": "https://www.instagram.com/p/CyOKe2OMUP2",
        "text": "Al-Rimal Neighborhood... Israel turned it to ashes!\n\nحي الرمال.. أعاده الاحتلال رماداً !\n\n#GazaUnderAttack #غزه_تحت_القصف"
    },
    {
        "id": 21,
        "url": "https://www.instagram.com/p/CyThyRDIex0",
        "text": "قريبآ هيتقطع عن #الفلسطنين الانترنت محدش هيعرف عنهم اي حاجه تاني في نفس الوقت الطرف الأخر عنده كل حاجه وكل الميديا ومتحكمين في الميديا في العالم كله  Don’t stop talking about palastine \nهذا الحساب يدعم #القضية_الفلسطنية \n#فلسطين  #palastine  #palastine🇵🇸 \n#فلسطين_تحت_القصف_الإسرائيلي\n#dontstop_takling_about_palastine 🇵🇸"
    },
    {
        "id": 23,
        "url": "https://www.instagram.com/p/CyV1WCRoBG9",
        "text": "اللهم إنا نستودعك بيت المقدس وأهل #غزة 🤲🏼🇵🇸💔\nاللهم كن لأهل #فلسطين عوناً ونصيرًا ومؤيدًا وظهيرًا، وحافظًا وأمينًا…\nاللهم إنا لا نملك إلا الدعاء لهم يا ربّ لا ترد لنا دعاء وأنت أرحم الراحمين\n\n#istandwithpalestine"
    },
    {
        "id": 25,
        "url": "https://www.instagram.com/p/CyOf4UqrGQH",
        "text": "They escaped to safety… So they killed them \nفروا للنجاة.. فقتلهم !\n\n#GazaUnderAttack #غزه_تحت_القصف"
    },
    {
        "id": 26,
        "url": "https://www.instagram.com/p/Cyfd97_L6iz",
        "text": "The World is Silent, meanwhile, Israel is Committing War Crimes against the people of Gaza\n\n#AIMIM #AsaduddinOwaisi #Owaisi #Palestine #AlAqsa #Gaza #MasjideAqsa #IsraelGazaWar #GazaUnderAttack #gazagenocide"
    },
    {
        "id": 27,
        "url": "https://www.instagram.com/p/CyZGdaVLBIP",
        "text": "🇵🇸🚨IT’S GENOCIDE🚨🇵🇸\n\n#freepalestine #palestine #gaza #palestinewillbefree #savepalestine #palestineunderattack #gazaunderattack #gazapalestine #gazastrip #gaza_under_attack #savegaza #france #usa #uk #brazil #mexico #india #saudiarabia"
    },
    {
        "id": 28,
        "url": "https://www.instagram.com/p/CyZPutTs9Sh",
        "text": "You don't have to be a Muslim to support Palestine; you just have to be human! \n\n#freepalestine #غزة #gazaunderattack #gaza"
    },
    {
        "id": 31,
        "url": "https://www.instagram.com/p/CyRdbsIMGgT",
        "text": "This is the truth. Spread it. \n\n#freepalestine #gazaunderattack #freedom"
    },
    {
        "id": 32,
        "url": "https://www.instagram.com/p/CyUDmleND1L",
        "text": "May Allah (swt) protect our brothers and sisters ❤️‍🩹 \n\nDigital art by me 🤍\n\n.\n.\n.\n#islamicquotes #digitalart #palestine #palestine🇵🇸 #gaza #gazaunderattack #palestinians #quranquotes #quran #quranverses"
    },
    {
        "id": 35,
        "url": "https://www.instagram.com/p/CyO3xPHMT0d",
        "text": "“You don’t need to be a Muslim to stand up for Palestine, you just need to be a human” \n\nO Allah protect the people of Palestine \nO Allah ease their pain and suffering \nO Allah bestower of mercy ,bestow your mercy on them \nO Allah open people’s hearts to give in this time of crisis Amin 🙏 \nما يحدث في فلسطين لا يتحمله عقل بشري .. \nجرائم من ابشع ما يكون \nواستخدام اسلحة محرمة دوليا لقتل المدنين الاطفال والسيدات \nعدد حالات الضحايا تتعدي الالاف من اطفال لسيدات لرجال .. \n\"اللهم نصرك كما وعدت \" \n\"اللهم نصرك كما وعدت \" \n\"اللهم نصرك كما وعدت\" \nفلسطين قضيتنا وفي دمنا \n\"نحن اصحاب القضية \"\n#gazaunderattack \n#freepalestine \n#savepalestine \n#stopthewar 🔴\nفلسطين 🇵🇸"
    },
    {
        "id": 39,
        "url": "https://www.instagram.com/p/CyQ9G-Ltv88",
        "text": "Never choose silence!!!!!\n#gazaunderattack #gaza #standwithpalestine #palestine #freepalestine #landpalestine"
    },
    {
        "id": 41,
        "url": "https://www.instagram.com/p/CyOIpJeN1DK",
        "text": "Again and again!!!!\nWhat is happening in Palestine is not complicated; it's settler colonialism and ethnic cleansing. \nSwipe to learn about myths perpetuated by the mainstream media who has continuously worked to conceal Israel's ongoing genocide and ethnic cleansing of Palestine by saying it's a two-sided conflict, framing Palestinian resistance as terrorism and normalizing the state of Israel\n#standwithpalestine #gazaunderattack #gaza #palestne #jerusalem #freepalestine  #landpalestine"
    },
    {
        "id": 42,
        "url": "https://www.instagram.com/p/CyTIu_8NXSx",
        "text": "Come on, tell me who the oppressor is? Who is the oppressed? Who is the victim? Who is the perpetrator? \n#gazaunderattack #gaza #standwithpalestine #palestine #freepalestine #landpalestine"
    },
    {
        "id": 44,
        "url": "https://www.instagram.com/p/CyTpimwvRqI",
        "text": "नेपाल ने अपने 253 छात्रों को इज़राइल के तेल अवीव से एयरलिफ्ट किया। विदेश मंत्री एनपी सऊद ने विमान में सवार छात्रों का अभिनंदन किया। #Isarael #palastine #Nepal"
    },
    {
        "id": 45,
        "url": "https://www.instagram.com/p/Cybdla7tRfd",
        "text": "HAMAS = ISIS\n#hamasisisis #freegazafromhamas"
    },
    {
        "id": 46,
        "url": "https://www.instagram.com/p/CyTv6litXhp",
        "text": "#gazaunderattack"
    },
    {
        "id": 48,
        "url": "https://www.instagram.com/p/CyQ_5Ihs2Ay",
        "text": "#GazaSpeaksUp : Journalist Yafa Abu Akr, mother of 3 children - Gaza City.\n#غزة_تتحدث : الصحفية يافا أبو عكر أم ل3 أطفال - مدينة غزة\n\n#GazaUnderAttack #غزه_تحت_القصف"
    },
    {
        "id": 50,
        "url": "https://www.instagram.com/p/CyMkz_RLZWS",
        "text": "Ato em solidariedade ao povo palestino 🇵🇸\n\nTerça (10), Rio e São Paulo sediarão grandes atos em defesa da Palestina e contrários aos crimes contra a humanidade cometidos pelo Estado terrorista de Israel, cuja existência enquanto Estado teocrático e sionista já é uma afronta direta ao direito dos palestinos de existirem.\n\nA luta pelo fim do Estado de Israel é imediata, sendo a única possibilidade de haver paz entre os trabalhadores palestinos e israelenses.\n\nTodo apoio à Palestina 🇵🇸 \nFim do Estado de Israel 🇮🇱 \n\n#pco #hamas #palestinalivre #israelterrorista"
    },
    {
        "id": 51,
        "url": "https://www.instagram.com/p/CyWwu7GIWOv",
        "text": "This account supports the state of Palestine and does not recognize the Zionist entity\n\nهذا الحساب يدعم القضية الفلسطينية ولا يعترف بدولة إسرائيل.\n\n\"اللهُم إنّا لا نملك لهم إلا الدعاء فكن يارب عونًا لهم وانصرهم على القوم الظالمين ، اللّهُمّ إنا نستودعك غزة وأهلها .\" 🇵🇸\n\n#فلسطين #القدس\n#palestine #freepalestine \n#gazaunderattack"
    },
    {
        "id": 52,
        "url": "https://www.instagram.com/p/CyTbCCeOGL9",
        "text": "May Allah help and protect the people of Palestine 🤲🏻🇵🇸 #freepalestine \n\n#freedomforpalestine #prayforpalestine #palestinelivesmatter \n#westandwithpalestine #palestinewillbefree \n#savealaqsa #savepalestine \n#gazaunderattack \n#alaqsaunderattack #explore #exploremore #exploring #islam #islamic #islamicquotes #islamicreminders #islamicpost #allah #allahuakbar #allahﷻ #followme #followforfollowback"
    },
    {
        "id": 54,
        "url": "https://www.instagram.com/p/CyQgJkkM3FQ",
        "text": "نزوح تحت النار!\n Displacement under fire!\n\n#GazaUnderAttack #غزه_تحت_القصف"
    },
    {
        "id": 55,
        "url": "https://www.instagram.com/p/CyWEVX-IlYB",
        "text": "#Israel dropped 6,000 bombs on #Gaza Strip in six days, the same amount the U.S dropped on #Afghanistan in one year!\n\nAfghanistan is 1811 times as big as the Gaza Strip!\n\nFollow @doamuslims\nBackup Page @doamuslims2 \n#Islam #Ummah #Muslims #Palestine #Palestinians #palestine🇵🇸 #palestineforever #MasjidAlAqsa #AlAqsa #AlQuds #AlAqsaMosque #Muslim #Палестина #فلسطين #Hijab #Hijabi #Niqabi #Muslimah #hijabers #Quran #gazaunderattack"
    },
    {
        "id": 56,
        "url": "https://www.instagram.com/p/CyS0I6VyrpM",
        "text": "The Israeli Defense Minister earlier announced an abolition of all rules of war. This is a war crime.\n\n#freepalestine #freegaza"
    },
    {
        "id": 61,
        "url": "https://twitter.com/1066392792715399171/status/1713668428563656724",
        "text": "Father of Muhammad al-Durrah, whose son was executed in his lap by the lsraeli occupation forces 23 years ago, bids farewell today to a number of his family members who were killed in the lsraeli air attacks. https://t.co/n1v7zLSuGy"
    },
    {
        "id": 62,
        "url": "https://twitter.com/462312323/status/1714238063080009879",
        "text": "🎙️ Eric Cantona: “Defending Palestinian human rights does not mean you are pro-Hamas. Saying “Free Palestine” does not mean that we are anti-Semitic or that we want the disappearance of all Jews.\n\n“Free Palestine” means freeing Palestinians from the Israeli occupation that has… https://t.co/B4KJqzYqF0"
    },
    {
        "id": 63,
        "url": "https://twitter.com/1066392792715399171/status/1713925444934795696",
        "text": "Handcuffed and blindfolded, lsraeli occupation forces detain a number if Palestinians and torture them with loud music. https://t.co/hPB2LrevTp"
    },
    {
        "id": 64,
        "url": "https://twitter.com/1318537581185896448/status/1713843513043890202",
        "text": "Palestinian civil defense crews pull out a child's body from the rubble of his home after being bombed by Israeli occupation jets in Khan Younis city, #Gaza. https://t.co/ZTJReCtXpo"
    },
    {
        "id": 65,
        "url": "https://twitter.com/2268541258/status/1711836352470257738",
        "text": "Two years later, everything I said in this interview is still true.\nHamas has not achieved anything on behalf of the Palestinian people — it simply holds Palestinians hostage while promoting terror.\n#FreeGazaFromHamas https://t.co/9yXw2y0cYe"
    },
    {
        "id": 66,
        "url": "https://twitter.com/112233124/status/1713994257843802605",
        "text": "English version 🧵\n\nMaybe you don't know the story of the child in the picture, but for sure you have seen his picture before, another crime for the occupation that never ends against the Palestinian people. This time, the victim is a child born in 2002 in Jerusalem.. https://t.co/5K39VwjNdZ"
    },

    {
        "id": 71,
        "url": "https://twitter.com/20591678/status/1713528910694993924",
        "text": "Shai Davidai, Israeli Professor at Columbia university: they won’t allow a pro-ISIS rally or a pro-KKK rally, yet when when it comes to Israeli and Jewish lives, they allow a pro-Hamas rally.\n \n#HamasisISIS #FreeGazaFromHamas https://t.co/uHLkXgGVXh"
    },
    {
        "id": 72,
        "url": "https://twitter.com/1318537581185896448/status/1714235983967760880",
        "text": "The Israeli occupation forces have arrested Dr. Dalal Abu Amneh, the Palestinian artist and researcher in the field of brain sciences and neurophysiology.\n\nDalal was arrested due to a post she shared on her Facebook account, in which she expressed her condemnation of the ongoing… https://t.co/1UIB833THP"
    },
    {
        "id": 73,
        "url": "https://twitter.com/16314859/status/1713618353682604159",
        "text": "HAMAS = ISIS\n#HamasisISIS #FreeGazaFromHamas https://t.co/PnutywQrs9"
    },
    {
        "id": 74,
        "url": "https://twitter.com/1318537581185896448/status/1713891122810884280",
        "text": "Aerial photos show #GazaCity towns before the israeli occupation airstrikes on it and after! https://t.co/LAZ03GA4bc"
    },
    {
        "id": 75,
        "url": "https://twitter.com/848279118/status/1713699259621523603",
        "text": "WATCH: \n\nRemember this video?\n\nIt was in the year 2000, the Israeli Occupation Forces shot 12 year old Muhammad Al Durrah to death as his father (Jamal al-Durrah) tried to shield him.\n\nToday, Jamal lost 2 more of his children due to an Israeli airstrike on Gaza.\n\nThe first video… https://t.co/xYQmaBsOfN"
    },
    {
        "id": 76,
        "url": "https://twitter.com/110396781/status/1713888016622841928",
        "text": "This is what life is like for a Palestinian child living under Israeli occupation: https://t.co/YFXd1vuI1D"
    },
    {
        "id": 77,
        "url": "https://twitter.com/1564608943099355141/status/1713813893397508577",
        "text": "\"Hamas is a resistance (group) confronting 75 years of occupation and colonialism\"\n\nThe Israeli reporter was left speechless as a Canadian citizen educated him on certain historical facts and debunked some fake news. https://t.co/bnnC3q08xn"
    },
    {
        "id": 78,
        "url": "https://twitter.com/178095380/status/1713993666203660418",
        "text": "not settlement!\noccupation!\n#zionistterror https://t.co/lyHhK1sE4C"
    },
    {
        "id": 79,
        "url": "https://twitter.com/16314859/status/1713539705482912255",
        "text": "Enough said. \n\n#StandWithIsrael \n#FreeGazaFromHamas\n#HamasMassacre\n#HamasIsISIS\n@RepDeanPhillips https://t.co/vo32lCqK8z"
    },
    {
        "id": 80,
        "url": "https://twitter.com/16314859/status/1713545046098641087",
        "text": "...Just saying.\n\n#FreeGazaFromHamas\n#HamasIsISIS https://t.co/PcA3XsRNUU"
    },
    {
        "id": 81,
        "url": "https://twitter.com/2155425056/status/1713967502483661227",
        "text": "@ionebelarra BOLD statement from UK MP @jeremycorbyn :\n\n\"We will carry on demonstrating as long as it takes to stop the bombardment of Gaza, to end the occupation, and to bring about a just and lasting peace.\" https://t.co/PYupsuayEL"
    },
    {
        "id": 82,
        "url": "https://twitter.com/1964862775/status/1714230177532617135",
        "text": "Israeli journalist Gideon Levy at the Oxford Union several years ago: \n\n‘There is no Israeli-Palestinian conflict. There is a brutal Israeli occupation which must come to its end one way or the other’ https://t.co/L5YdDPIzWC"
    },
    {
        "id": 83,
        "url": "https://twitter.com/2268541258/status/1686093791680229376",
        "text": "In a rare public display of discontent with the Hamas regime, thousands of Gazans protested difficult living conditions and call for the removal of Hamas from power. Hamas security officers used force, including live fire, to disperse the protests.\n#FreeGazaFromHamas https://t.co/wVMl0zjoo0"
    },
    {
        "id": 84,
        "url": "https://twitter.com/16314859/status/1713958041425461482",
        "text": "Still think that #Hamas is fighting FOR the sake of #Palestinians? \nThink again. \n\n#FreeGazaFromHamas\n#HamasIsISIS https://t.co/wfmpyY34Aw"
    },
    {
        "id": 85,
        "url": "https://twitter.com/524869533/status/1714183488759353609",
        "text": "Media coverage: \"The bereaved mother of #Palestinian youth Nidal Melhem bids a final farewell to her son, who was murdered by Israeli occupation forces' gunfire this morning in the town of Halhul, south of the occupied West Bank.\" https://t.co/DcsAIbSW3l"
    },
    {
        "id": 86,
        "url": "https://twitter.com/580033481/status/1429158613378084870",
        "text": "An Israeli soldier is fighting for his life after being shot in the head from point blank range during a violent demonstration today in #Gaza.\n\nBut media reports insist on describing these incidents as \"protests\".\n\n#FreeGazaFromHamas\n\n#GazaUnderAttack #Hamas #Israel https://t.co/atyHGrwxXL"
    },
    {
        "id": 87,
        "url": "https://twitter.com/524869533/status/1714168740856651853",
        "text": "Media coverage: \"Israeli occupation forces raid a #Palestinian shop in occupied Jerusalem, force the vendor to open his phone to check whether there are posts supporting Gaza, subsequently arresting him.\" https://t.co/KwLq9dO9Ig"
    },
    {
        "id": 88,
        "url": "https://twitter.com/1392864463204782082/status/1714276406002438432",
        "text": "⚡️A new massacre carried out by the occupation forces on the Bureij refugee camp in the central Gaza Strip https://t.co/9BLGUjM06E"
    },
    {
        "id": 89,
        "url": "https://twitter.com/51913011/status/1714263330754273611",
        "text": "Fathi Hamad is the Hamas political chief and a member of the Palestinian Legislative Council. His goal is not to \"free Palestine.\" It is not to end the \"occupation.\" It is not \"national liberation.\"\n\nIt is to shed as much Jewish blood as possible. Period. https://t.co/4bVZt9YWvs"
    },
    {
        "id": 90,
        "url": "https://twitter.com/1318537581185896448/status/1714250529168073096",
        "text": "Israeli occupation warplanes bombed residential buildings in Rafa city, Gaza. https://t.co/iSbbcIlz9n"
    },
    {
        "id": 91,
        "url": "https://twitter.com/1121807798826930177/status/1714172789760954511",
        "text": "🇵🇸🇮🇱🚨‼️ Palestinian media reports; \"Israeli occupation forces raid a Palestinian shop in occupied Jerusalem, force the vendor to open his phone to check whether there are posts supporting Gaza, subsequently arresting him.\" https://t.co/iEYhuSoYxg"
    },
    {
        "id": 92,
        "url": "https://twitter.com/203348714/status/1712388348923503067",
        "text": "The footage from Gaza are heartbreaking, praying for all the innocents caught up in Hamas mess and for the war to be over! I know what’s like to be dragged to a war by corrupt gov. Hamas should surrender if they truly cared about the welfare of Palestinians. #FreeGazaFromHamas https://t.co/JRerUjvW4l"
    },
    {
        "id": 93,
        "url": "https://twitter.com/2258963714/status/1713981055135277285",
        "text": "🚨ISRAEL LIES AGAIN🚨\n\nThe Israeli occupation said it would allow some aid to enter Gaza. Then it broke its word and bombed the Egypt-Gaza Rafah border crossing once again.\n\nThis time they bombed the Egypt side of the border, but what will Egypt do about it? Nothing. #Palestine https://t.co/C5Qogh6rCT"
    },
    {
        "id": 94,
        "url": "https://twitter.com/1267097352847925248/status/1714196142123082078",
        "text": "هذه غزة .. يا أمة المليار\n#مجلس_الأمن #الأردن #غزة_تزحف_الى_القدس #غزة_تُباد #معبر_رفح #الإجتياح_البري \n#مع_غزة_المقاومة #غزة_الآن #غزه_تحت_القصف #غزه_تقاوم #إبن_اليهودية #حماس_منا_ونحن_منها #الصهاينة_العرب #FreePaleastine #FreeGazaFromHamas #PalestineLivesMatter #ZionistTerror https://t.co/JGy5vmxjXC"
    },
    {
        "id": 95,
        "url": "https://twitter.com/820411862026190848/status/1714208814495301993",
        "text": "A wide arrest campaign in the West Bank..\n\nHead of the Palestinian Parliament, Aziz Dweek and other 108 Palestinians arrested by the Israeli occupation forces. 25% of them are children &amp; elderly https://t.co/CW0vBX3Mc9"
    },
    {
        "id": 96,
        "url": "https://twitter.com/47316091/status/1713630607173963986",
        "text": "A wonderful dignified vigil of well over 500/600 people in Dublin for the victims of Hamas terror . Calls to free the kidnapped &amp; prayers for all the innocent people in the region.  #dublin #proIsrael #Hamas_is_ISIS #FreeGazaFromHamas #Ireland https://t.co/TnVZSyi14u"
    },
    {
        "id": 97,
        "url": "https://twitter.com/16314859/status/1713530821477359638",
        "text": "#FreeGazaFromHamas\n#HamasIsISIS https://t.co/y3TKf4z5KN"
    },
    {
        "id": 98,
        "url": "https://twitter.com/524869533/status/1713891150971424960",
        "text": "BREAKING: Israeli occupation soldiers open gunfire on a Palestinian vehicle near the village of Al-Hijra, south of Hebron in the occupied West Bank. https://t.co/a9TOMbH21q"
    },
    {
        "id": 99,
        "url": "https://twitter.com/2258963714/status/1714224832856936514",
        "text": "A Palestinian doctor busy treating children who were just bombed by the Israeli occupation suddenly realises that it's his own family and relatives whose homes have been bombed 💔 it's difficult to imagine what they must be going through #GazaGenocide 🇵🇸 https://t.co/61DqctfxCQ"
    },
    {
        "id": 100,
        "url": "https://twitter.com/1505982614875877386/status/1714035237280268368",
        "text": "This was Life in Palestine prior to the ILLEGAL Zionist Occupation \n\nLook how peaceful it was. https://t.co/GR3Zap7TF2"
    },
    {
        "id": 101,
        "url": "https://twitter.com/1012443708/status/1712457442749989356",
        "text": "Hamas is to blame\n\n#FreeGazaFromHamas \n\n https://t.co/SVFfHJtGGD"
    },
    {
        "id": 102,
        "url": "https://twitter.com/2373735295/status/1713907676856402403",
        "text": "While speaking on Sky News on Sunday, journalist Rachel Shabi urged people to consider the wider context behind the support of Hamas, highlighting how political attempts to end Israel's occupation of Palestine were “blocked by the international community.” https://t.co/5rrpJkdmFj"
    },
    {
        "id": 103,
        "url": "https://twitter.com/16314859/status/1713623275354362167",
        "text": "Terror group #Hamas prevents #Palestinians from fleeing Northern #Gaza. Here is why: #HamasisISIS #FreeGazaFromHamas #HamasMassacre https://t.co/Z8Z0TeDTep"
    },
    {
        "id": 104,
        "url": "https://twitter.com/2268541258/status/1711430106344734974",
        "text": "A Hamas rocket hit a mosque in Abu Ghosh.\nHamas continues to fire rockets indiscriminately at civilian areas. They don't care who they kill.\n#FreeGazaFromHamas https://t.co/PwkcF4uzLo"
    },
    {
        "id": 105,
        "url": "https://twitter.com/282670739/status/1673999259887190016",
        "text": "According to the United Nations, using children in a military conflict is a war crime.\n\nChildren are being forced to learn to kill in Hamas 'summer camps'  (funded by Iran) across Gaza.  There is no UN outrage.\n\nI hope your children enjoy the summer!\n\n#FreeGazaFromHamas https://t.co/pNzxNx12WQ"
    },
    {
        "id": 106,
        "url": "https://twitter.com/1443502889499045889/status/1712754253226246581",
        "text": "🚨🇮🇱🇵🇸 ALERTE : Les #Jordaniens ont franchi le cordon militaire et se dirigent vers la frontière avec #Israël.\n\n@BFMTV @LCI #Gazaouis #FreeGaza #FreeGazaFromHamas #Hamas \n#Gaza #Gaza_under_attack \n#palastine #Palestine #Israel_under_attack \n\n https://t.co/hbdgDPuav4"
    },
    {
        "id": 107,
        "url": "https://twitter.com/1012443708/status/1713197121933889976",
        "text": "This is how Hamas uses children.\n\nThey burn infants in their sleep and send children to the front line while their commanders are fattening themselves in luxury hotels in Qatar \n\nHamas is responsible for the whole situation \n\n#FreeGazaFromHamas https://t.co/wx9l4OjAow"
    },
    {
        "id": 108,
        "url": "https://twitter.com/2373174403/status/1714256277587587418",
        "text": "Hello Gaza. #FreeGazaFromHamas https://t.co/IXwW3DArUx"
    },
    {
        "id": 109,
        "url": "https://twitter.com/1247200900122398720/status/1712759865876091110",
        "text": "Huge crowd gather in Tahir Square in the capital, Bangladesh, in support of Palestine 🇵🇸 and in rejection of the occupations aggression against #Gaza \n\n#Israel  #FreeGazaFromHamas #LahoreProtest #GazaUnderAttack #IsraelTerrorists #Gaza  #Hamas\n#BoycottMcDonalds https://t.co/O82GtcUJq7"
    },
    {
        "id": 110,
        "url": "https://twitter.com/524869533/status/1714278696532767146",
        "text": "A merchant sends a message to the world from the Rimal neighborhood in Gaza: “We were born to resist the occupation. We are steadfast and will not migrate and leave our land”.  #Gaza #Palestine https://t.co/i09mz50TCT"
    },
    {
        "id": 111,
        "url": "https://twitter.com/441212043/status/1714282840698032472",
        "text": "A new massacre carried out by the occupation forces on the Bureij refugee camp in the central Gaza Strip"
    },
    {
        "id": 112,
        "url": "https://twitter.com/1298349652203524097/status/1713169600127414282",
        "text": "London now 🇵🇸🇵🇸\n\n#Gazagenocide #IsraelTerrorists #مقاطعة_ماكدونالدز #علامات_الظهور #غزة_الآن #غزه_تحت_القصف_القدس_تنتفض #طوفان_الاردن #فلسطين_تقاوم #فلسطين #فلسطين_المحتلة #فلسطين_حرة #FreeGaza #FreePalaetine #FreePalaestine #FreeGazaFromHamas #غزة_الآن #غزة_تُباد #فلسطين_حرة https://t.co/h2YYalaZj1"
    },
    {
        "id": 113,
        "url": "https://twitter.com/1092843402490798085/status/1714237921807540539",
        "text": "People of Israel,\nYour government has lied to you, and brought this bloodshed upon you themselves.\nWhere was your army?\nCall your corrupt leaders to end the occupation and corruption. https://t.co/UEXxtSeTdH"
    },
    {
        "id": 114,
        "url": "https://twitter.com/2348377081/status/1713919785698832550",
        "text": "The occupation forces have killed more than 1,030 Palestinian children since October 7.\n\nAt the rate of a child killed every 15 minutes 💔💔💔💔💔\n\nhttps://t.co/01qVLzs6Y4\n\n #OpenRafahCrossing\n#ادخال_المساعدات_لغزة"
    },
    {
        "id": 115,
        "url": "https://twitter.com/1320050125050421258/status/1714240478294487443",
        "text": "See how the #Israeli occupation changed the #Palestinian map since 1927. https://t.co/Pyh26J1c0y"
    },
    {
        "id": 116,
        "url": "https://twitter.com/16314859/status/1714020656986144945",
        "text": "If you care about #Palestinian civilians in #Gaza, you MUST wholly and unequivocally condemn #Hamas.\n\n#FreeGazaFromHamas\n#HamasIsISIS\n\nVia: @cogatonline https://t.co/n4TAEJZIKK"
    },
    {
        "id": 117,
        "url": "https://twitter.com/1647653785689288707/status/1713616200641479035",
        "text": "The facts\n\n#Every_Palestinian_is_oppressed #FreeGazaFromHamas #جاهزون_يا_فلسطين #غزة_تزحف_الى_القدس https://t.co/aEo7PHgoFQ"
    },
    {
        "id": 118,
        "url": "https://twitter.com/1264842314498756610/status/1712044294381461668",
        "text": "This video is from London a few years ago, \nA Muslim woman bravely defends a Jewish family.\n#PalestineUnderAttack #HamasMassacre #طوفان_الأقصى #غزة_الآن #Israel #Gaza #GazaUnderAttack #FreePalestine #FreeGaza #FreeGazaFromHamas https://t.co/LnzkTlfAAd"
    },
    {
        "id": 119,
        "url": "https://twitter.com/1464561877028114438/status/1713991649968181260",
        "text": "Final goodbye! \n\nA Palestinian dad hugging his baby after he got killed by Israeli occupation air strikes on Gaza https://t.co/XKUBguuOF0"
    },
    {
        "id": 120,
        "url": "https://twitter.com/1600030509664460802/status/1713249493808828457",
        "text": "Are you sure that you really want to stand with Palestine????? Maybe this video will change your mind.\n#WeStandWithIsrael #Hamas_is_ISIS #hamasiISIS #Hamas #FreeGazaFromHamas #FreePalastinefromhamas #FreePalaestine 🇮🇱🇺🇸🇮🇱🇺🇸🇮🇱🇺🇸🇮🇱🇺🇸🇮🇱 https://t.co/SVXMPpEz06"
    },
    {
        "id": 121,
        "url": "https://twitter.com/1972226910/status/1714190714244833786",
        "text": "Everywhere there are martyrs, the occupation's involvement in violence has led to the death of over 1,200 children in just 11 days. Is this the humanity we should discuss? Can children be labeled as terrorists? What justifies their suffering? #GazaAttack https://t.co/FVTaWqEkeu"
    },
    {
        "id": 122,
        "url": "https://twitter.com/172425386/status/1714280348811751719",
        "text": "With the attendance of diplomatic community, Fuzuli residents are rejoicing Fuzuli City Day - 17 October. On this particular day, in 2020, Fuzuli was liberated from 30 years long military occupation of Armenia. Former IDPs and refugees are returning to newly built apartments, and… https://t.co/nbsadRmXGn"
    },
    {
        "id": 123,
        "url": "https://twitter.com/1247200900122398720/status/1712735925900357780",
        "text": "Short Film 🇵🇸\nThe image is clear,\njust needs to be seen.\n\n#FreeGaza\n#Israel  #FreeGazaFromHamas #IsraelPalestineConflict #طوفان_الأقصى #طوفان_الاقصى_ #فلسطين #غزه_تباد  #GazaUnderAttack #IsraelTerrorists #Gaza #IsraelFightsBack #Hamas https://t.co/gM2ATs8II9"
    },
    {
        "id": 124,
        "url": "https://twitter.com/1414620597745766405/status/1714034869376889201",
        "text": "This little infant is now parentless after Israeli occupation warplanes targeted and bombed her family's house in Gaza.\n\nThe fate of her parents isn't known yet. They're maybe buried under the rubbles! https://t.co/bW6oZCYxUh"
    },
    {
        "id": 125,
        "url": "https://twitter.com/1683708205086674946/status/1713227552763085219",
        "text": "And 700 Palestinian children killed don't outrage you anymore, do they?\n\n#palastine #Palestine #FreeGaza #FreeGazaFromHamas #Israel #IsraeliWarCrimes #IsraelTerrorists https://t.co/CFJwCDqtZZ"
    },
    {
        "id": 126,
        "url": "https://twitter.com/941705260846010368/status/1712457144887341193",
        "text": "Hamas is a terror organization.\nThey do not care about the life of Israelis nor the life of its own people, the Palestinians.\n\nThey only care about preserving their power, no matter the cost. \n\n#HamasisISIS #FreeGazaFromHamas https://t.co/B4pfnyIyEM"
    },
    {
        "id": 127,
        "url": "https://twitter.com/3427754325/status/1712856816256696474",
        "text": "You want to #FreePalestine? Then #FreeGazaFromHamas https://t.co/PC4PADRAgp"
    },
    {
        "id": 128,
        "url": "https://twitter.com/4927593075/status/1714000614902923473",
        "text": "Humanitarian aid...\nThey live to kill!\n#hamasterrorists #HamasisISIS #freegazafromhamas #standwithisrael #letidfwin https://t.co/xlk8OC9A5K"
    },
    {
        "id": 129,
        "url": "https://twitter.com/18475861/status/1711838127424237996",
        "text": "Which side are you on? Humanity Or Hamas\n\n#Israel_under_attack\n#Israel #Gaza\n#HamasisISIS\n#HamasMassacre\n#FreeGazaFromHamas https://t.co/x5imQnzhSg"
    },
    {
        "id": 130,
        "url": "https://twitter.com/1261187841327054850/status/1714248013592313881",
        "text": "See how the isr*eli occupation changed the Palestinian map since 1927. https://t.co/CJ5SOz7jmd"
    },
    {
        "id": 131,
        "url": "https://twitter.com/1383911266658385924/status/1713471438336737525",
        "text": "➖SPEAK FOR THE :\n\n➖TRUTH' \n➖JUSTICE \n➖HUMANITY \n\n➖SPEAK up FOR :- #FreePalastine \n\n#FreeGazaFromHamas\n\n#IsraeliWarCrimes #KSIFury\n#IsrealPalestineconflict #Palestine\n#Gaza_under_attack #Israel\n#IsraeliWarCrimes #fixed\n #gazza #Gazagenocideشاركونا\n#อิสราเอลฮามาส #insiders… https://t.co/L5o1UYijHz"
    },
    {
        "id": 132,
        "url": "https://twitter.com/1683708205086674946/status/1714141592414785688",
        "text": "This occupation never was and NEVER will be about Jew vs. Muslim. REAL Jews are ANTI-ZIONISTS, because they KNOW that ZIONISM is AGAINST JUDAISM.   \n#IsraelGazaWar #GazaAttack #GazaCity #zionistterror #IsraeliNewNazism\n#PalestineGenocide #palastine #FreePalestine #FreeGaza… https://t.co/tkF6y6rosl"
    },
    {
        "id": 133,
        "url": "https://twitter.com/464853072/status/1713053930639294582",
        "text": "No one should justify the horrors executed by Hamas. \n\nHamas is the enemy of the people of Gaza.\n\n#FreeGazaFromHamas \n#Hamas_is_ISIS \n#HamasislSIS \n#TrueCrime \n#Gazagenocide \n\nhttps://t.co/JKMOxn07Nc"
    },
    {
        "id": 134,
        "url": "https://twitter.com/220776068/status/1714151855607959965",
        "text": "Iranian Foreign Minister Abdollahian:\n\n “The expansion of the war only to the resistance countries will create a situation that will change the geographical map of the occupation regime of Jerusalem.\n The Americans cannot constantly send the signal that they do not want the war… https://t.co/91lWhxRgEO"
    },
    {
        "id": 135,
        "url": "https://twitter.com/219433787/status/1714033607365292367",
        "text": "Gaza Strip workers, from inside the National Security Forces camp in Jericho.\n.\nAfter the occupation tore up their work permits and threw them into the West Bank https://t.co/LiDkUmyw9x"
    },
    {
        "id": 136,
        "url": "https://twitter.com/46856983/status/1713821337544724922",
        "text": "Bags of bullets instead of rice: Israel's SWAT unit, which eliminated 200 Hamas terrorists, discovered ammo in humanitarian aid. \nThe world must know that #Hamas is using their donations to smuggle ammo.\n#HamasIsISIS #FreeGazaFromHamas #Gaza #TheRealImage\n https://t.co/AN5cxYxc38"
    },
    {
        "id": 137,
        "url": "https://twitter.com/17874999/status/1713436656458498220",
        "text": "18 Israeli children have been kidnapped by Hamas.\n\nIf you want the suffering in Gaza to stop - give us back our children.\n\n #FreeGazaFromHamas\n#Hamas_is_ISIS \n\n https://t.co/ZoZpNd0SwB"
    },
    {
        "id": 138,
        "url": "https://twitter.com/1686222213513412608/status/1712011648662114513",
        "text": "Stand with us on the right side of history!\n\n#HamasisISIS\n#NeverAgainNow\n#FreeGazaFromHamas\n\n https://t.co/MihBW059P1"
    },
    {
        "id": 139,
        "url": "https://twitter.com/18475861/status/1711848135419371534",
        "text": "Hamas terrorists took over a residential home with a family and children and live-streamed it on Facebook \n\n#Gaza_under_attack \n#Gazastreifen \n#GazaTerror \n#Israel_under_attack\n#Israel #Gaza\n#HamasisISIS\n#HamasMassacre\n#FreeGazaFromHamas https://t.co/BpeKQPCnTz"
    },
    {
        "id": 140,
        "url": "https://twitter.com/352660013/status/1714230136587841975",
        "text": "A young man with special needs was injured in the Israeli occupation's shelling of the Gaza Strip. https://t.co/6zyRHfST0c"
    },
    {
        "id": 141,
        "url": "https://twitter.com/1647653785689288707/status/1713069212443259126",
        "text": "The Israelis' lies are exposed in a short video that does not exceed 1:13 minutes\n\n#Every_Palestinian_is_oppressed #Gazagenocide #FreeGazaFromHamas #اطفال_غزة_تحت_القصف_الاسرائيلي #طوفان_الاقصى #طوفان_الاردن https://t.co/HBPtd0e32M"
    },
    {
        "id": 142,
        "url": "https://twitter.com/282670739/status/1674697428853719042",
        "text": "Within Gaza, Palestinian terrorists kill Gazan civilians everyday. There is no acknowledgement or apology to the families of those murdered.\n\nThis is the real Gaza, Palestinians do not want the world to see.\n\nStop killing Gazans.\n\n#FreeGazaFromHamas https://t.co/ZhlvVk4Kfs"
    },
    {
        "id": 143,
        "url": "https://twitter.com/1535268491741339651/status/1712843510779429327",
        "text": "Malaysia stands in solidarity with Palestine 🇵🇸\n\n#FreePalestine #جمعة_طوفان_الأقصى #FreeGaza #FreeGazaFromHamas https://t.co/ybDJMhl6ij"
    },
    {
        "id": 144,
        "url": "https://twitter.com/1325872531279056897/status/1712883465555742814",
        "text": "@SharonaMazalian @DouglasKMurray You don't have to agree with every Israeli policy to condemn the massacre of innocents. No political stance justifies violence against children, women, and men. #StandWithIsrael #FreeGazaFromHamas https://t.co/PHDCe8OHE9"
    },
    {
        "id": 145,
        "url": "https://twitter.com/1267097352847925248/status/1714191141111771296",
        "text": "#ألمانيا  تعلن دعمها للكيان الصهيوني وتقمع الاحتجاجات المتضامنة مع فلسطين في معظم أنحاء البلاد \n#مع_غزة_المقاومة #غزة_الآن #غزه_تحت_القصف #غزه_تقاوم \n#إبن_اليهودية #حماس_منا_ونحن_منها #الصهاينة_العرب #FreePaleastine #FreeGazaFromHamas #PalestineLivesMatter #Israel #ZionistTerror https://t.co/GLXPdC8erz"
    },
    {
        "id": 146,
        "url": "https://twitter.com/4604479876/status/1714248353712624123",
        "text": "𝗜𝗦𝗥𝗔𝗘𝗟 𝗖𝗟𝗔𝗜𝗠𝗦 𝗙𝗔𝗞𝗘 𝗗𝗘𝗔𝗗 𝗕𝗔𝗕𝗜𝗘\n‼️𝗣𝗔𝗟𝗘𝗦𝗧𝗜𝗡𝗘 𝗜𝗦 𝗦𝗛𝗢𝗪𝗜𝗡𝗚 𝗬𝗢𝗨 𝗧𝗛𝗜𝗘𝗥 𝗠𝗨𝗥𝗗𝗘𝗥𝗘𝗗 𝗕𝗔𝗕𝗜𝗘𝗦‼️\n\nRescuing newborn babies from under the rubble as a result of the devastating Israeli occupation force bombing in the #Gaza Strip https://t.co/I7UACeRbGr"
    },
    {
        "id": 147,
        "url": "https://twitter.com/1320050125050421258/status/1714276544854798522",
        "text": "It's all about humanity.\n\nA number of Jews show their opposition to the Israeli occupation. #FreePalestine https://t.co/P1efBDfXvM"
    },
    {
        "id": 148,
        "url": "https://twitter.com/361833425/status/1712413694469013933",
        "text": "If you support the #Palestinian people you should be the first to condemn #Hamas!\nDo you belive that most Palestinians would behead babies? Shoot old women in the head? Burn people alive just for fun?????\nI don't.\n#freeGazafromHamas\n#HamasWarCrimes\n#HamasMassacre https://t.co/e1if7kJiRk"
    },
    {
        "id": 149,
        "url": "https://twitter.com/1461290481980162060/status/1712514454154346617",
        "text": "When a dog (gorgeous, innocent, brave, loyal dog) is more human then hamas man\n#HamasMassacre \n#HamasWarCrimes \n#FreeGazaFromHamas https://t.co/uRMpp69drc"
    },
    {
        "id": 150,
        "url": "https://twitter.com/219433787/status/1714262508041634070",
        "text": "The occupation targets the outskirts of the town of Al-Bustan, south of Lebanon, with artillery shells. https://t.co/CRVcfSKOM5"
    },
    {
        "id": 151,
        "url": "https://twitter.com/1438873890747392007/status/1712512198634013162",
        "text": "Heart-wrenching images depicting one of the tragedies in Gaza.\n\nSTOP THE WAR 🚨\n#GhazaUnderAttack #IsraelPalestineConflict #Gaza  #OperationAjay #Istandwithisreal #palastine #FreeGazaFromHamas #Isarael #IsraelFightsBack\n#IsraelTerrorists \nFrom: @Sahilsa29 https://t.co/IKw6UAWFSl"
    },
    {
        "id": 152,
        "url": "https://twitter.com/220347418/status/1713671237086359937",
        "text": "All you pro Palestinian supporters who have been orgasmic over the past week over #Hamas ‘resistance’ listen to Abu for the truth about your heroes. #HamasMassacre #FreeGazaFromHamas #StandWithIsrael"
    },
    {
        "id": 153,
        "url": "https://twitter.com/2875388958/status/1712694839148441905",
        "text": "Breaking: Egypt has refused to admit a single refugee from Gaza just like every other Arab nation and Iran. \n\nIsreal Friday the 13th Gaza and Lebanon Argentina Hamas \n#IsraelFightsBack \n#Hamas_is_ISIS #FreeGazaFromHamas \n#FreeGaza https://t.co/UsNNcmKcdQ"
    },
    {
        "id": 154,
        "url": "https://twitter.com/361833425/status/1712779798462832668",
        "text": "There are over 100 Israeli hostages in #Gaza, among them 12 children kept in the hands of barbaric terrorists.\n#FreeOurKids \n#freeGazaFromHamas\n#HamasMassacre https://t.co/8Cd4zsUap1"
    },
    {
        "id": 155,
        "url": "https://twitter.com/1612042716732424194/status/1713812562830045213",
        "text": "This picture is of a Palestinian woman named Israa Jaabis, a prisoner in the Zionists occupation prisons.\n\nShe is the mother of a child named Moatasem.\n\nEsraa was lovely happy Plastenien woman she was just moved on a new home with her child and her husband She bough gaz tupe &amp; tv… https://t.co/kDsFwUwyhS"
    },
    {
        "id": 156,
        "url": "https://twitter.com/1202541676096565248/status/1618963335751569414",
        "text": "Yet again, we see Hamas, PIJ, et al. calling for the death of Jews. This war cry is not a call for Jews to pay the “Jizya” tax...Khaybar Khaybar ya Yahud is contextually an antisemitic war cry. #FreeGazaFromHamas https://t.co/K2eghiHTaR"
    },
    {
        "id": 157,
        "url": "https://twitter.com/3274810681/status/1714277029468848315",
        "text": "और ये ऑंखे वो मंजर भी देखेगी जिस दिन \nमस्जिद - ए - अकसा आजाद होगी ....\n\nइंशाअल्लाह 🕌\n\n#FreePalpatine\n#FreeGazaFromHamas \n#GazaCity \n#IStandWithPalestine https://t.co/kmeVvhezct"
    },
    {
        "id": 158,
        "url": "https://twitter.com/220347418/status/1711404357764411696",
        "text": "There is poverty in Gaza - that’s true. But there is also great wealth. #TheGazaYouDontSee #FreeGazaFromHamas #StandWithIsrael"
    },
    {
        "id": 159,
        "url": "https://twitter.com/1598641473687257088/status/1714059432915513681",
        "text": "A soldier from the criminal Israeli occupation army rapes a 15-year-old Palestinian girl (the girl \ncommitted suicide)💔\n\n#zionistterror\n#GazaAttack\n#GazzeUnderAttack\n#غزة_الآن #غزه_تباد \n#طوفان_الأقصى #Isarael #palastine\n#PalestineGenocide\n#GazaCity\n#GazaAttack\n#IsraeliNewNazism https://t.co/CZ3flT9euu"
    },
    {
        "id": 160,
        "url": "https://twitter.com/243785567/status/1714280864027492588",
        "text": "Palestinian searching for his family under the rubble after their home was destroyed by the Israeli occupation's aircraft\n\n#IsraeliNewNazism\n#Gaza_under_attack \n#Israel #غزة_الآن\n#OpenRafahCrossing\n#IsraelPalestineConflict https://t.co/UpC0qI7MmF"
    }
]

export const TrendingPost: Post[] =
    [{
        "id": 1,
        "url": "https://www.instagram.com/p/CyRNEfQM2RQ",
        "text": "May Allah grant Gazans the patience and reward them with the highest places in Janna 🤲🏻💔 \n\n#gazaunderattack #freepalestine #freedom #resistance"
    }]

