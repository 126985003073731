import { useEffect, useReducer } from 'react';
import { initializePosts } from '../../../utils/postFetching';
import { Post, dataSource } from '../../../utils/interfaces';
import { Allposts, BOOKMARK, SENT, createQueryForAnswered, createQueryForBookmarked } from '../../../utils/constant';
import { FilterPosition } from '../Feed';
import FilterAndSortPosts from '../FilterAndSortPosts';

interface PostState {
    allPosts: Post[];
    posts: Post[];
    isLoading: boolean;
    hasMore: boolean;
}

type PostAction =
    | { type: 'FETCH_INIT' }
    | { type: 'FETCH_SUCCESS'; allPosts: Post[] }
    | { type: 'UPDATE_POSTS'; posts: Post[]; hasMore: boolean }
    | { type: 'FETCH_FAIL' };

const initialState: PostState = { allPosts: [], posts: [], isLoading: true, hasMore: true };

const actions = {
    FETCH_INIT: 'FETCH_INIT',
    FETCH_SUCCESS: 'FETCH_SUCCESS',
    UPDATE_POSTS: 'UPDATE_POSTS',
    FETCH_FAIL: 'FETCH_FAIL',
} as const;

function reducer(state: PostState, action: PostAction): PostState {
    switch (action.type) {
        case 'FETCH_INIT':
            return { ...state, isLoading: true };
        case 'FETCH_SUCCESS':
            return { ...state, isLoading: false, allPosts: action.allPosts, hasMore: action.allPosts.length > 0 };
        case 'UPDATE_POSTS':
            return { ...state, posts: action.posts, hasMore: action.hasMore };
        case 'FETCH_FAIL':
            return { ...state, isLoading: false };
        default:
            return state;
    }
}

const useFetchPosts = (
    dataSource: dataSource,
    pageSize: number,
    page: number,
    sortCriteria: string,
    filterPlatform: string,
    filterPosition: FilterPosition,
    userMail: string | undefined,
    alignment: string | undefined
) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        dispatch({ type: 'FETCH_INIT' });
        const fetchPosts = async () => {
            try {
                let query = "";
                let isDefault = true;
                if (alignment === BOOKMARK) { query = createQueryForBookmarked(userMail ?? ""); isDefault = false; }
                if (alignment === SENT) { query = createQueryForAnswered(userMail ?? ""); isDefault = false; }
                let fetchedPosts = []
                if(dataSource.dataBaseUrlParam.query)
                {
                    fetchedPosts = await initializePosts(dataSource.dataBaseUrlParam, userMail, query, false);
                }
                else{
                    fetchedPosts = await initializePosts(dataSource.dataBaseUrlParam, userMail, query, true);
                }
                dispatch({ type: 'FETCH_SUCCESS', allPosts: fetchedPosts });
            } catch (error) {
                dispatch({ type: 'FETCH_FAIL' });
                console.error(error);
            }
        };

        fetchPosts();
    }, [alignment, userMail]);

    useEffect(() => {
        // Apply filtering and sorting whenever relevant parameters or the allPosts array changes
        const filteredAndSortedPosts = FilterAndSortPosts(state.allPosts, sortCriteria, filterPlatform, filterPosition);
        const paginatedPosts = filteredAndSortedPosts.slice(0, page * pageSize);

        dispatch({
            type: 'UPDATE_POSTS',
            posts: paginatedPosts,
            hasMore: filteredAndSortedPosts.length > page * pageSize,
        });
    }, [state.allPosts, page, pageSize, sortCriteria, filterPlatform, filterPosition]);

    return { posts: Allposts.slice(15) , isLoading: state.isLoading, hasMore: state.hasMore };

    // return { posts: state.posts, isLoading: state.isLoading, hasMore: state.hasMore };
};

export default useFetchPosts;
