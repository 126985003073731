import { LanguageCodes } from "./constant";
import { DetectLanguageApiResponse, DetectResponse, RequestArgumentParamenters, TranslationResponse } from "./interfaces";

export async function generateCounterArgument(requestParam : RequestArgumentParamenters): Promise<string> {
  try {
    console.log(requestParam)
    const response = await fetch('https://rhetoric-ai-api.azurewebsites.net/api/generate_counterargument?code=rdWACHWET5ylnIDa6KyTqib1UIhLyz7c6IFi-ZDsrbdRAzFucqugwg==', {

    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({

      "post-text": requestParam["post-text"],
      "response-length": requestParam["response-length"],
      "response-audience": requestParam["response-audience"],
      "response-is-pro-israel":requestParam["response-is-pro-israel"],
      "response-tone":requestParam["response-tone"],
      "response-formality":requestParam["response-formality"],
      "platform-name": requestParam["platform-name"],
      "post-description": requestParam["post-description"]
    
    })
  });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    if (response.status === 400) {
      return "This post is unavailable. Try another!.";
    }
    const data = await response.json();
    console.log(data)

      const textResponse: string = data['response-text'] ? data['response-text'] : "No response text.";

    console.log(textResponse);
    return textResponse;  
  } catch (error) {
    console.error('Error:', error);
    return 'This post is unavailable. Try another! ';
  }
}



// -------------THOSE FUNCTION CODES ARE OUTDATED!
export async function generateDebtoreCounterArgument(post_text: string, length: number, audience: number): Promise<string> {
  try {
    if (length == 5) length = 4;
    const response = await fetch('https://rhetoric-ai-api.azurewebsites.net/api/generate_counterargument?code=6oBe9a8IbNEL5QhJmroeSGkb3VNmKTJE316AV7gK_bbrAzFuzvFfPw==', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "counterargument-length": length,
        audience: audience,
        "post-text": post_text
      })
    });

    const data = await response.json();
    const textResponse: string = data['counterargument-text'] ? data['counterargument-text'] : "No response text.";
    return textResponse;
  } catch (error) {
    console.error('Error:', error);
    return 'Error: ';
  }
}

export async function generateTranslationToEnglish(post_text: string){
  let textResponse: TranslationResponse = {translation_text:'not found', confidence:'not found'}
  
  try {
    const response = await fetch('https://rhetoric-ai-api.azurewebsites.net/api/translate_to_english?code=4Bdc8lsn8DXLxgbOYIWltBnSWdLA4cUI5TUahHAVdgQEAzFuP27oxw==', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "post-text": post_text
      })
    });

    const data = await response.json();
    if(data['translation-text']){
      textResponse = {translation_text: data['translation-text'], confidence: data['confidence']}
    }
    
  } catch (error) {
    console.error('Error:', error);
    return textResponse
  }
  return textResponse
}

export async function generateTranslationFromEnglish(post_text: string, target_translate:string) {
  console.log(post_text + "  ||| "+ target_translate)
  let textResponse: TranslationResponse = {translation_text:'not found', confidence:'not found'}
  try {
    const response = await fetch('https://rhetoric-ai-api.azurewebsites.net/api/translate_from_english?code=9dNYbRAjyGEezy3UvzIK3ovn3aUVjsKPwodB98MXTv-6AzFuzE4MVQ==', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "post-text": post_text,
        "target-language": target_translate
      })
    });

    const data = await response.json();
    if(data['translation-text']){
      textResponse = {translation_text: data['translation-text'], confidence: data['confidence']}
    }
    console.log(data)
    return textResponse;
  } catch (error) {
    console.error('Error:', error);
    
    return textResponse;
  }
}

export async function detectLanguage(post_text: string){
  let textResponse: DetectResponse = {language:'not found', confidence:'not found'}
  try {
    const response = await fetch('https://rhetoric-ai-api.azurewebsites.net/api/detect_language?code=fqILdtf0eJDHpk_9d-7vVm5_i2I8RzRJzgzp5VMR6WnDAzFuhuftnw==', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "post-text": post_text
      })
    });


    const data: DetectLanguageApiResponse = await response.json();
    
    const languageCode = data['language-code'];

    if (languageCode && LanguageCodes.hasOwnProperty(languageCode)) {
      const languageName = LanguageCodes[languageCode];
      textResponse = { language: languageName, confidence: data.confidence || 'not found' };
    }
    
    return textResponse;

  } catch (error) {
    console.error('Error:', error);
    return textResponse;
  }
  return textResponse;
}