import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Stack,
  Divider,
  CircularProgress,
  Paper,
} from "@mui/material";
import { generateLatestTree } from "../EndpointService/ResponseService";
import {
  campaignAnswerStyles,
  treeCommentTableStyles,
  customConversationStyles,
} from "./CampaignStyles";
import SliderControls from "./CampaignSliderControls";
import { convertSliderValue } from "../EndpointService/CampaignUtils";
import { LOADING_MESSAGE } from "../CampaignConst";
import { timerStyles } from "./CampaignStyles";

interface CustomConversationProps {
  apiKey: string;
}

interface Message {
  content: string;
  stance: "PRO" | "ANTI";
}

const CustomConversation: React.FC<CustomConversationProps> = ({ apiKey }) => {
  const [topic, setTopic] = useState("");
  const [conversation, setConversation] = useState<Message[]>([]);
  const [stance, setStance] = useState<"PRO" | "ANTI">("PRO");
  const [reasoningType, setReasoningType] = useState<
    "devils_advocate" | "iterative_drafting" | "plan_and_execute"
  >("devils_advocate");
  const [sliderValue, setSliderValue] = useState({
    toneValue: 2,
    lengthValue: 3,
    languageStyleValue: 2,
    communicationToneValue: 1,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const [useMonteCarloTreeOfThought, setUseMonteCarloTreeOfThought] =
    useState(false);

  const resetConversation = () => {
    setTopic("");
    setConversation([]);
    setStance("PRO");
    setReasoningType("devils_advocate");
    setSliderValue({
      toneValue: 2,
      lengthValue: 3,
      languageStyleValue: 2,
      communicationToneValue: 1,
    });
    setUseMonteCarloTreeOfThought(true);
  };

  const handleStanceChange = (
    event: React.MouseEvent<HTMLElement>,
    newStance: "PRO" | "ANTI"
  ) => {
    if (newStance !== null) {
      setStance(newStance);
    }
  };

  const handleReasoningTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newReasoningType:
      | "devils_advocate"
      | "iterative_drafting"
      | "plan_and_execute"
  ) => {
    if (newReasoningType !== null) {
      setReasoningType(newReasoningType);
    }
  };

  const handleMonteCarloToggle = (
    event: React.MouseEvent<HTMLElement>,
    newValue: boolean
  ) => {
    if (newValue !== null) {
      setUseMonteCarloTreeOfThought(newValue);
    }
  };

  const handleGenerateResponse = async (resend: boolean = false) => {
    setIsLoading(true);

    try {
      const responseParameters = convertSliderValue(sliderValue);
      const response = await generateLatestTree(
        {
          userId: "",
          campaignId: "",
          agentId: "",
          threadId: "",
          provided_thread: {
            threadId: topic,
            messages: resend
              ? conversation.slice(0, -1).map((msg) => ({
                  role: "user",
                  name: msg.stance,
                  content: msg.content,
                }))
              : conversation.map((msg) => ({
                  role: "user",
                  name: msg.stance,
                  content: msg.content,
                })),
          },
          responseParameters,
          apiKey,
          stance,
          reasoningType,
        },
        useMonteCarloTreeOfThought
      );
      console.log("response", response);
      setStance((prev) => (prev === "PRO" ? "ANTI" : "PRO"));
      if (resend) {
        setConversation([
          ...conversation.slice(0, -1),
          { content: response, stance },
        ]);
      } else {
        setConversation([...conversation, { content: response, stance }]);
      }
    } catch (error: any) {
      console.error(error);
      alert(error.message || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitTopic = async () => {
    if (!topic.trim()) return;
    setIsLoading(true);
    setTimer(0);
    const startTime = Date.now();
    const timerInterval = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      setTimer(Math.floor(elapsedTime / 10) / 100);
    }, 10);

    try {
      const responseParameters = convertSliderValue(sliderValue);
      const response = await generateLatestTree(
        {
          userId: "",
          campaignId: "",
          agentId: "",
          threadId: "",
          provided_thread: {
            threadId: topic,
            messages: [
              {
                role: "user",
                name: stance,
                content: topic,
              },
            ],
          },
          responseParameters,
          apiKey,
          stance,
          reasoningType,
        },
        useMonteCarloTreeOfThought
      );

      setConversation([{ content: response, stance: stance }]);
    } catch (error: any) {
      console.error(error);
      alert(error.message || "An error occurred");
    } finally {
      setIsLoading(false);
      clearInterval(timerInterval);
    }
  };

  return (
    <Card sx={customConversationStyles.card}>
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="h6" color="primary">
            Custom Conversation
          </Typography>
          <TextField
            label="Topic"
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
            fullWidth
            disabled={conversation.length > 0 || isLoading}
            helperText="Enter the topic to start the conversation."
            sx={customConversationStyles.textField}
          />
          <Divider />
          {conversation.length > 0 && (
            <Paper
              elevation={3}
              sx={customConversationStyles.conversationContainer}
            >
              {conversation.map((message, index) => (
                <Box
                  key={index}
                  sx={{
                    ...treeCommentTableStyles.commentBox,
                    borderLeft: `3px solid ${
                      message.stance === "PRO" ? "green" : "red"
                    }`,
                    marginBottom: 1,
                    backgroundColor:
                      message.stance === "PRO" ? "#e8f5e9" : "#ffebee",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      ...treeCommentTableStyles.authorName,
                      color: message.stance === "PRO" ? "green" : "red",
                    }}
                  >
                    {message.stance}:
                  </Typography>
                  <Typography variant="body1">{message.content}</Typography>
                </Box>
              ))}
            </Paper>
          )}
          {isLoading && (
            <Box sx={customConversationStyles.loadingContainer}>
              <CircularProgress size={24} />
              <Typography variant="body2" sx={{ ml: 1 }}>
                {LOADING_MESSAGE}
              </Typography>
            </Box>
          )}
          {conversation.length == 0 && (
            <Box sx={customConversationStyles.controlsContainer}>
              <Typography variant="subtitle1" color="primary">
                Stance:
              </Typography>
              <ToggleButtonGroup
                value={stance}
                exclusive
                onChange={handleStanceChange}
                aria-label="stance"
                disabled={isLoading}
              >
                <ToggleButton value="PRO" aria-label="pro stance">
                  PRO
                </ToggleButton>
                <ToggleButton value="ANTI" aria-label="anti stance">
                  ANTI
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          )}
          <Box sx={customConversationStyles.controlsContainer}>
            <Typography variant="subtitle1" color="primary">
              Reasoning Type:
            </Typography>
            <ToggleButtonGroup
              value={reasoningType}
              exclusive
              onChange={handleReasoningTypeChange}
              aria-label="reasoning type"
              disabled={isLoading}
            >
              <ToggleButton
                value="devils_advocate"
                aria-label="devil's advocate reasoning"
              >
                Devil's Advocate
              </ToggleButton>
              <ToggleButton
                value="iterative_drafting"
                aria-label="iterative drafting reasoning"
              >
                Iterative Drafting
              </ToggleButton>
              <ToggleButton
                value="plan_and_execute"
                aria-label="plan and execute reasoning"
              >
                Plan & Execute
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box sx={customConversationStyles.controlsContainer}>
            {/* <Typography variant="subtitle1" color="primary">
              Reasoning Method:
            </Typography>
            <ToggleButtonGroup
              value={useMonteCarloTreeOfThought}
              exclusive
              onChange={handleMonteCarloToggle}
              aria-label="reasoning method"
              disabled={isLoading}
            >
              <ToggleButton value={false} aria-label="tree of thought">
                Tree of Thought
              </ToggleButton>
              <ToggleButton
                value={true}
                aria-label="monte carlo tree of thought"
              >
                Monte Carlo Tree of Thought
              </ToggleButton>
            </ToggleButtonGroup> */}
          </Box>
          <SliderControls
            isLoading={isLoading}
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
          />
          <Stack direction="row" spacing={2} justifyContent="center">
            {conversation.length === 0 ? (
              <Button
                variant="contained"
                onClick={handleSubmitTopic}
                disabled={isLoading || !topic.trim()}
                sx={customConversationStyles.button}
              >
                Start Conversation
              </Button>
            ) : (
              <>
                <Button
                  variant="contained"
                  onClick={() => handleGenerateResponse(false)}
                  disabled={isLoading}
                  sx={customConversationStyles.button}
                >
                  {isLoading ? "Generating..." : "Generate AI Response"}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => handleGenerateResponse(true)}
                  disabled={isLoading}
                  sx={customConversationStyles.button}
                >
                  Try Again
                </Button>
                <Button
                  variant="outlined"
                  onClick={resetConversation}
                  disabled={isLoading}
                  sx={customConversationStyles.button}
                >
                  Reset Conversation
                </Button>
              </>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CustomConversation;
