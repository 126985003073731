import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import { CardActionStyles } from "../Feed/FeedStyle";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import background from "../../assets/background3.png";
import Yosef from "../../assets/Yosef.jpeg";
import Ronen from "../../assets/Ronen.jpeg";
import Zach from "../../assets/Zach.jpeg";
import AboutIntroductionSection from "./AboutIntroductionSection";
import AddReportModal, { ReportData } from "../Feed/AddReportModal";
import {
  POSTS_DB,
  REPORT_OPTIONS,
  USERS_REPORTS_CONTAINER,
} from "../../utils/constant";
import { addPostToDatabase } from "../../utils/webServices";

interface member {
  name: string;
  role: string;
  isPress: boolean;
  imgSrc: string;
  text: string;
}
interface TeamMemberProps {
  isSelected: boolean;
}

const PageContainerDiv = styled.div`
  width: 100%; // Changed from 100wh to 100%
  min-height: 100vh; // Changed for better responsiveness
  background-color: rgb(240, 240, 240);
  @media (min-width: 768px) {
    padding: 20px;
  }
`;
const ContentContainerDiv = styled.div`
  font-family: "Arial", sans-serif;
  width: 100%; // Changed from 100wh to 100%
  max-width: 800px;
  color: #333;
  margin: 0 auto;
  min-height: 100vh; // Changed for better responsiveness
  background-color: rgb(240, 240, 240);

  @media (min-width: 768px) {
    padding: 20px;
  }
`;

const TeamSection = styled.section`
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
`;

const TeamMember = styled.div<TeamMemberProps>`
  text-align: center;
  opacity: ${(props) => (props.isSelected ? 1 : 0.35)};
  width: 100%; // Full width on mobile
  font-size: 14px;

  @media (min-width: 768px) {
    width: auto; // Auto width on larger screens
    font-size: 16px;
  }
`;

const TeamImage = styled.img`
  width: 100px; // Smaller image size on mobile
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    width: 150px; // Larger image size on desktop
    height: 150px;
  }
`;

const MissionStatementSection = styled.section`
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 40px;
`;

const TeamText = styled.div`
  font-size: 16px;
  display: flex;
  max-width: 90vw;
  @media (min-width: 768px) {
    max-width: 60vw;
  }
`;
const TextContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TeamName = styled.h3`
  margin: 0;
`;

const TeamRole = styled.p`
  margin: 0;
`;

const teamMembers: member[] = [
  {
    name: "Zachary Bamberger",
    role: "Founder",
    isPress: false,
    imgSrc: Zach,
    text: `With a strong background in AI and NLP, Zachary is instrumental in guiding the company's vision and technological direction.
    `,
  },
  {
    name: "Ronen Irani",
    role: "",
    imgSrc: Ronen,
    isPress: false,
    text: ` A seasoned executive with extensive experience in business, product management, and operations. Ronen's expertise in strategic business management and operational excellence is pivotal to our growth.
  `,
  },
  {
    name: "Yosef Ben Yehuda ",
    role: "Product and Development",
    isPress: false,
    imgSrc: Yosef,
    text: ` Specializing in AI integration, Web development and soon to be Med student, Yosef built our platform almost single-handedly. His dedication and diverse skill set are invaluable to our team's product’s innovation.`,
  },
];

const AboutPage: React.FC = () => {
  const [selectedMember, setSelectedMember] = useState<member>({
    name: "Yosef ",
    role: "Product and Development",
    isPress: false,
    imgSrc: Yosef,
    text: ` Specializing in AI integration, Web development and soon to be Med student, Yosef built our platform almost single-handedly. His dedication and diverse skill set are invaluable to our team's product’s innovation.`,
  });

  const handleMemberClick = (member: member) => {
    setSelectedMember(member);
  };
  const styles = CardActionStyles;
  const theme = useTheme();

  const handleReport = async (reportData: ReportData) => {
    console.log(reportData);
    addPostToDatabase(
      {
        id: Date.now().toString,
        reportText: reportData,
        user: user?.email || "not found",
      },
      USERS_REPORTS_CONTAINER,
      POSTS_DB
    );
    console.log("sent!");
    setIsReportModalOpen(false);
  };

  const { logout, user } = useAuth0();
  const [isMobile, setIsMobile] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);

  const updateDimenstions = () => {
    if (window.innerWidth < 765) {
      setIsMobile(true);
    }
  };
  useEffect(() => {
    updateDimenstions();
    window.addEventListener("resize", updateDimenstions);
    return () => window.removeEventListener("resize", updateDimenstions);
  }, []);

  return (
    <>
      <AboutIntroductionSection
        setIsReportModalOpen={setIsReportModalOpen}
        isMobile={isMobile}
        logout={logout}
      />

      <PageContainerDiv>
        <ContentContainerDiv>
          <MissionStatementSection>
            <Typography variant="h4" gutterBottom>
              Our Mission
            </Typography>
            <Typography variant="body1">
              Rhetoric AI is an innovative technology company focused on
              revolutionizing the way online discourse is shaped and influencing
              public opinion, particularly in the realm of social media. We
              leverage cutting-edge AI to enhance the efficacy of online
              campaigns, countering misinformation, and shaping public opinion
              in favor of our clients. Our mission is to harness the power of AI
              for creating a more informed, balanced, and ethical digital
              landscape.
            </Typography>
          </MissionStatementSection>

          <TeamSection>
            {teamMembers.map((member) => (
              <TeamMember
                onClick={() => handleMemberClick(member)}
                isSelected={
                  selectedMember
                    ? selectedMember && selectedMember.name === member.name
                    : false
                }
              >
                <TeamImage src={member.imgSrc} alt={member.name} />
                <TeamName>{member.name}</TeamName>
                <TeamRole>{member.role}</TeamRole>
              </TeamMember>
            ))}
          </TeamSection>
          {selectedMember && (
            <TextContainer>
              <TeamText>{selectedMember.text}</TeamText>
            </TextContainer>
          )}
        </ContentContainerDiv>
      </PageContainerDiv>
      <AddReportModal
        isReportModalOpen={isReportModalOpen}
        REPORT_OPTIONS={REPORT_OPTIONS}
        handleReport={handleReport}
        closeReportModal={() => setIsReportModalOpen(false)}
      />
    </>
  );
};

export default AboutPage;
