import React, { useState, useEffect } from "react";
import { Box, Theme, Typography, useTheme } from "@mui/material";
import { FacebookComment, FacebookPost } from "../../../utils/interfaces";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import { treeCommentTableStyles } from "./CampaignStyles";
import { Agent } from "../CampaignInterface";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { alpha } from "@mui/material/styles";

const styles = treeCommentTableStyles;

interface Props {
  post: FacebookPost;
  selectedAgent: Agent;
  campaignId?: string;
  selectedComment: FacebookComment | null | undefined;
  setSelectedComment: React.Dispatch<
    React.SetStateAction<FacebookComment | null | undefined>
  >;
}

// ... existing imports

const getSentimentIcon = (sentiment?: "positive" | "neutral" | "negative") => {
  switch (sentiment) {
    case "positive":
      return <SentimentSatisfiedAltIcon color="success" />;
    case "negative":
      return <SentimentVeryDissatisfiedIcon color="error" />;
    default:
      return <SentimentNeutralIcon color="action" />;
  }
};

const getSentimentStyle = (
  theme: Theme,
  sentiment?: "positive" | "neutral" | "negative"
) => {
  switch (sentiment) {
    case "positive":
      return {
        // 1. Color-coded borders
        borderLeft: `3px solid ${theme.palette.success.main}`,
        // 2. Subtle background gradients
        background: `linear-gradient(to right, ${alpha(
          theme.palette.success.light,
          0.07
        )}, transparent)`,
        // 3. Small colored dots
        "&::before": {
          content: '""',
        },
        // 4. Thin colored underlines
        borderBottom: `1px solid ${alpha(theme.palette.success.main, 0.1)}`,
        // 5. Slight text color variations
        color: {},
      };
    case "negative":
      return {
        borderLeft: `3px solid ${theme.palette.error.main}`,
        background: `linear-gradient(to right, ${alpha(
          theme.palette.error.light,
          0.06
        )}, transparent)`,
        "&::before": {
          content: '""',
        },
        borderBottom: `1px solid ${alpha(theme.palette.error.main, 0.1)}`,
        color: {},
      };
    default:
      return {
        borderLeft: `3px solid ${theme.palette.grey[400]}`,
        background: `linear-gradient(to right, ${alpha(
          theme.palette.grey[300],
          0.07
        )}, transparent)`,
        "&::before": {
          content: '""',
        },
        borderBottom: `1px solid ${alpha(theme.palette.grey[400], 0.1)}`,
        color: {},
      };
  }
};

const CommentTable = ({
  setSelectedComment,
  selectedComment,
  campaignId,
  post,
  selectedAgent,
}: Props) => {
  const theme = useTheme();

  const renderTree = (comments: FacebookComment[], depth = 0) =>
    comments.map((comment) => (
      <TreeItem
        key={comment.id}
        itemId={comment.id}
        label={
          <Box
            sx={{
              ...styles.commentBox,
              ...getSentimentStyle(theme, comment.sentiment),
              marginLeft: `${depth * 16}px`,
            }}
          >
            <Box sx={styles.commentHeader}>
              <Typography
                component="span"
                variant="body1"
                sx={styles.authorName}
              >
                {comment.author?.name}
              </Typography>
            </Box>
            <Typography component="span" variant="body1">
              {comment.content}
            </Typography>
            <Box sx={styles.commentMetadata}>
              <Typography variant="caption" sx={{ paddingRight: "10px" }}>
                {comment.likes} likes
              </Typography>
              <Typography variant="caption" sx={{ paddingRight: "10px" }}>
                {comment.comments?.length} replies
              </Typography>
            </Box>
          </Box>
        }
      >
        {comment.comments && Array.isArray(comment.comments)
          ? renderTree(comment.comments, depth + 1)
          : null}
      </TreeItem>
    ));
  const [selectedCommentId, setSelectedCommentId] = useState<string>("");
  const styles = treeCommentTableStyles;

  const handleItemSelectionToggle = (
    event: React.SyntheticEvent,
    itemId: string,
    isSelected: boolean
  ) => {
    if (isSelected) {
      setSelectedCommentId(itemId);
    } else {
      setSelectedCommentId("");
    }
  };

  // Move this effect outside of the render function
  useEffect(() => {
    const findCommentById = (
      comments: FacebookComment[],
      id: string
    ): FacebookComment | null => {
      for (let comment of comments) {
        if (comment.id === id) {
          return comment;
        }
        if (comment.comments) {
          const found = findCommentById(comment.comments, id);
          if (found) return found;
        }
      }
      return null;
    };

    const tempSelectedComment = selectedCommentId
      ? findCommentById(post.comments, selectedCommentId)
      : null;
    setSelectedComment(tempSelectedComment);
  }, [selectedCommentId, post.comments, setSelectedComment]);

  return (
    <Box sx={styles.container}>
      <SimpleTreeView onItemSelectionToggle={handleItemSelectionToggle}>
        {renderTree(post.comments)}
      </SimpleTreeView>
    </Box>
  );
};

export default CommentTable;
