import { FacebookPost } from "../../utils/interfaces";

export const newConversation: FacebookPost[] = [
  {
    id: "3",
    topic: "Legality and ethics of Israel's pager bomb attack on Hezbollah",
    message:
      "From a law of war standpoint, Israel's pager bombs represent one of the most precisely targeted strikes in the history of warfare. I can't think of a single widespread strike on an armed force that's embedded in a civilian population that's been more precise. It's remarkable.",
    url: "https://twitter.com/DavidAFrench/status/1836176001019060332?ref_src=twsrc%5Etfw",
    commentsCount: 11,
    comments: [
      {
        id: "1",
        author: {
          name: "Heidi N. Moore",
          id: "RealScottRitter",
        },
        content: `From a "law of war" standpoint, Israel's indiscriminate terrorist   attack was a violation of the Geneva Conventions. 

      Your defense of Israel's war crimes and terrorism is reprehensible and frankly, disqualifying to your opinion on anything if you lack the basic judgment on something so simple.`,
        likes: 140,
        sentiment: "negative",
        comments: [],
      },
      {
        id: "2",
        author: {
          name: "Scott Ritter",
          id: "RealScottRitter",
        },
        content: `Of course, you also think invading Iraq in 2003 was a just cause.
              It was an illegal war of aggression.
              But let’s get real for a moment—you were a judge advocate in Iraq in 2007. If you had carried out an action like this against Iraqis, you would have been arrested and prosecuted for war crimes.
              And you know that is the truth.

      And yet you shill for the Israelis.`,
        likes: 41,
        sentiment: "negative",
        comments: [],
      },
      {
        id: "3",
        author: {
          name: "Rob Howse",
          id: "TheSylvreWolfe",
        },
        content:
          "If you simply accept the line that all of the pagers targeted were personally used by combatants, then there is something to that.  But proof of that? What I can learn so far, from public sources, is they were merely purchased by Hezbollah, both terror group & political party.",
        likes: 75,
        sentiment: "negative",
        comments: [],
      },
    ],
  },
  {
    id: "6",
    topic:
      "Donald Trump will do a better job at immigration and border security than Kamala Harris",
    message:
      "After almost four years, Border Czar Kamala Harris has decided, for political reasons, that it's time for her to go to our broken Southern Border. What a disgrace that she waited so long, allowing millions of people to enter our Country from prisons, mental institutions, and criminal cells all over the World, not just South America, many of those coming are terrorists, and at a level never seen before! She's trying to con the public like she did a good job at the Border when, in fact, she has destroyed the very fabric of our Nation allowing 21 Million people in from places unknown. When Kamala is seen at the Border on Friday, she will pass Hundreds of Miles of Wall that was built by TRUMP, and it is Wall that WORKS! When she speaks, be advised that this woman has allowed more than 21 million people into our Country, totally unvetted, and from places unknown. They are now creating criminal havoc all throughout the Country. Every State is a Border State! When she speaks, I hope everybody remembers that she has caused our cities, towns, and Country itself, tremendous damage, and only I can fix it!",
    url: "https://twitter.com/realDonaldTrump/status/1838780123610243192",
    commentsCount: 7,
    comments: [
      {
        id: "1",
        author: {
          name: "Dave Ryder",
          id: "ValentinaForUSA",
        },
        content: `American voters will remember that Donald Trump BLOCKED the most comprehensive, strongest Border Bill in American history because he wanted to campaign on the issue

He would rather open the border up to illegal immigrants than see a bipartisan group FIX THE BORDER

DESPICABLE`,
        likes: 551,
        sentiment: "negative",
        comments: [],
      },
      {
        id: "2",
        author: {
          name: "Human ",
          id: "BoLoudon",
        },
        content: `.
@realDonaldTrump
 Trump’s post is full of lies and fear tactics. Kamala Harris was never the “Border Czar.” She was tasked with tackling the root causes of migration, not managing the border itself. 

His claims about “millions” of criminals and terrorists entering the U.S. are baseless. The “21 million” figure is a complete fabrication, actual numbers are much lower.

Trump's own immigration policies were chaotic and harmful. He rejected a bipartisan deal that would have fixed our broken system because he wanted to use immigration as a political weapon. His approach was cruel and divisive, prioritizing his agenda over national interests.

The bill included:

Increased Border Patrol Staffing: Over 1,500 new Customs and Border Protection personnel.

Asylum Reform: 4,300 new Asylum Officers to expedite determinations and deport the unqualified.

Increased Detention Capacity: Expanding ICE detention beds from 40,000 to 50,000.

Additional Immigration Judges: Funding for 100 new judges to reduce case backlogs.

Enhanced Screening and Vetting: Improved screening for individuals entering the country.

But you couldn't stand to see a solution that didn't fit your twisted narrative. Your actions are directly responsible for perpetuating this crisis of human suffering.

Harris is focused on real, compassionate solutions that strengthen both security and justice. Trump’s nonsense is dangerous and misleading; Americans deserve truth and effective leadership, not more lies and division.

Dear Trump voter: imagine a friend who lies to you once. You might forgive them. Twice, maybe you still trust them. But what if they lie ten times? A hundred? A thousand? Thirty thousand times and every day after?  

At some point, it's not just them deceiving you, it's you choosing to be deceived.   

If you're voting for Trump, the betrayal isn't just on him, it's on you for letting it happen.

Analysis | Tracking all of President Trump’s false or misleading claims https://wapo.st/trumpclaimsdb?tid=ss_tw`,
        likes: 507,
        sentiment: "negative",
        comments: [],
      },
    ],
  },
  {
    id: "4",
    topic:
      "Israeli soldiers deliberately shoot to kill Palestinian civilians (including children)",
    message:
      "The @nytimes published a piece in which dozens of foreign medical volunteers who went into Gaza recounted seeing and treating children who were deliberately shot in the head and neck by Israeli soldiers (you can tell from the x-ray they're 5.56 rounds used by the IDF). These volunteers describe in painful detail the extent of horror they saw firsthand, including mass killings, malnutrition, targeting of civilians, and repeated and documented patterns of IDF soldiers behaving ruthlessly toward Palestinian noncombatants throughout the war in Gaza. It is indisputable that horrendous war crimes were and are continuing to be committed in the Gaza war, no matter the claims to the contrary. Hamas's criminality and terrorism on October 7 can never justify this behavior by a professional military with clear rules of engagement and codes of conduct. There must be accountability for these crimes",
    url: "https://twitter.com/afalkhatib/status/1844751647672512987",
    commentsCount: 9,
    comments: [
      {
        id: "3",
        author: {
          name: "Jews Fight Back",
          id: "JewsFightBack",
        },
        content:
          "The image you posted of a supposed Palestinian child with a bullet in his head is not only misleading but scientifically impossible. A 5.56 round, which is the type commonly used by the IDF, would cause significant damage to the skull upon impact. The fact that the skull in this",
        likes: 150,
        sentiment: "negative",
        comments: [],
      },

      {
        id: "6",
        author: {
          name: "Cheryl E",
          id: "CherylWroteIt",
        },
        content: `Hello 
@afalkhatib
 

I saw your post after seeing the reply from 
@COLRICHARDKEMP
 and 
@AntSpeaks
, and then I read the article, twice. As someone who is actually a forensic ballistics specialist, I wanted to respond to your post, and this article, with some facts that will demonstrate just how deliberately dishonest and inaccurate both your post and the article are:

1. The damage to the body by bullet shot depends on the weapon and caliber used. A small low caliber pistol will always have less damage than a high caliber rifle such as the 5.56 you and the article mention. 

2. The most important factor that determines the level of damage is velocity. And a 5.56 caliber high velocity rifle as is used by the IDF will therefore have a high degree of damage to the head and skull. None of the most obvious types of damage from any gunshot wound to the head nevertheless a 5.56 high velocity rifle shot are visible. 

3. When the bullet hits the skull at high velocity, it bevels into the skull, which means as it’s passing through the skull, the immediate entry is small and clean whereas the exit at the front of the skull inside the head is wider and flared. It splinters the skull bone on entry and creates bone shards that then move with the bullet and cause even more damage. 

4. Once the bullet enters the head, especially at high velocity, it heats up and creates a shockwave in front of the bullet which widens as the bullet travels through the head causing more damage. The brain is a solid, soft and highly inelastic organ, which means the damage to the brain is such that it literally mushes. The shockwave on entry causes external gases to enter the head in front of the bullet and thus significant displacement of brain matter very rapidly which in turn causes the head to expand rapidly thus causing primary and secondary fractures in various areas of the skull. NONE such fractures are visible in these X-rays. 

5. Back to the velocity of the 5.56 rifle shot, for any bullet to stop in the area shown in any of the X-ray images, the bullets would need to travel at very low velocity. That means either the bullets were fired by low velocity small caliber pistols or the rifle shots would need to have been fired from a very long distance (many hundreds of meters) with pinpoint accuracy. When looking at the images, the caliber of bullets are not the same. The first image has a much shorter bullet than the second, meaning it could not be the same caliber. 

Now if the children were deliberately shot in the head and neck, it would mean it would be from close range. With a high velocity 5.56 rifle of the type the IDF use, the bullet would never stop so quickly, ever. The bullet would travel so quickly due to VELOCITY that they would almost always exit the skull or body causing much larger exit wounds. None are visible as the bullets are all magically stopped for the perfect X-ray pose. As you can see, there is no damage to the brain in the X-rays. This is even more true of the neck shots where the bullets barely travel an inch and stop at the spine. This would happen only with very low velocity small caliber handguns such as .22 caliber pistols. 

6. Basically, for any of the X-rays to be true, these would need to be low velocity pistols, and not high velocity rifles. The distance of fire would still not be very close range, as the damage is not significant enough. Very close range has higher velocity thus more damage. 

I’m quite happy for any other ballistics experts to come and debate the post and article and my analysis above. What I can pretty comfortably say is that these are not head and neck shots by high velocity 5.56 rifles. At best these may be wounds from ricochets which would mean they are not deliberate and completely accidental, and also not from close range, but would account for the much lower velocity. At worst, and more likely, this entire post of yours, and the article, is complete and utter bullshit. 

Thanks for reading. 
Cheryl`,
        likes: 150,
        sentiment: "negative",
        comments: [],
      },
    ],
  },
  {
    id: "5",
    topic:
      "California’s SB-1047 proposes an overly restricting regulation, that will stifle innovation, and cause more harm than good onto society at large",
    message: `
    I’m deeply concerned about California’s SB-1047, Safe and Secure Innovation for Frontier Artificial Intelligence Models Act. While well intended, this bill will not solve what it is meant to and will deeply harm #AI academia, little tech and the open-source community. https://fortune.com/2024/08/06/godmother-of-ai-says-californias-ai-bill-will-harm-us-ecosystem-tech-politics/`,
    url: "https://twitter.com/drfeifei/status/1820838100568056028",
    commentsCount: 9,
    comments: [
      {
        id: "1",
        author: {
          name: "Michael Cohen",
          id: "JewsFightBack",
        },
        content:
          "You say that the shutdown provision would preclude open source, but open source has been exempted. You say it will harm academia and little tech, but the bill only applies to $100M training runs.",
        likes: 130,
        sentiment: "negative",
        comments: [
          {
            id: "2",
            author: {
              name: "David Hinkle",
              id: "JewsFightBack",
            },
            content:
              "Open source has not been exempted, and llama 3 the latest open source model used more than 100 million in compute alone.",
            likes: 41,
            sentiment: "positive",
            comments: [],
          },
          {
            id: "3",
            author: {
              name: "Kevin Lacker",
              id: "JewsFightBack",
            },
            content:
              "Academia and little tech are helped by open source releases like Llama. When you discourage companies from open sourcing their expensive work, that harms academic and little tech.",
            likes: 10,
            sentiment: "positive",
            comments: [],
          },
        ],
      },
      {
        id: "4",
        author: {
          name: "Liv Boeree",
          id: "JewsFightBack",
        },
        content:
          "I’m deeply concerned about your $100m from A16z who are known to be actively misleading people with falsehoods about this bill",
        likes: 131,
        sentiment: "negative",
        comments: [],
      },
    ],
  },
];

export const conversations: FacebookPost[] = [
  {
    id: "1",
    topic: "Israel's use of pager bombs in military operations",
    message:
      "From a law of war standpoint, Israel's pager bombs represent one of the most precisely targeted strikes in the history of warfare. I can't think of a single widespread strike on an armed force that's embedded in a civilian population that's been more precise. It's remarkable.",
    url: "https://twitter.com/DavidAFrench/status/1836176001019060332",
    commentsCount: 5,
    comments: [
      {
        id: "1",
        author: {
          name: "Dr. Amira Dalton",
          id: "libertymama86",
        },
        content:
          "Remarkable and from a Law of war standpoint , Israel just committed a war crime . Imagine his response if a militant group had put explosives in the phones of US servicemen and their children were killed",
        likes: 1100,
        sentiment: "negative",
        comments: [
          {
            id: "2",
            author: {
              name: "staswilf",
              id: "staswilf",
            },
            content:
              'No, Israel didn\'t. Please go and educate yourself on laws of war and the term "collateral damage".',
            likes: 8,
            sentiment: "positive",
            comments: [
              {
                id: "3",
                author: {
                  name: "linkshund",
                  id: "linkshund",
                },
                content: "Collateral damage is murder.",
                likes: 6,
                sentiment: "negative",
                comments: [],
              },
            ],
          },
        ],
      },
      {
        id: "5",
        author: {
          name: "Jamal Dajani جمال",
          id: "JamalDajani",
        },
        content:
          "You are one sick individual. Children have been killed and shoppers, medics and ordinary people have been maimed. This is a terrorist attack that will only invite retaliation that will cause the death of innocent civilians.",
        likes: 452,
        sentiment: "negative",
        comments: [
          {
            id: "6",
            author: {
              name: "Miffy in New York",
              id: "dklompsma",
            },
            content:
              "Maybe tell your terrorist friends not to try to murder Jews for -let's say- a decade. You'd be surprised with the results.",
            likes: 236,
            sentiment: "positive",
            comments: [],
          },
          {
            id: "9",
            author: {
              name: "Julia",
              id: "theophimia",
            },
            content:
              "This is an amazingly surgical military operation. Yes, Israel has the right to defend itself. But we know that the Jihadi lovers will never be happy...",
            likes: 0,
            sentiment: "positive",
            comments: [],
          },
        ],
      },
      {
        id: "10",
        author: {
          name: "Rob Howse",
          id: "howserob",
        },
        content:
          "If you simply accept the line that all of the pagers targeted were personally used by combatants, then there is something to that. But proof of that? What I can learn so far, from public sources, is they were merely purchased by Hezbollah, both terror group & political party.",
        likes: 47,
        sentiment: "negative",
        comments: [
          {
            id: "13",
            author: {
              name: "Zarah Sultana MP",
              id: "zarahsultana",
            },
            content:
              "Still waiting for your condemnation of #Hezbollah's year long bombardment of northern Israel which forced 60,000 from their homes and killed Druze children, Zarah? Cat got your tongue?",
            likes: 1700,
            sentiment: "positive",
            comments: [
              {
                id: "15",
                author: {
                  name: "Dennis Belogorsky",
                  id: "DBelogorsky",
                },
                content:
                  'Because she only cares about the "rules of warfare" when it applies to Israel and not to the paramilitary terrorist groups that she tacitly supports.',
                likes: 13,
                sentiment: "positive",
                comments: [
                  {
                    id: "16",
                    author: {
                      name: "|",
                      id: "wtfjunalisa",
                    },
                    content:
                      "We all get proper information this side of the world. Sorry that doesn't match what American media owned by Zionists pushes out or your AIPAC owned politicians.",
                    likes: 0,
                    sentiment: "negative",
                    comments: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "2",
    topic: "Double Standard in Politics in relation to Israel and Palestine",
    message: "",
    url: "https://twitter.com/AOC/status/1837220813176721773",
    commentsCount: 5,
    comments: [
      {
        id: "1",
        author: {
          name: "Aviva Klompas",
          id: "AvivaKlompas",
        },
        content:
          "Can you remind us when you last called out Hezbollah, a US/designated terrorist organization?",
        likes: 2200,
        sentiment: "negative",
        comments: [
          {
            id: "2",
            author: {
              name: "SDL",
              id: "SocDoneLeft",
            },
            content:
              "The ICC ruled Israel's conduct in the war is criminal. When have you called for the US to extradite Netanyahu and Ben-Gvir to the Hague? When have you called for an independent investigation into Israeli soldiers killing American protesters? You don't care. Stop pretending",
            likes: 1300,
            sentiment: "negative",
            comments: [],
          },
          {
            id: "3",
            author: {
              name: "Walter is a Bulldog",
              id: "Walter58930677",
            },
            content:
              'Here\'s what would happen if Hamas and hezbollah and all the other terrorist orgs in the region called for an end to all actions against Israel… if they put down their guns and said "no more war." There would be peace. If Israel did that, they would be wiped off the map.',
            likes: 6,
            sentiment: "negative",
            comments: [
              {
                id: "4",
                author: {
                  name: "simpler times",
                  id: "timessimpler7",
                },
                content:
                  "I honestly just don't believe you're this brain dead. I don't believe it.",
                likes: 13,
                sentiment: "negative",
                comments: [],
              },
            ],
          },
          {
            id: "5",
            author: {
              name: "Jewish Starlight",
              id: "jewishstarlight",
            },
            content:
              "Call out Hezbollah? That would be Islamophobic and anti-Arab!!!",
            likes: 779,
            sentiment: "negative",
            comments: [
              {
                id: "6",
                author: {
                  name: "Zach Pryor",
                  id: "MagicEater96",
                },
                content: "Okay, Zionist. You're a disgrace to Judaism.",
                likes: 2,
                sentiment: "negative",
                comments: [
                  {
                    id: "7",
                    author: {
                      name: "The Bear Jew's Bat",
                      id: "ornerydonowitz",
                    },
                    content:
                      "Maybe stop haunting the whole thread and make your life slightly less of an abject failure. Spoiler: you won't.",
                    likes: 0,
                    sentiment: "negative",
                    comments: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: "8",
        author: {
          name: "AmericanPapaBear",
          id: "AmericaPapaBear",
        },
        content:
          "You guys have no room to talk. You are pro hamas sympathizers. Sit down.",
        likes: 809,
        sentiment: "negative",
        comments: [
          {
            id: "9",
            author: {
              name: "mikemakescartoons",
              id: "mikemakestoons",
            },
            content:
              "We can't reduce this conversation to assuming people are only for Hamas or the Israeli government. The death of civilians gets trivialized when we do. Too many people are pointing fingers in the latest iteration of a 3000 year old conflict. Advocating for less racism is good.",
            likes: 493,
            sentiment: "positive",
            comments: [
              {
                id: "10",
                author: {
                  name: "Moopsy",
                  id: "Chef_EZToon",
                },
                content:
                  "the deaths of civilians dont get trivialized as an israel supporter, it does as a hamas supporter, because thats hama's goal, get as many people killed as possible.",
                likes: 79,
                sentiment: "negative",
                comments: [
                  {
                    id: "11",
                    author: {
                      name: "Kastor",
                      id: "kastorcaster",
                    },
                    content:
                      "Yeah! Hamas actually just rigged explosives in an entire shipment of pagers because they knew a handful of them would go to military targets, killed 10+ innocents, including children and severely injured almost 3000 people.",
                    likes: 0,
                    sentiment: "negative",
                    comments: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "1",
    topic:
      "Islamophobia, anti-Arab sentiment, and international relations in the Middle East",
    message:
      "Shame on the @detroitnews for allowing this racist, xenophobic vile cartoon on their platform. Pay attention to who condemns this. And then recognize the different standard Arab and Muslim politicians are held by.",
    url: "",
    commentsCount: 8,
    comments: [
      {
        id: "1",
        author: {
          name: "Abraham Aiyash",
          id: "AbrahamAiyash",
        },
        content:
          'When I was doxxed by the far right and had threats made against me and my family, party leaders told me I "shouldn\'t have said" things to "stoke anger" (I was calling out the racist attempt to not certify the election in Detroit). This normalization of hate will induce violence',
        likes: 1700,
        sentiment: "negative",
        comments: [
          // ... (previous comments remain the same)
        ],
      },
      {
        id: "13",
        author: {
          name: "Bailey",
          id: "realsnoopbailey",
        },
        content:
          "You're right, Abraham. Congresspersons should be able to back whatever islamic terrorist groups they choose to without any criticism whatsoever. Especially the ones that want to wipe out that tiny little evil Jewish country.",
        likes: 535,
        sentiment: "negative",
        comments: [
          // ... (previous comments remain the same)
        ],
      },
      {
        id: "18",
        author: {
          name: "Expat Vibes",
          id: "expatvibes",
        },
        content:
          "The U.S. State Dept says the U.S. was not involved in the pager explosions in Lebanon and was not aware of the incident beforehand. In unrelated news, the American University of Beirut Medical Center replaced the pagers of their doctors and staff 2 weeks ago.",
        likes: 53000,
        sentiment: "positive",
        comments: [
          {
            id: "19",
            author: {
              name: "Cryptiano Omar",
              id: "CryptianoOmarYT",
            },
            content:
              "Man, they always lie. They do all the dirty work for Israel and act like they're never even involved. CIA is like the boss of Mossad. They work hand in hand",
            likes: 501,
            sentiment: "negative",
            comments: [
              {
                id: "20",
                author: {
                  name: "Elizabeth Grossman",
                  id: "ejglaw",
                },
                content:
                  "Do think that international cooperation is a bad thing?",
                likes: 0,
                sentiment: "positive",
                comments: [
                  {
                    id: "21",
                    author: {
                      name: "Cyberworld 9000",
                      id: "9000Cyberworld",
                    },
                    content: "When they're doing terror attacks????? Yes.",
                    likes: 129,
                    sentiment: "negative",
                    comments: [],
                  },
                  {
                    id: "22",
                    author: {
                      name: "محمد خطاب",
                      id: "mhmdkht93685944",
                    },
                    content:
                      "You mean terrorist cooperation, not international. Please put the words in their correct context",
                    likes: 34,
                    sentiment: "negative",
                    comments: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "2",
    topic:
      "US involvement in Lebanon pager explosions and international cooperation",
    message:
      "The U.S. State Dept says the U.S. was not involved in the pager explosions in Lebanon and was not aware of the incident beforehand. In unrelated news, the American University of Beirut Medical Center replaced the pagers of their doctors and staff 2 weeks ago.",
    url: "https://twitter.com/expatvibes/status/1836118467600437632",
    commentsCount: 3,
    comments: [
      {
        id: "1",
        author: {
          name: "Cryptiano Omar",
          id: "CryptianoOmarYT",
        },
        content:
          "Man, they always lie. They do all the dirty work for Israel and act like they're never even involved. CIA is like the boss of Mossad. They work hand in hand",
        likes: 501,
        sentiment: "negative",
        comments: [
          {
            id: "2",
            author: {
              name: "Elizabeth Grossman",
              id: "ejglaw",
            },
            content: "Do think that international cooperation is a bad thing?",
            likes: 0,
            sentiment: "positive",
            comments: [
              {
                id: "3",
                author: {
                  name: "Cyberworld 9000",
                  id: "9000Cyberworld",
                },
                content: "When they're doing terror attacks????? Yes.",
                likes: 129,
                sentiment: "negative",
                comments: [],
              },
              {
                id: "4",
                author: {
                  name: "محمد خطاب",
                  id: "mhmdkht93685944",
                },
                content:
                  "You mean terrorist cooperation, not international. Please put the words in their correct context",
                likes: 34,
                sentiment: "negative",
                comments: [],
              },
            ],
          },
        ],
      },
    ],
  },
];
