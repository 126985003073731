import { useState } from 'react';
import { FacebookPage } from '../../../../utils/interfaces';

export const useFacebookLogin = () => {
    const [pages, setPages] = useState<FacebookPage[]>([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const login = () => {
        window.FB.login((response: any) => {
            if (response.authResponse) {
                console.log('Welcome! Fetching your information.... ');
                setIsLoggedIn(true);
                fetchPages();
            } else {
                console.log('User cancelled login or did not fully authorize.');
            }
        }, { scope: 'pages_show_list, pages_read_engagement, pages_manage_posts, pages_manage_metadata,pages_manage_engagement,pages_read_user_content' });
};
    const fetchPages = () => {
        window.FB.api('/me/accounts', 'GET', {}, function(pagesResponse: any) {
            if (pagesResponse && pagesResponse.data) {
                setPages(pagesResponse.data);
            } else {
                console.log('No pages found or access to pages not granted.');
            }
        });
    };

    return { login, pages, isLoggedIn };
};
