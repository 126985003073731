import React, { useState } from "react";
import { Box, Slider, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { AnswerCardStyles } from "./CampaignStyles";
import { slidersValue } from "../../../utils/interfaces";
import { Length } from "../EndpointService/mapSliderValues";
import { Formality } from "../CampaignInterface";

// export interface slidersValue {
//     toneValue: number,
//     lengthValue: number,
//     languageStyleValue: number,
//     communicationToneValue: number
// }

interface SliderControlsProps {
  sliderValue: slidersValue;
  setSliderValue: (value: slidersValue) => void;
  isLoading: boolean;
}

const SliderControls: React.FC<SliderControlsProps> = ({
  sliderValue,
  setSliderValue,
  isLoading,
}: SliderControlsProps) => {
  const handleSliderChange = (value: number, name: keyof slidersValue) => {
    setSliderValue({ ...sliderValue, [name]: value });
  };

  const handleToggleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: number,
    name: keyof slidersValue
  ) => {
    if (newValue !== null) {
      setSliderValue({ ...sliderValue, [name]: newValue });
    }
  };

  function valueLabelFormatLength(value: number) {
    const units = [
      Length.ONE_SENTENCE,
      Length.TWO_SENTENCES,
      Length.FEW_SENTENCES,
      Length.ONE_PARAGRAPH,
      Length.FEW_PARAGRAPHS,
    ];
    return `${units[value - 1]}`;
  }

  function valueLabelFormatFormality(value: number) {
    const units = ["Slang", "Casual", 'Formal'];
    return `${units[value - 1]}`;
  }

  function valueLabelFormatTone(value: number) {
    const units = ["Liberal", "Balanced", "Conservative"];
    return `${units[value - 1]}`;
  }
  const LengthMarks = [
    {
      value: 1,
      label: Length.ONE_SENTENCE,
    },
    {
      value: 2,
      label: "",
    },
    {
      value: 3,
      label: Length.FEW_SENTENCES,
    },
    {
      value: 4,
      label: "",
    },
    {
      value: 5,
      label: Length.FEW_PARAGRAPHS,
    },
  ];

  const FormalityMarks = [
    {
      value: 1,
      label: "Slang",
    },
    {
      value: 2,
      label: 'Casual',
    },
    {
      value: 3,
      label: 'Formal',
    },
  ];

  const IdeologicalToneMarks = [
    // Audicence
    {
      value: 1,
      label: "Liberal",
    },
    {
      value: 2,
      label: "Balanced",
    },
    {
      value: 3,
      label: "Conservative",
    },
  ];

  const classes = AnswerCardStyles;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "column",
        p: "10px",
        alignItems: "center",
      }}
    >
      <Box></Box>
      {/* needed for proper function of the slider. no IDEA why */}
      <Box sx={{ width: "80%" }}>
        <Box sx={{ fontSize: "14px" }}>Length</Box>

        <Slider
          valueLabelFormat={valueLabelFormatLength}
          sx={(theme) => ({
            "& .MuiSlider-markLabel": {
              ...classes.sliderMarkStyle,
              fontSize: theme.typography.pxToRem(10),
            },
          })}
          valueLabelDisplay="auto"
          aria-label="Custom marks"
          value={sliderValue.lengthValue}
          min={1}
          max={5}
          step={1}
          marks={LengthMarks}
          size="small"
          disabled={isLoading}
          aria-labelledby="non-linear-slider"
          onChange={(e, newValue) => {
            if (typeof newValue === "number") {
              handleSliderChange(newValue as number, "lengthValue");
            }
          }}
        />
      </Box>

      <Box sx={{ width: "80%" }}>
        <Box sx={{ fontSize: "14px" }}>Audience</Box>

        <Slider
          size="small"
          value={sliderValue.toneValue}
          valueLabelFormat={valueLabelFormatTone}
          valueLabelDisplay="auto"
          sx={(theme) => ({
            "& .MuiSlider-markLabel": {
              ...classes.sliderMarkStyle,
              fontSize: theme.typography.pxToRem(10),
            },
          })}
          aria-label="Custom marks"
          defaultValue={2}
          disabled={isLoading}
          min={1}
          max={3}
          step={1}
          marks={IdeologicalToneMarks}
          onChangeCommitted={(e, newValue) => {
            if (typeof newValue === "number") {
              handleSliderChange(newValue as number, "toneValue");
            }
          }}
        />
      </Box>

      <Box sx={{ width: "80%" }}>
        <Box sx={{ fontSize: "14px", paddingTop: "10px" }}>Formality</Box>

        <Slider
          size="small"
          value={sliderValue.languageStyleValue}
          valueLabelFormat={valueLabelFormatFormality}
          valueLabelDisplay="auto"
          sx={(theme) => ({
            "& .MuiSlider-markLabel": {
              ...classes.sliderMarkStyle,
              fontSize: theme.typography.pxToRem(10),
            },
          })}
          aria-label="Custom marks"
          defaultValue={2}
          disabled={isLoading}
          min={1}
          max={3}
          step={1}
          marks={FormalityMarks}
          onChangeCommitted={(e, newValue) => {
            if (typeof newValue === "number") {
              handleSliderChange(newValue as number, "languageStyleValue");
            }
          }}
        />
      </Box>

      <Box
        sx={{
          width: "80%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ fontSize: "14px" }}>Tone</Box>

        <ToggleButtonGroup
          value={sliderValue.communicationToneValue}
          exclusive
          color="secondary"
          onChange={(e, newValue) =>
            handleToggleChange(e, newValue, "communicationToneValue")
          }
          aria-label="communication tone"
          size="small"
          disabled={isLoading}
          sx={{
            paddingTop: "10px",
            color: "#acaaaa",
            top: { xs: "35px", sm: "20px" },
          }}
        >
          <ToggleButton value={1} aria-label="logical">
            Logical
          </ToggleButton>
          <ToggleButton value={2} aria-label="aggressive">
            Aggressive
          </ToggleButton>
          <ToggleButton value={3} aria-label="sarcastic">
            Sarcastic
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};

export default SliderControls;
