import {
  FacebookComment,
  FacebookPost,
  slidersValue,
} from "../../../utils/interfaces";
import {
  Audience,
  Formality,
  Length,
  Message,
  RawThread,
  ResponseParameters,
  Thread,
  Tone,
} from "../CampaignInterface";
import { v4 as uuidv4 } from "uuid";

function facebookCommentToMessage(
  comment: FacebookComment,
  parentIds: string[] = []
): Message {
  const messageId = uuidv4();
  const message: Message = {
    role: "user",
    name: comment.author.name,
    content: comment.content,
    messageId: comment.id,
    processedId: messageId,
    parentIds: parentIds,
    childrenIds: comment.comments ? comment.comments.map((c) => c.id) : [],
    url: comment.message_url,
    timePosted: new Date().toISOString(), // Assuming current time for simplicity
    lastUpdated: new Date().toISOString(), // Assuming current time for simplicity
    likes: [["likes", comment.likes]],
  };
  return message;
}

// Recursive function to process comments and nested replies
function processComments(
  comments: FacebookComment[],
  parentIds: string[] = []
): Message[] {
  let messages: Message[] = [];
  comments.forEach((comment) => {
    const message = facebookCommentToMessage(comment, parentIds);
    messages.push(message);
    if (comment.comments && comment.comments.length > 0) {
      messages = messages.concat(
        processComments(comment.comments, [message.messageId ?? ""])
      );
    }
  });
  return messages;
}

// Function to convert FacebookPost to Thread
export function facebookPostToThread(post: FacebookPost): Thread {
  const messages: Message[] = [];

  // Convert the main post to a message
  const mainMessage: Message = {
    role: "user",
    name: "user",
    content: post.message,
    messageId: post.id,
    processedId: post.id,
    childrenIds: post.comments.map((c) => c.id),
    url: post.url,
    timePosted: new Date().toISOString(), // Assuming current time for simplicity
    lastUpdated: new Date().toISOString(), // Assuming current time for simplicity
    likes: [], // Assuming no likes data for the main post
  };
  messages.push(mainMessage);

  // Process the comments and nested replies
  const commentMessages = processComments(post.comments, [post.id]);
  messages.push(...commentMessages);

  // Create the thread object
  const thread: Thread = {
    messages: messages,
    threadId: post.id,
  };

  return thread;
}

export function convertSliderValue(
  sliders: slidersValue,
  userText?: string
): ResponseParameters {
  const lengthMap: { [key: number]: Length } = {
    1: Length.ONE_SENTENCE,
    2: Length.TWO_SENTENCES,
    3: Length.FEW_SENTENCES,
    4: Length.ONE_PARAGRAPH,
    5: Length.FEW_PARAGRAPHS,
  };

  const toneMap: { [key: number]: Tone } = {
    1: Tone.LOGICAL,
    2: Tone.AGGRESSIVE,
    3: Tone.SARCASTIC,
  };

  const formalityMap: { [key: number]: Formality } = {
    1: Formality.SLANG,
    2: Formality.CASUAL,
    3: Formality.FORMAL,
  };

  const audienceMap: { [key: number]: Audience } = {
    1: Audience.PROGRESSIVE,
    2: Audience.MODERATE,
    3: Audience.CONSERVATIVE,
  };

  return {
    length: lengthMap[sliders.lengthValue],
    tone: toneMap[sliders.communicationToneValue],
    formality: formalityMap[sliders.languageStyleValue],
    targetAudiencePolitics: audienceMap[sliders.toneValue],
    userInstructions: userText ? userText : "",
  };
}

export function transformThreadToFacebookPost(thread: Thread): FacebookPost {
  const { threadId, messages } = thread;

  const postId = threadId || uuidv4();

  const initialMessage = messages[0];

  // Aggregate likes, shares, and views counts
  let totalLikes = 0,
    totalShares = 0,
    totalViews = 0;

  messages.forEach((message) => {
    message.likes?.forEach(([, count]) => (totalLikes += count));
    message.shares?.forEach(([, count]) => (totalShares += count));
    message.views?.forEach(([, count]) => (totalViews += count));
  });

  // Build comments hierarchy iteratively
  const messageMap: { [key: string]: FacebookComment } = {};
  const roots: FacebookComment[] = [];

  messages.forEach((message) => {
    const commentId = message.messageId || uuidv4();
    const comment: FacebookComment = {
      sentiment: message.sentiment,
      id: commentId,
      author: { name: message.name, id: message.authorUsername || uuidv4() },
      content: message.content,
      likes: message.likes?.reduce((acc, [, count]) => acc + count, 0) || 0,
      reply_url: message.url || "",
      message_url: message.url || "",
      isPrepeared: { answer: message.content, isPrepeared: false },
      commentCount: message.childrenIds?.length || 0,
      comments: [],
    };

    messageMap[commentId] = comment;

    if (message.parentIds && message.parentIds.length > 0) {
      message.parentIds.forEach((parentId) => {
        if (!messageMap[parentId]) {
          messageMap[parentId] = {
            sentiment: message.sentiment,
            id: parentId,
            author: { name: "", id: "" },
            content: "",
            likes: 0,
            comments: [],
          } as FacebookComment;
        }
        if (!messageMap[parentId].comments) {
          messageMap[parentId].comments = [];
        }

        messageMap[parentId].comments?.push(comment);
      });
    } else {
      roots.push(comment);
    }
  });

  const facebookPost: FacebookPost = {
    id: postId,
    topic: postId,
    message: initialMessage.content,
    url: thread.url || "",
    commentsCount: roots.length,
    comments: roots,
  };
  return facebookPost;
}

export function extractConversationThread(
  comments: FacebookComment[],
  targetCommentId: string
): FacebookComment[] {
  const findCommentPath = (
    comments: FacebookComment[],
    targetId: string,
    path: FacebookComment[] = []
  ): FacebookComment[] | null => {
    for (const comment of comments) {
      const newPath = [...path, comment];
      if (comment.id === targetId) {
        return newPath;
      }
      if (comment.comments) {
        const result = findCommentPath(comment.comments, targetId, newPath);
        if (result) return result;
      }
    }
    return null;
  };

  const thread = findCommentPath(comments, targetCommentId);
  return thread || [];
}
