import { Box, Select, MenuItem, FormControl, InputLabel, Button, Modal, TextField, Dialog, DialogContent, DialogActions, DialogTitle, Toolbar, Typography, CircularProgress } from '@mui/material';
import background from '../../assets/background3.png';
import backgroundMobile from '../../assets/background3.png';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, useNavigate } from 'react-router-dom';
import { ReactNode } from 'react';
import logo from '../../assets/logo.png';
import { addLogsToDatabase } from '../../utils/webServices';
import { LOGSTYPE } from '../../utils/constant';

const LoginPage = () => {
    const navigate = useNavigate();

    const Styles = {
        backgroundStyle: {
            height: `100vh`,
            width: '100%',
            backgroundRepeat: 'repeat',
            backgroundImage: { xs: `url(${backgroundMobile})`, sm: `url(${background})` },
            backgroundSize: 'cover',
            backgroundAttachment: 'fixed'
        },
        HeaderText: {
            pt: '5vh',
            textAlign: 'center',
            color: 'white'
        },
        navButton: {
            background: 'black',
            m: '5px',

            color: '#FFF',
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
            },
        }
    }

    const { loginWithRedirect } = useAuth0();
    const {user, isAuthenticated, isLoading } = useAuth0();
    
    const loadingScreen = () => {
        return <Box sx={Styles.backgroundStyle} >
            <Box sx={{
                height: `100vh`,
                backgroundColor: 'rgba(32, 5, 240, 0.65)',
                width: '100%',
            }}>
                <Toolbar>
                    <Typography variant="h6" sx={{
                        width: '100px',
                        flexGrow: 1,
                        fontWeight: 'bold',
                        color: '#FFF'
                    }}>
                        <img style={{ width: '200px' }} src={`${logo}`}></img>
                    </Typography>
                </Toolbar>
                <Box sx={Styles.HeaderText}>
                    <h1>
                        Logging You In ...
                    </h1>
                <CircularProgress size={55}/>
                </Box>
            </Box>
        </Box>
    }
    
    if(isAuthenticated) {
        addLogsToDatabase({user:user}, LOGSTYPE.ENTER)
        return <Navigate to="/feed" />;
    }

    if (isLoading) {
        return (
            loadingScreen()
        );
    }
    
    return (
        <Box sx={Styles.backgroundStyle}>
            <Box sx={{
                height: `100vh`,
                backgroundColor: 'rgba(32, 5, 240, 0.65)',
                width: '100%',
            }}>
                <Toolbar>
                    <Typography variant="h6" sx={{
                        width: '100px',
                        flexGrow: 1,
                        fontWeight: 'bold',
                        color: '#FFF'
                    }}>
                        <img style={{ width: '200px' }} src={`${logo}`}></img>
                    </Typography>
                </Toolbar>
                <Box sx={Styles.HeaderText}>
                    <h1>
                        Fight Back For Israel
                    </h1>
                    <Box sx={{ color: { sm: "white" } }}>
                        <h2>
                            Use our AI to create the best response
                        </h2>
                    </Box>
                    <Box sx={{ mt: '10vh' }}>
                        <Button variant='contained' size='large' onClick={() => {
                            loginWithRedirect()
                            }} sx={Styles.navButton}>
                            Sign In
                        </Button>
                        <Button  onClick = {()=> {navigate("/About")}} size='large' sx={Styles.navButton}>
                            About
                        </Button>
                    </Box>

                </Box>
            </Box>
        </Box>
    );
}


export const LogoutButton = () => {
    const { logout } = useAuth0();

    return (
        <Button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
            Log Out
        </Button>
    );
};

export default LoginPage;