import React from 'react';
import { Post } from '../../utils/interfaces'; // Import your Post interface
import FeedResponseCard from './FeedResponseCard'; // Adjust the import path as necessary
import { Grid } from '@mui/material';

interface PostGridProps {
    combinedPosts: Post[];
    isMobile: boolean;
    isLoading: boolean;
    hasMore: boolean;
    lastPostElementRef: (node: HTMLDivElement) => void;
    handleActionClickToggle: (open: boolean, postId: number) => void;
    clickedAction: boolean;
    activePostId: number;

}

const PostGrid: React.FC<PostGridProps> = ({
    combinedPosts,
    isMobile,
    isLoading,
    hasMore,
    lastPostElementRef,
    handleActionClickToggle,
    clickedAction,
    activePostId
}) => {
    return (
        <Grid container spacing={2}>
            {combinedPosts.map((post, index) => {
                const isLastPost = combinedPosts.length === index + 1;
                return (
                    <Grid
                        ref={isLastPost ? lastPostElementRef : null}
                        key={post.id}
                        xs={12} sm={6} md={4}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <FeedResponseCard
                            isIsrael={post.proIsrael ?? false} 
                            isMobile={isMobile}
                            post={post}
                            onActionClick={handleActionClickToggle}
                            blur={clickedAction && post.id !== activePostId} />
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default PostGrid;
