
enum OpenAIModels {
    GPT4 = 'gpt-4-turbo-2024-04-09',   // Context window of 128,000 tokens. 
    GPT35 = 'gpt-3.5-turbo-0125'       // Context window of 16,385 tokens. Returns a maximum of 4,096 output tokens.
}

enum AgentFields {
    AgentName = 'agent_name',
    AgentDescription = 'agent_description',
    AgentDomain = 'agent_domain',
    PlatformName = 'platform_name',
    Model = 'model',
    CampaignId = 'campaign_id',
    AgentId = 'agent_id',
    AgentCreatorId = 'agent_creator_id'
}


export interface LLMGenerateRequest {
    userId: string;
    campaignId: string;
    agentId: string;
    threadId: string;
    responseParameters: ResponseParameters;
    userBucketName?: string;
    campaignBucketName?: string;
    agentBucketName?: string;
    threadBucketName?: string;
    openaiKeyPath?: string;
    targetId?: string;
    provided_thread?: Thread
}

export interface RawAgent {
    /**
     * Represents an agent within a campaign, detailing its characteristics, purpose,
     * and the model it's based on. Agents are key actors in campaigns, executing tasks,
     * interacting with users, or providing information based on their domain of expertise.
     */
    agentName: string;  // The name of the agent, serving as a unique identifier within a campaign.
    model: string;  // The name of the model on which the agent is based (e.g., 'gpt-3.5-turbo' or 'gpt-4-turbo').
    agentDescription?: string;  // A detailed description of the agent's purpose and capabilities.
    agentDomain?: string;  // The domain or area of expertise of the agent.
    platformName?: string;  // The social media platform the agent operates on (e.g., 'Twitter' or 'Facebook').
    agentTools?: string[];  // A list of tool names, representing the tools the agent can use to accomplish its goals.
    agentGuidelines?: string;  // A set of guidelines or rules that the agent follows when interacting with users or other agents.
}

export interface Agent extends RawAgent {
    /**
     * Represents an agent within a campaign, detailing its characteristics, purpose,
     * and the model it's based on. Agents are key actors in campaigns, executing tasks,
     * interacting with users, or providing information based on their domain of expertise.
     */
    campaignId: string;  // The unique identifier of the campaign this agent is associated with, linking it to its operational context.
    agentId: string;  // The unique identifier of the agent, generated by default using UUID4.
    agentCreatorId: string;  // The unique identifier of the agent's creator.
}

enum CampaignFields {
    CampaignName = 'campaign_name',
    WorldSetup = 'world_setup',
    ReasonForCampaignExistence = 'reason_for_campaign_existence',
    CampaignStrengths = 'campaign_strengths',
    CampaignWeaknesses = 'campaign_weaknesses',
    CampaignOpportunities = 'campaign_opportunities',
    CampaignThreats = 'campaign_threats',
    CampaignGuidelines = 'campaign_guidelines',
    TargetAudience = 'target_audience',
    CampaignCreatorId = 'campaign_creator_id',
    CampaignId = 'campaign_id'
}

export interface RawCampaign {
    campaignName: string;  // The official name of the campaign.
    worldSetup?: string;  // Describes the setting or world in which the campaign takes place.
    reasonForCampaignExistence?: string;  // The rationale behind the campaign's initiation.
    campaignGuidelines?: string;  // Specifies any guidelines or rules that should be followed within the campaign.
    targetAudience?: string;  // Defines the primary audience or demographic targeted by the campaign.
    campaignStrengths?: string;  // Describes the strengths of the campaign.
    campaignWeaknesses?: string;  // Describes the weaknesses of the campaign.
    campaignOpportunities?: string;  // Outlines the opportunities available to the campaign.
    campaignThreats?: string;  // Outlines potential threats to the campaign's success.
}

export interface Campaign extends RawCampaign {
    campaignCreatorId: string;  // The unique identifier of the user who created the campaign.
    campaignId: string;  // A unique identifier for the campaign itself.
}

interface Video {
    videoId: string;
    videoUrl: string;
    // Define additional video attributes as needed.
}

interface Image {
    imageId: string;
    imageUrl: string;
    // Define additional image attributes as needed.
}

interface Video {
    videoId: string;
    videoUrl: string;
    // Define additional video attributes as needed.
}

interface Image {
    imageId: string;
    imageUrl: string;
    // Define additional image attributes as needed.
}



export interface Organization {
    organizationId: string;  // A unique identifier for the organization, generated by default using UUID4.
    organizationName: string;  // The name of the organization.
}

export enum Audience {
    PROGRESSIVE = 'progressive',
    MODERATE = 'moderate',
    CONSERVATIVE = 'conservative'
}

export enum Length {
    ONE_SENTENCE = 'one sentence',
    TWO_SENTENCES = 'two sentences',
    FEW_SENTENCES = 'a few sentences',
    ONE_PARAGRAPH = 'one paragraph',
    FEW_PARAGRAPHS = 'a few paragraphs'
}

export enum Tone {
    LOGICAL = 'logical',
    AGGRESSIVE = 'aggressive',
    SARCASTIC = 'sarcastic',
    EMPATHETIC = 'empathetic',
    DRAMATIC = 'dramatic'
}

export enum Formality {
    FORMAL = 'formal',
    CASUAL = 'casual',
    SLANG = 'slang'
}

export interface ResponseParameters {
    userInstructions?: string;  // Instructions provided by the user for the response.
    length?: Length;  // Desired length of the response.
    tone?: Tone;  // Desired tone of the response.
    formality?: Formality;  // Desired level of formality for the response.
    targetAudiencePolitics?: Audience;  // The political audience the response is targeted at.
    audienceDescription?: string;  // Description of the target audience.
}

export interface Message {
    role: string;  // The role of the entity that produced the message, such as "system", "user", or "assistant".
    name: string;  // The name of the sender.
    content: string;  // The actual text content of the message.
    messageId?: string;  // Unique identifier for the message, defaults to a UUID4 if not provided.
    processedId?: string;  // Another unique identifier for processing, defaults to a UUID4.
    messageVideoContent?: Video[];  // Optional list of videos associated with the message.
    messageImageContent?: Image[];  // Optional list of images associated with the message.
    parentIds?: string[];  // Identifiers of parent messages, if any.
    childrenIds?: string[];  // Identifiers of children messages, if any.
    url?: string;  // Optional URL related to the message.
    authorUsername?: string;  // Optional username of the author.
    timePosted?: string;  // The time when the message was posted.
    lastUpdated?: string;  // The last time the message was updated.
    likes?: [string, number][];  // List of likes the message has received, with identifiers and counts.
    shares?: [string, number][];  // List of shares the message has received, with identifiers and counts.
    views?: [string, number][];  // List of views the message has received, with identifiers and counts.
}

export interface RawThread {
    messages: Message[];  // A list of `Message` objects that belong to the thread.
}

export interface Thread extends RawThread {
    threadId: string;  // A unique identifier for the conversation thread, typically generated to ensure uniqueness across the system.
}

export interface User {
    userId: string;  // A unique identifier for the user, generated by default using UUID4.
    userName: string;  // The name of the user. This could be a full name, a nickname, or any identifier chosen by the user.
    userDescription?: string;  // A description of the user.
    userStances?: string;  // A collection of stances or positions that the user holds.
}


