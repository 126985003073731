import React, { useState } from 'react';
import { Box, Slider, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { AnswerCardStyles } from "../Feed/FeedStyle"; // Update path as necessary
import { slidersValue } from '../../utils/interfaces';

// export interface slidersValue {
//     toneValue: number,
//     lengthValue: number,
//     languageStyleValue: number,
//     communicationToneValue: number
// }

interface SliderControlsProps {
    sliderValue: slidersValue;
    setSliderValue: (value: slidersValue) => void,
    isLoading: boolean;
}

const SliderControls: React.FC<SliderControlsProps> = ({ sliderValue, setSliderValue, isLoading }: SliderControlsProps) => {
    const handleSliderChange = (value: number, name: keyof slidersValue) => {
        setSliderValue({ ...sliderValue, [name]: value });
    }
    
    const handleToggleChange = (event: React.MouseEvent<HTMLElement>, newValue: number, name: keyof slidersValue) => {
        if (newValue !== null) {
            setSliderValue({ ...sliderValue, [name]: newValue });
        }
    };

    function valueLabelFormatLength(value: number) {
        const units = ['Very Short', 'Short', 'Medium', 'Long', 'Very Long'];
        return `${units[value - 1]}`;
    }

    function valueLabelFormatTone(value: number) {
        const units = ['Liberal', 'Lite Liberal', 'Balanced', 'Lite Conservative', 'Conservative'];
        return `${units[value - 1]}`;
    }

    const LengthMarks = [
        {
            value: 1,
            label: 'Very Short',
        },
        {
            value: 2,
            label: '',
        },
        {
            value: 3,
            label: '',
        },
        {
            value: 4,
            label: '',
        },
        {
            value: 5,
            label: 'Long',
        }
    ];

    const IdeologicalToneMarks = [
        {
            value: 1,
            label: 'Liberal',
        },
        {
            value: 2,
            label: '',
        },
        {
            value: 3,
            label: '',
        },
        {
            value: 4,
            label: '',
        },
        {
            value: 5,
            label: 'Conservative',
        }
    ];

    const classes = AnswerCardStyles;

    return (
        <Box sx={{
            display: "flex", justifyContent: "space-around",
            flexDirection: "column",
            p: '10px', alignItems: "center"
        }}>
            <Box></Box>
            {/* needed for proper function of the slider. no IDEA why */}
            <Box sx={{ width: '80%' }}>
                <Box sx={{ fontSize: '14px' }}>
                    Length
                </Box>

                <Slider
                    valueLabelFormat={valueLabelFormatLength}
                    sx={(theme) => ({
                        '& .MuiSlider-markLabel': {
                            ...classes.sliderMarkStyle,
                            fontSize: theme.typography.pxToRem(10),
                        },
                    })}
                    valueLabelDisplay="auto"
                    aria-label="Custom marks"
                    value={sliderValue.lengthValue}
                    min={1}
                    max={5}
                    step={1}
                    marks={LengthMarks}
                    size="small"
                    disabled={isLoading}
                    aria-labelledby="non-linear-slider"
                    onChange={(e, newValue) => {
                        if (typeof newValue === 'number') {
                            handleSliderChange(newValue as number, 'lengthValue')
                        }
                    }}
                />
            </Box>

            <Box sx={{ width: '80%' }}>
                <Box sx={{ fontSize: '14px' }}>
                    Communication Tone
                </Box>

                <ToggleButtonGroup
                    value={sliderValue.communicationToneValue}
                    exclusive
                    onChange={(e, newValue) => handleToggleChange(e, newValue, 'communicationToneValue')}
                    aria-label="communication tone"
                    size="small"
                    disabled={isLoading}
                >
                    <ToggleButton value={1} aria-label="logical">
                        Logical
                    </ToggleButton>
                    <ToggleButton value={2} aria-label="aggressive">
                        Aggressive
                    </ToggleButton>
                    <ToggleButton value={3} aria-label="sarcastic">
                        Sarcastic
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>

            <Box sx={{ width: '80%' }}>
                <Box sx={{ fontSize: '14px' }}>
                    Language Style
                </Box>

                <ToggleButtonGroup
                    value={sliderValue.languageStyleValue}
                    exclusive
                    onChange={(e, newValue) => handleToggleChange(e, newValue, 'languageStyleValue')}
                    aria-label="language style"
                    size="small"
                    disabled={isLoading}
                >
                    <ToggleButton value={1} aria-label="formal">
                        Formal
                    </ToggleButton>
                    <ToggleButton value={2} aria-label="casual">
                        Casual
                    </ToggleButton>
                    <ToggleButton value={3} aria-label="slang">
                        Slang
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>

            <Box sx={{ width: '80%' }}>
                <Box sx={{ fontSize: '14px' }}>
                    Ideological Tone
                </Box>

                <Slider
                    size="small"
                    value={sliderValue.toneValue}
                    valueLabelFormat={valueLabelFormatTone}
                    valueLabelDisplay="auto"
                    sx={(theme) => ({
                        '& .MuiSlider-markLabel': {
                            ...classes.sliderMarkStyle,
                            fontSize: theme.typography.pxToRem(10),
                        },
                    })}
                    aria-label="Custom marks"
                    defaultValue={2}
                    disabled={isLoading}
                    min={1}
                    max={5}
                    step={1}
                    marks={IdeologicalToneMarks}
                    onChangeCommitted={(e, newValue) => {
                        if (typeof newValue === 'number') {
                            handleSliderChange(newValue as number, 'toneValue')
                        }
                    }}
                />
            </Box>
        </Box>
    );
};

export default SliderControls;
