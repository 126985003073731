import React, { ReactNode } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, useLocation } from 'react-router-dom';
import background from '../../assets/background3.png';
import logo from '../../assets/logo.png';

import { Box, CircularProgress, Toolbar, Typography } from '@mui/material';
interface props {
    children : ReactNode
}

const ProtectedRoute : React.FC<props> = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();
  const Styles = {
    backgroundStyle: {
        height: `100vh`,
        width: '100%',
        backgroundRepeat: 'repeat',
        backgroundImage: `url(${background})` ,
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed'
    },
    HeaderText: {
        pt: '5vh',
        textAlign: 'center',
        color: 'white'
    },
    navButton: {
        background: 'black',
        m: '5px',

        color: '#FFF',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
        },
    }
}
  const loadingScreen = ()=>{
    return <Box sx={Styles.backgroundStyle} >
        <Box sx={{
        height: `100vh`,
        backgroundColor: 'rgba(32, 5, 240, 0.3)',
        width: '100%',
    }}>
                    <Toolbar>
                    <Typography variant="h6" sx={{
                        width: '100px',
                        flexGrow: 1,
                        fontWeight: 'bold',
                        color: '#FFF'
                    }}>
                        <img style={{ width: '200px' }} src={`${logo}`}></img>
                    </Typography>
                </Toolbar>
        <Box sx={Styles.HeaderText}>
            <h1>
                Logging You In ...
            </h1>
            <CircularProgress size={55}/>
        </Box>
        </Box>
    </Box>
}

  if (isLoading) {
    return (
      loadingScreen()
      ); 
}

  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};
export default ProtectedRoute