import { Platforms, Post, dataBaseUrlParam } from "./interfaces";
import { DATABASE_BASEURL, POSTS_DB, POSTS_DB_CONTAINER, post_to_show } from "./constant";
import { shuffleArray } from "./PostCardHelperFunctions";

interface RequestPostItem {
    id: string;
    url: string;
    likes: number;
    image: string;
    date: string;
    video: string;
    shares: number;
    proIsrael: boolean;
    text: string;
    Description: string;
    userAdded: boolean;
    answeredBy: string[];
    bookmarkBy: string[]

}


// constructQuery: Creates a SQL query for fetching posts. It tailors the query based on user email, a default query, and a flag to include posts not answered.
function constructQuery(userMail: string | undefined, defaultQuery: string = "", isDefault = true): string {

    if (isDefault) {
        if (defaultQuery) {
            return `SELECT * FROM c WHERE ${defaultQuery} AND (NOT IS_DEFINED(c.answeredBy) OR NOT ARRAY_CONTAINS(c.answeredBy, '${userMail}'))`
        }

        if (userMail) {
            return `SELECT * FROM c WHERE (NOT IS_DEFINED(c.answeredBy) OR (IS_DEFINED(c.answeredBy) AND NOT ARRAY_CONTAINS(c.answeredBy, '${userMail}'))`;
        }
        return "";
    }

    console.log(`request non-default query `, defaultQuery)
    return defaultQuery
}
// fetchPosts: Retrieves posts from the database using provided container and database names, user email, and a custom SQL query. It handles the API call and response parsing.
export async function fetchPosts(dataBaseUrlParam: dataBaseUrlParam, userMail: string | undefined, query = "", isDefault = true) {
    const effectiveQuery = constructQuery(userMail, query, isDefault);

    const baseUrl = `${DATABASE_BASEURL}/get_posts?container=${dataBaseUrlParam.container}&database=${dataBaseUrlParam.database}&query=${effectiveQuery}`;
    console.log(`fetchPosts called for dataBaseUrlParam.container: ${dataBaseUrlParam.container}, database: ${dataBaseUrlParam.database}, with query ${effectiveQuery}`);

    try {
        const response = await fetch(`${baseUrl}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log(`Data fetched for ${dataBaseUrlParam.container}: `, data);
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};


// processPosts: insert the posts into Post type 
async function processPosts(dataBaseUrlParam: dataBaseUrlParam, userMail: string | undefined, query = "", isDefault = true) {
    try {
        console.log('processPosts called');

        const PostDBResponse = isDefault ?
            await fetchPosts(dataBaseUrlParam, userMail, query, isDefault) :
            await fetchPosts(dataBaseUrlParam, userMail, query, false)

        console.log('ProcessPosts : Responses received from fetchPosts:', { PostDBResponse });

        const posts: Post[] = PostDBResponse.map((item: RequestPostItem) => ({
            id: parseInt(item.id),
            url: item.url,
            likes: item.likes,
            image_url: item.image ?? 'not found',
            date: item.date ?? 'not found',
            video_url: item.video ?? 'not found',
            shares: item.shares ?? -1,
            proIsrael: item.proIsrael ?? false,
            text: item.text,
            img_description: item.Description ?? "",
            userAdded: item.userAdded ?? false,
            answeredBy: item.answeredBy ?? [],
            bookmarkBy: item.bookmarkBy ?? []
        }));

        return posts;

    } catch (error) {
        console.error('Error in processPosts:', error);
        return [];
        // Handle error or return an appropriate value
    }
}


// calculateVirality: Assigns a virality score to each post based on a formula that factors in likes and shares, then normalizes these scores across all posts.
function calculateVirality(posts: Post[]): Post[] {
    const LikeWeight = 1; // Adjust as needed
    const ShareWeight = 1; // Adjust as needed

    posts.forEach(post => {
        post.viralityScore = (post.likes ? post.likes : 0 * LikeWeight) + (post.shares ? post.shares : 0 * ShareWeight)
    })

    const maxScore = Math.max(...posts.map(p => p.viralityScore ? p.viralityScore : 0));
    const minScore = Math.min(...posts.map(p => p.viralityScore ? p.viralityScore : 0));

    posts.forEach(post => {
        post.viralityScore = (post.viralityScore ? post.viralityScore : 0 - minScore) / (maxScore - minScore)
    });

    return posts
}

// initializePosts: Organizes and combines user posts with other posts, initializing the post feed, calcualte the relative virality
export async function initializePosts(dataBaseUrlParam: dataBaseUrlParam, userMail: string | undefined, query = "", isDefault = true) {
    try {
        if (isDefault) {
            const withoutUserPosts = await processPosts(dataBaseUrlParam, userMail, query = `NOT IS_DEFINED(c.userAdded)`);
            const userPosts = await processPosts(dataBaseUrlParam, userMail, query = `c.userAdded`);
            console.log('User posts and non-user posts separated:', { userPosts, withoutUserPosts });
            return shuffleArray(calculateVirality([...userPosts, ...withoutUserPosts]))
         
        }
        if(dataBaseUrlParam.query)
        {
            const posts = await processPosts(dataBaseUrlParam, userMail, dataBaseUrlParam.query, false);
            return posts
        }
        const posts = await processPosts(dataBaseUrlParam, userMail, query, false);
        console.log(`non - defualt query, the query is ${query}. posts fetched: `, calculateVirality(posts))
        return shuffleArray(calculateVirality(posts));
   
    } catch (error) {
        console.error('Error fetching posts:', error);
        return [];
    }
}
