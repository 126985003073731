import { useEffect } from 'react';

const useFacebookSdk = (appId: string) => {
  useEffect(() => {
    if (window.document.getElementById('facebook-jssdk')) {
      
      return;
    }

    window.fbAsyncInit = function() {
      window.FB.init({
        appId,
        cookie: true,
        xfbml: true,
        version: 'v14.0' 
      });

      console.log('Facebook SDK initialized');
      window.FB.AppEvents.logPageView();
    };

    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id) || !fjs.parentNode) {
        return;
      }
      js = d.createElement(s) as HTMLScriptElement; // Correct type assertion here
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, [appId]); 
};

export default useFacebookSdk;
