import React, { useEffect, useRef, useState } from "react";
import { FacebookPost, Platforms } from "../../utils/interfaces";
import { Box, Card, Stack, Skeleton, Typography, Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { recognizePlatform } from "../../utils/postUtils";
import {
  getUrlForEmbbeding,
  buildLoadScript,
} from "../../utils/PostEmbeddingHelperFunctions";

interface Prop {
  post?: FacebookPost;
  url: string;
  isAlone?: boolean;
}

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 500,
  margin: theme.spacing(2),
  padding: theme.spacing(2),
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

const PostHeader = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}));

const PostContent = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const PostMeta = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: theme.spacing(2),
}));

const PostLink = styled("a")(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
}));

const PostEmbbdingComponent = ({ url, isAlone = false, post }: Prop) => {
  const uniqueIdRef = useRef(
    `post-embed-${Math.random().toString(36).substr(2, 9)}`
  );
  const [loading, setLoading] = useState(true);
  const [embedFailed, setEmbedFailed] = useState(false);
  const socialPlatform = recognizePlatform(url);

  url = getUrlForEmbbeding(url);

  useEffect(() => {
    const script = buildLoadScript(socialPlatform);
    document.body.appendChild(script);
    if (socialPlatform === Platforms.facebook) {
      if ((window as any).FB) {
        (window as any).FB.XFBML.parse();
      } else {
        (window as any).fbAsyncInit = function () {
          (window as any).FB.init({
            xfbml: true,
            version: "v3.2",
          });
        };
      }
    }

    const timeout = setTimeout(() => {
      if (loading) {
        setEmbedFailed(true);
        setLoading(false);
      }
    }, 15000); // 10 seconds timeout

    return () => {
      clearTimeout(timeout);
      document.body.removeChild(script);
    };
  }, [socialPlatform, loading]);

  useEffect(() => {
    const interval = setInterval(() => {
      const uniqueDiv =
        document.getElementById(uniqueIdRef.current) || document;
      let iframe;

      switch (socialPlatform) {
        case Platforms.twitter:
          iframe = uniqueDiv.querySelector(".twitter-tweet");
          break;
        case Platforms.instagram:
          iframe = uniqueDiv.querySelector(".instagram-media-rendered");
          break;
        default:
          iframe = uniqueDiv.querySelector(".instagram-media-rendered");
          break;
      }

      if (iframe) {
        setLoading(false);
        clearInterval(interval);
      }
    }, 13000);

    return () => clearInterval(interval);
  }, [socialPlatform]);

  const renderEmbed = () => {
    switch (socialPlatform) {
      case Platforms.twitter:
        return (
          <div>
            <blockquote
              className="twitter-tweet"
              id={`twitter-embed-${uniqueIdRef.current}`}
            >
              <a
                id={`twitter-embed-${uniqueIdRef.current}`}
                href={url.replace("https://x.com/", "https://twitter.com/")}
              ></a>
            </blockquote>
          </div>
        );
      case Platforms.instagram:
        return (
          <div>
            <blockquote
              data-instgrm-captioned
              className="instagram-media"
              data-instgrm-permalink={url}
              style={{ minWidth: "1px", width: "100%" }}
            ></blockquote>
          </div>
        );
      case Platforms.facebook:
        return (
          <Box overflow="hidden">
            <iframe
              src={`https://www.facebook.com/plugins/post.php?href=${encodeURIComponent(
                url
              )}&show_text=true&width=500`}
              width="100%"
              height="400px"
              frameBorder="0"
              allowFullScreen={true}
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              onLoad={() => setLoading(false)}
            ></iframe>
          </Box>
        );
      default:
        return <div>Unsupported platform</div>;
    }
  };

  const renderFallbackPost = () => {
    if (!post) return <Typography>Post data unavailable</Typography>;

    return (
      <StyledCard>
        <PostHeader spacing={2} direction="row" alignItems="center">
          <StyledAvatar>{post.id.charAt(0).toUpperCase()}</StyledAvatar>
          <Typography variant="h6">Post ID: {post.id}</Typography>
        </PostHeader>
        <PostContent variant="body1">{post.message}</PostContent>
        <PostMeta>
          <Typography variant="caption" color="text.secondary">
            Comments: {post.commentsCount}
          </Typography>
          {post.topic && (
            <Typography variant="caption" color="text.secondary">
              Topic: {post.topic}
            </Typography>
          )}
        </PostMeta>
        <Box mt={2}>
          <PostLink href={post.url} target="_blank" rel="noopener noreferrer">
            View original post
          </PostLink>
        </Box>
      </StyledCard>
    );
  };

  return (
    <div id={uniqueIdRef.current}>
      {embedFailed ? renderFallbackPost() : renderEmbed()}
      {loading && (
        <Stack spacing={1}>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem", bgcolor: "grey.400" }}
          />
          <Skeleton
            sx={{ bgcolor: "grey.400" }}
            variant="circular"
            width={40}
            height={40}
          />
          <Skeleton
            sx={{ bgcolor: "grey.400" }}
            variant="rectangular"
            width={"100%"}
            height={120}
          />
          <Skeleton
            sx={{ bgcolor: "grey.400" }}
            variant="rounded"
            width={"100%"}
            height={120}
          />
        </Stack>
      )}
    </div>
  );
};

const PostEmbbding = React.memo(PostEmbbdingComponent);
export default PostEmbbding;
