import { LanguageCodes } from "./constant";
import { Platforms } from "./interfaces";

export const buildSentText = () => {
    const sent_text = document.createElement('div');
    sent_text.className = 'sent-text';
    sent_text.textContent = 'Sent!';
    return sent_text
}

export const DesignViralChip = (Virality: string)=> {
    switch (Virality) {
        case 'High Virality':
            return ['#2072FF', '#E9F1FF']
        case 'Viral':
            return ['black', '#F1F1F1']
        case 'Extreme Virality':
            return ['black', '#FFBDBD']
        default:
            return ['black', '#F1F1F1']
    }
}
export const DesignIsraelChip = (isPro: boolean | undefined)=> {
    if(isPro == true){
        return ['#31AD6F', '#EDFFE9']
    }
    return ['#A10F16', '#FFE9E9']

}
export const handleMaxRow = ()=>{
    if (window.innerWidth < 760){
        return 5;
    }
    else if (window.innerWidth < 1460)
    {
        return 5;
    }
    else if (window.innerWidth < 1900) {
        return 7;
    }
    else {return 10;}
}
export const printVirality = (score: number): string[] => {
    if (score > 0 && score < 0.3) {
        return ["Viral", '#FDA3A3']
    }
    if (score > 0.3 && score < 0.7) {
        return ["High Virality", '#FF6666']
    }
    if (score > 0.7) {
        return ["Extreme Virality", '#FE1111']
    }
    return ["Viral", '#FDA3A3']

}
export function getLanguageCode(languageName: string) {
    for (const [code, name] of Object.entries(LanguageCodes)) {
      if (name === languageName) {
        return code;
      }
    }
    return 'not found';
}
export const fixInstagramIframe = () => {
    setInterval(() => {
        Array.from(document.getElementsByClassName("instagram-media instagram-media-rendered")).forEach((element) => {
            // Assert that the element is an HTMLElement before accessing the style property
            if (element instanceof HTMLElement) {
                element.style.minWidth = "";
            }
        });
    }, 2000);
}
export const capitalizeFirstLetter = (str: string): string => {
    return str.slice(0, 1).toUpperCase() + str.slice(1);
}

export const recognizePlatform = (url: string) => {
    if (url.includes(Platforms.instagram)) {
        return Platforms.instagram;
    }
    if (url.includes(Platforms.facebook)) {
        return Platforms.facebook;
    }
    if (url.includes(Platforms.twitter)) {
        return Platforms.twitter;
    }
    if (url.includes(Platforms.x_com)) {
        return Platforms.twitter;
    }
    return 'not found';
}
export const CalculateDivisionFactor = (width: number) => {
    if (width < 600) {
      return 1;

    } else if (width < 960) { 
      return 2;

    } else {
     
      return 3;}
  };
export const StringToBool = ( str : string) =>{
    if (str == 'True') {return true}
    return false
}