import { AWS_SERVER_URL } from "../CampaignConst";
import { LLMGenerateRequest } from "../CampaignInterface";
import { camelToSnake, snakeToCamel } from "./UsersService";

interface LLMGenerateResponse {
    content: string;
    responseMetadata: Record<string, any>;
}

export async function generateResponse(request: LLMGenerateRequest): Promise<LLMGenerateResponse> {
    const lambdaUrl = 'https://fimnw3ne3y2v7n2b2cmxvdgeve0amsvh.lambda-url.us-east-1.on.aws/'
    const snakeCaseRequest = camelToSnake(request);
    console.log(snakeCaseRequest);
    const response = await fetch(lambdaUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(snakeCaseRequest)
    });

    if (!response.ok) {
        throw new Error(`Error generating response: ${response.statusText}, ${response}`);
    }
    
    return snakeToCamel(response.json());
}
