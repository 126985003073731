import { Box } from "@mui/material"
import './RespondPageStyle.css';
import { useState } from "react";
import { Post } from "../../utils/interfaces";
import FeedPostCard from "../PostsCard/FeedPostCard";
import FeedAnswerCard from "../AnswerCard/FeedAnswerCard";
interface Props {
    post: Post,
    onActionClick: (state: boolean, id: number) => void,
    blur: boolean,
    isMobile: boolean
    isIsrael: boolean
}

const FeedResponseCard = ({isIsrael, blur,isMobile, post, onActionClick }: Props) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const handleFlip = () => setIsFlipped(prev => !prev);
    return (
        <>
            <Box display={isFlipped ?  'none': ''}>
                <FeedPostCard isIsrael={isIsrael} isMobile = {isMobile}  post={post} handleFlip={handleFlip} blur={blur || isFlipped} onDialogOpen={onActionClick} />
            </Box>
            <Box display={isFlipped ? '' : 'none'}>
                <FeedAnswerCard isMobile = {isMobile} isOpen = {isFlipped} post={post} handleFlip={handleFlip} onDialogOpen={onActionClick} />
            </Box>
        </>
    );
}
export default FeedResponseCard;
