import React, { useState, ChangeEvent, useCallback, useEffect } from 'react';
import { TextField, Button, Typography, Container, Box, CircularProgress, Paper, List, ListItem, ListItemText, ListItemAvatar, Avatar, Grid, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, MenuList } from '@mui/material';

import AssistantIcon from '@mui/icons-material/Assistant';
import PersonIcon from '@mui/icons-material/Person';
interface ResponseItem {
    id: string;
    created_at: number;
    role: string;
    content: string;
}
interface AssistantItem {
    id: string;
    name: string;
    description?: string | null;
}

const GPTAssistant = () => {
    const [lastInput, setLastInput] = useState<string>('');
    const [inputs, setInputs] = useState<string[]>([]);
    const [assistantName, setAssistantName] = useState<string>();
    const [assistant, setAssistant] = useState<AssistantItem[]>([]);

    const [response, setResponse] = useState<ResponseItem[]>([]);
    const [currentInput, setCurrentInput] = useState<string>('');
    const [currentInstruction, setCurrentInstruction] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const fetchAvailbleAssistants = async () => {
        const response = await fetch('https://demo-gpt-assistant.azurewebsites.net/api/assistant-getlist?code=pxDoZCDA-6lMyMF-H-Q2Kus7RX7KnkRuzKsZZjc-yEoeAzFuX-mtRA%3D%3D', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data: AssistantItem[] = await response.json();
        console.log(data)
        setAssistant(data)
    }

    useEffect(() => {
        fetchAvailbleAssistants()
    }, [])


    const handleInstructionsChange = useCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setCurrentInstruction(e.target.value);
    }, []);

    const handleInputChange = useCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setCurrentInput(e.target.value);
    }, []);
    const handleCreateNewAssistant = () => {
        // Logic to handle the creation of a new assistant
        console.log("Create new assistant logic goes here");
        // This could involve opening a dialog to input details for the new assistant
        // or navigating to a different screen or component where this can be handled.
    };

    const handleAssistantChange = (event: SelectChangeEvent<string>) => {
        const value = event.target.value as string;
        if (value === "create-new") {
            setAssistantName("");
        } else {
            setAssistantName(value);
        }
    };

    const handleAddInput = () => {
        console.log('added comment',)
        if (lastInput != '') { setInputs(prevInputs => [...prevInputs, lastInput]) };
        setLastInput(currentInput);
        setCurrentInput('')
    };

    const handleSubmit = useCallback(async () => {
        setIsLoading(true);
        const requestBody = {
            assistant_name: assistantName,
            previous_comments: inputs,
            target_comment: lastInput,
            instructions: currentInstruction
        };
        console.log(requestBody)
        try {
            const response = await fetch('https://demo-gpt-assistant.azurewebsites.net/api/gptassistant?code=oM7ZxHj-hJu42AX3QWX6onyLZl98Xmc2x6q4j7VBPAQCAzFu4QKWcQ%3D%3D', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            console.log(data)
            setResponse(data);
        } catch (error) {
            console.error('Error sending data to the server:', error);
        } finally {
            setIsLoading(false);
        }
    }, [inputs, currentInput, currentInstruction]);

    function extractValue(content: string): string {
        const valueRegex = /value='([^']+)'/;
        let match = content.match(valueRegex);
        if (!match) {
            match = content.match(/value="([^']+)"/)
        }
        return match ? match[1] : content;
    }

    const renderResponse = () => {
        if (response) {
            return (
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {response.map((item: ResponseItem) => (
                        <ListItem alignItems="flex-start" key={item.id}>
                            <ListItemAvatar>
                                <Avatar>
                                    {item.role === 'assistant' ? <AssistantIcon /> : <PersonIcon />}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={`[${item.role.toUpperCase()}] ${new Date(item.created_at * 1000).toLocaleString()}`}
                                secondary={extractValue(item.content)}
                            />
                        </ListItem>
                    ))}
                </List>
            );
        }
        return null;
    };
    return (
        <Container maxWidth="md">
            <Typography variant="h4" gutterBottom>
                GPT Assistant
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="assistant-select-label">Assistant</InputLabel>
                        <Select
                            labelId="assistant-select-label"
                            id="assistant-select"
                            value={assistantName}
                            label="Assistant"
                            onChange={handleAssistantChange}
                        >
                            {assistant.map((assistantItem) => (
                                <MenuItem key={assistantItem.id} value={assistantItem.id}>
                                    {assistantItem.name}
                                </MenuItem>
                            ))}
                             <MenuItem value="create-new">Create New Assistant</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        multiline
                        value={currentInstruction}
                        onChange={handleInstructionsChange}
                        placeholder="Write your Instructions"
                        variant="outlined"
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        multiline
                        value={currentInput}
                        onChange={handleInputChange}
                        placeholder="Write your comment"
                        variant="outlined"
                        margin="normal"
                    />
                    <Button variant="contained" color="primary" onClick={handleAddInput} style={{ marginRight: '8px' }}>
                        Add Comment
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleSubmit} disabled={isLoading}>
                        {isLoading ? <CircularProgress size={24} /> : 'Submit to Assistant'}
                    </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6">Comments:</Typography>
                    <List>
                        {[...inputs, lastInput].map((input, index) => (
                            <ListItem key={index}>{input}</ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>
            {renderResponse()}
        </Container>
    );
};

export default GPTAssistant;
