import { AWS_SERVER_URL } from "../CampaignConst";
import { Campaign, RawCampaign } from "../CampaignInterface";
import { camelToSnake, snakeToCamel } from "./UsersService";

export interface AddCampaignRequest {
    userId: string;
    rawCampaign: RawCampaign;
    bucketName?: string;
}

export async function addCampaign(request: AddCampaignRequest): Promise<Campaign> {
    const lambdaUrl = 'https://57tymhsptc3rdmydnqmoywovea0twfty.lambda-url.us-east-1.on.aws/'
    console.log(JSON.stringify(request))

    const snakeCaseRequest = camelToSnake(request);
    console.log(JSON.stringify(snakeCaseRequest))

    const response = await fetch(`${lambdaUrl}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(snakeCaseRequest)
    });

    if (!response.ok) {
        throw new Error(`Error adding campaign: ${response.statusText}`);
    }
    const finalResponse = await response.json();
    return snakeToCamel(finalResponse);
}


export async function getCampaignsByUserId(userId: string): Promise<Campaign[]> {
    const lambdaUrl = 'https://55qobnroz76n3yqaxionxdtuki0rnnzz.lambda-url.us-east-1.on.aws/'

    const response = await fetch(`${lambdaUrl}?user_id=${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error(`Error fetching campaigns: ${response.statusText}`);
    }
    const finalResponse = await response.json();
    return snakeToCamel(finalResponse);
}

export async function editCampaign(campaignId: string, updateRequest: RawCampaign): Promise<Campaign> {
    const lambdaUrl = 'https://xolpb2zzuxxv6dg47q3q5a6t7m0idals.lambda-url.us-east-1.on.aws/'
    const snakeCaseRequest = camelToSnake(updateRequest);
    const response = await fetch(`${lambdaUrl}?campaign_id=${campaignId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(snakeCaseRequest)
    });

    if (!response.ok) {
        throw new Error(`Error updating campaign: ${response.statusText}`);
    }
    const finalResponse = await response.json();
    return snakeToCamel(finalResponse);
    
}

export async function deleteCampaign(campaignId: string): Promise<{ message: string }> {
    const lambdaUrl = 'https://o5jjskgqomrmxlvx4uhac6wybq0czxis.lambda-url.us-east-1.on.aws/'

    const response = await fetch(`${lambdaUrl}?campaign_id=${campaignId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error(`Error deleting campaign: ${response.statusText}`);
    }
    const finalResponse = await response.json();
    return snakeToCamel(finalResponse);
}
