import React from 'react';
import { Toolbar, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import logo from '../../assets/logo.png';
import { useStyles } from './FeedStyle'; // Adjust the path as necessary
import { LogoutOptions } from '@auth0/auth0-spa-js';
import { useNavigate } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';

interface Props {
    isMobile: boolean,
    logout: (options?: LogoutOptions | undefined) => Promise<void>
}

const Header = ({ isMobile, logout }: Props) => {
    const theme = useTheme();
    const classes = useStyles; // If you have specific styles for the header, consider creating a separate style file.
    const navigate = useNavigate();
    const { user } = useAuth0();
    const isAdmin = ['yosefbyd@gmail.com', 'rirani888@gmail.com', 'zacharybamberger1@gmail.com'].includes(user?.email ?? "--");
    return (
        <Toolbar>
            <Typography variant="h6" sx={classes.title}>
                <img style={{ width: `${isMobile ? '120px' : '200px'}` }} src={logo} alt="Logo"></img>
            </Typography>

            <Button size={isMobile ? 'small' : 'large'} onClick={() => { navigate("/About") }}
                sx={classes.navButton}>About</Button>
            <Button size={isMobile ? 'small' : 'large'} onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
                sx={classes.navButton}>Log out</Button>
            {isAdmin && 
            <Button size={isMobile ? 'small' : 'large'} onClick={() => {navigate("/Logs")}}
                    sx={classes.navButton}>Logs</Button>
            }
        </Toolbar>
    );
};

export default Header;
