import { Grid, Stack, Typography, Card, CardContent, Divider, useTheme, useMediaQuery, CardActions, Button } from "@mui/material";
import { FacebookComment, FacebookPost, FetchedAgent, FetchedCampaign } from "../../../utils/interfaces";
import PostCampaignCard from "./PostCampaignCard";
import CommentTable from "./TreeCommentTable";
import { useState } from "react";
import CampaignAnswer from "./CampaignAnswer";
import { campaignPostStyles } from "./CampaignStyles";
import { Agent, Campaign } from "../CampaignInterface";

interface prop {
  index: number;
  selectedCampaign: Campaign;
  selectedAgent: Agent;
  post: FacebookPost;
}

const CampaignPost = ({ index, post, selectedCampaign, selectedAgent }: prop) => {
  const [selectedComment, setSelectedComment] = useState<FacebookComment | undefined | null>();
  const [isSelected, setIsSelected] = useState<boolean>(false);
  
  const styles = campaignPostStyles;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleIsSelected = ()=>{
    setIsSelected(!isSelected);
  }
  return (
    <Card key={index} sx={styles.card}>
      <CardContent>
        <Grid container spacing={4}>
          {post.url && <Grid item xs={12} sm={6}>
            <PostCampaignCard url={post.url} />
          </Grid>}
          {!post.url && <Grid item xs = {1} />}
          <Grid item xs={12} sm={post.url ? 6 : 10}>
            <Stack spacing={2}>
              <Typography variant="h6" sx={styles.commentsTitle}>
                Post Comments
              </Typography>
              <CommentTable
                selectedComment={selectedComment}
                setSelectedComment={setSelectedComment}
                campaignId={selectedCampaign.campaignId}
                selectedAgent={selectedAgent}
                post={post}
              />
             
            </Stack>
          </Grid>
          {!post.url && <Grid item xs = {1} />}

          <Grid item xs={1} ></Grid>
          <Grid  item xs={9} sm={10}>
 {(isSelected || selectedComment) && (
                <>
                  <Divider />
                  {selectedComment? 
                  <CampaignAnswer
                    isPrepeared={selectedComment.isPrepeared?.isPrepeared}
                    potentialAnswer={selectedComment.isPrepeared?.answer}
                    campaignId={selectedCampaign.campaignId}
                    agent={selectedAgent}
                    commentId={selectedComment.id}
                    content={selectedComment.content}
                    author={selectedComment.author ? selectedComment.author : { name: "", id: "-1" }}
                    post={post}
                    isOpen={true}
                  />
                : 
              <CampaignAnswer
                isPrepeared={true}
                potentialAnswer={post.potentialAnswer}
                campaignId={selectedCampaign.campaignId}
                agent={selectedAgent}
                commentId={post.id}
                content={post.message}
                author={{ name: "", id: "-1" }}
                post={post}
                isOpen={true}
              />
                }
                </>
              )}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={styles.cardAction} >
        {(!isSelected && !selectedComment) && <Button onClick={()=>handleIsSelected()} variant="contained"> Respond To The Post</Button>}
      </CardActions>
    </Card>
  );
};

export default CampaignPost;