
import { createTheme } from '@mui/material/styles';

export  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,       // phone 0 - 700
        sm: 765,     // tablet 700 - 1400
        md: 1280,     // desktop 1280 - 1980
        lg: 1980,    // big desktop 1920 +
      },
    },
  });