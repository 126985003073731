import { User, useAuth0 } from "@auth0/auth0-react";
import { getPostUrlId } from "./PostCardHelperFunctions";
import { DATABASE_BASEURL, LOGS_DB_CONTAINER, LogsType, POSTS_DB } from "./constant";
import { Platforms } from "./interfaces";
import { recognizePlatform } from "./postUtils";

interface logsData {
  text: string,
  user: User,
  date : Date
}

export async function fetchPostDetails(url: string): Promise<{ text: string, likes: number, image_url: string }> {
  const apiType = recognizePlatform(url);
  try {
    if (apiType === Platforms.twitter) {
      const workerUrl = `https://parse-post.yosefbyd.workers.dev/?api=${apiType}&twitId=${encodeURIComponent(getPostUrlId(url))}`;
      const response = await fetch(workerUrl);
      if (!response.ok) {
        throw new Error(`Worker responded with status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);
      const text = data.tweet.full_text
      const details = {
        text: text ?? "not found",
        likes: data.tweet.favorite_count ?? -1,
        image_url: data.tweet.entities.media[0].media_url_https ?? "not found"
      }
      console.log(details);
      return details;
    }

    if (apiType === Platforms.instagram) {
      const workerUrl = `https://parse-post.yosefbyd.workers.dev/?api=${apiType}&mediaUrl=${encodeURIComponent(getPostUrlId(url))}`;
      console.log(workerUrl);
      const response = await fetch(workerUrl);
      if (!response.ok) {
        throw new Error(`Worker responded with status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data)
      console.log('data')
      return {
        text: data.edge_media_to_caption.edges[0].node.text ?? "not found",
        likes: data.edge_media_preview_like.count ?? -1,
        image_url: data.display_url ?? "not found"
      };

    }
    else {
      return { text: "apiType not found", likes: -1, image_url: "not found" }
    }
  } catch (error) {
    console.error('Error fetching post details:' + error);
    return { text: "Error", likes: -1, image_url: "not found" };
  }
}

export async function addPostToDatabase(data: object, collection: string, database: string) {
  try {
    const dataWithTimestamp = {
      ...data,
      timestamp: new Date().toISOString(),
    };
    console.log(`the object sent to database is `, dataWithTimestamp);

    const workerEndpoint = `https://rhetoric-ai-database.azurewebsites.net/api/add_post?database=${database}&container=${collection}`;
    const response = await fetch(workerEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataWithTimestamp)
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    if (response.status === 400) {
      return "This post is unavailable. Try another!.";
    }
    console.log('added succeed!')
  } catch (error) {
    console.error('Error sending data to the worker:' + error as string);
  }
}

export async function addLogsToDatabase(data: object, type: LogsType) {
  try {
    const dataWithTimestamp = {
      ...data,
      id: Date.now().toString(),
      type: type,
      timestamp: new Date().toISOString(),
    };

    const workerEndpoint = `https://rhetoric-ai-database.azurewebsites.net/api/add_post?database=${POSTS_DB}&container=${LOGS_DB_CONTAINER}`;
    
    const response = await fetch(workerEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataWithTimestamp)
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    if (response.status === 400) {
      return "This post is unavailable. Try another!.";
    }
    console.log('added succeed!')
  } catch (error) {
    console.error('Error sending data to the worker:' + error as string);
  }
}

export async function addUserToDatabase(user: string, collection: string, database: string, itemId: number) {
  try {
    const databaseEndpoint = `${DATABASE_BASEURL}update_post_answered_by?database=${database}&container=${collection}&itemId=${itemId}&username=${user}`;
    const response = await fetch(databaseEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    if (response.status === 400) {
      return "This post is unavailable. Try another!.";
    }
    if (response.status === 200) {
      console.log("pushed data successfuly, the response is", response.json());
    }

  } catch (error) {
    console.error('Error sending data to the worker:' + error as string);
  }
}

export async function addBookmarkForUser(user: string, collection: string, database: string, itemId: number) {
  try {
    const databaseEndpoint = `${DATABASE_BASEURL}update_post_bookmarked_by?database=${database}&container=${collection}&itemId=${itemId}&username=${user}`;
    const response = await fetch(databaseEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    if (response.status === 400) {
      return "This post is unavailable. Try another!.";
    }
    if (response.status === 200) {
      console.log("pushed data successfuly, the response is", response.json());
    }

  } catch (error) {
    console.error('Error sending data to the worker:' + error as string);
  }
}

// export async function getsLogsFromsServer(url: string): Promise<logsData> {


// }


export async function removeBookmarkForUser(user: string, collection: string, database: string, itemId: number) {
  try {
    const databaseEndpoint = `${DATABASE_BASEURL}remove_post_bookmark_by?database=${database}&container=${collection}&itemId=${itemId}&username=${user}`;
    const response = await fetch(databaseEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    if (response.status === 400) {
      return "This post is unavailable. Try another!.";
    }
    if (response.status === 200) {
      console.log("pushed data successfuly, the response is", response.json());
    }

  } catch (error) {
    console.error('Error sending data to the worker:' + error as string);
  }

}