import React, { SyntheticEvent, useState } from 'react';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
  useTheme,
  Snackbar,
  Divider,
  TextField,
  CircularProgress,
} from '@mui/material';
import { Campaign, RawCampaign } from '../CampaignInterface';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { deleteCampaign, editCampaign } from '../EndpointService/CampaignService';

interface CampaignSelectorProps {
  selectedCampaign: Campaign;
  setSelectedCampaign: React.Dispatch<React.SetStateAction<Campaign>>;
  campaigns: Campaign[];
  fetchAgents: (campaignId: string) => Promise<void>;
  onAddCampaign: () => void;
  updateCampaigns:  () => Promise<void>;

}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CampaignSelector: React.FC<CampaignSelectorProps> = ({
  selectedCampaign,
  setSelectedCampaign,
  campaigns,
  updateCampaigns,
  fetchAgents,
  onAddCampaign,
}) => {
  const [loading, setLoading] = useState(false);
  const [expandedCampaign, setExpandedCampaign] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [editingCampaign, setEditingCampaign] = useState<Campaign | null>(null);
  const [campaignDetails, setCampaignDetails] = useState<RawCampaign>({
    campaignName: '',
    worldSetup: '',
    reasonForCampaignExistence: '',
    campaignGuidelines: '',
    targetAudience: '',
    campaignStrengths: '',
    campaignWeaknesses: '',
    campaignOpportunities: '',
    campaignThreats: '',
  });
  const theme = useTheme();

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedCampaign(isExpanded ? panel : null);
  };

  const handleCampaignSelect = (event: React.MouseEvent, campaign: Campaign) => {
    event.stopPropagation();
    setSelectedCampaign(campaign);
    fetchAgents(campaign.campaignId ?? '');
    setSnackbarOpen(true);
    setExpandedCampaign(null);
  };

  const handleSnackbarClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleEditCampaign = (campaign: Campaign) => {
    setEditingCampaign(campaign);
    setCampaignDetails({
      campaignName: campaign.campaignName,
      worldSetup: campaign.worldSetup || '',
      reasonForCampaignExistence: campaign.reasonForCampaignExistence || '',
      campaignGuidelines: campaign.campaignGuidelines || '',
      targetAudience: campaign.targetAudience || '',
      campaignStrengths: campaign.campaignStrengths || '',
      campaignWeaknesses: campaign.campaignWeaknesses || '',
      campaignOpportunities: campaign.campaignOpportunities || '',
      campaignThreats: campaign.campaignThreats || '',
    });
  };

  const handleSaveCampaign = async () => {
    if (editingCampaign) {
      try {
        setLoading(true);
        const updatedCampaign = await editCampaign(editingCampaign.campaignId, campaignDetails);
        await updateCampaigns()

        setSnackbarOpen(true);
        setEditingCampaign(null);
        setSelectedCampaign(updatedCampaign);

      } catch (error) {
        console.error('Error editing campaign:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCancelEdit = () => {
    setEditingCampaign(null);
  };

  const handleDeleteCampaign = async (campaignId: string) => {
    try {
      setLoading(true);
      await deleteCampaign(campaignId);
      await updateCampaigns()

      setSnackbarOpen(true);
      setSelectedCampaign({} as Campaign); // Reset selected campaign

      
    } catch (error) {
      console.error('Error deleting campaign:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ padding: theme.spacing(2) }}>
      <Typography variant="h6" gutterBottom>
        <strong>Current Campaign:</strong> {selectedCampaign.campaignName || 'None selected'}
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ mb: theme.spacing(2) }}>
        Available Campaigns:
      </Typography>
      {loading && (
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left:  {xs: 330/2, sm: 600/2, md: 700/2},
            right: 0,
            bottom: 0,
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box sx={{opacity: loading ? 0.7: 1}}>
      {campaigns.map((campaign) => (
        <Accordion
          key={campaign.campaignId}
          expanded={expandedCampaign === campaign.campaignId}
          onChange={handleAccordionChange(campaign.campaignId)}
          sx={{
            backgroundColor:
              selectedCampaign.campaignId === campaign.campaignId ? theme.palette.action.selected : 'inherit',
            boxShadow: theme.shadows[1],
            '&:hover': {
              boxShadow: theme.shadows[4],
            },
            transition: theme.transitions.create(['box-shadow'], {
              duration: theme.transitions.duration.shortest,
            }),
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Stack direction="row" alignItems="center" width="100%" justifyContent="space-between">
              <Typography variant="body1">{campaign.campaignName}</Typography>
              {!(selectedCampaign.campaignId === campaign.campaignId) && (
                <Button
                  variant={selectedCampaign.campaignId === campaign.campaignId ? 'contained' : 'outlined'}
                  color={selectedCampaign.campaignId === campaign.campaignId ? 'primary' : 'secondary'}
                  onClick={(event) => handleCampaignSelect(event, campaign)}
                  sx={{
                    color: selectedCampaign.campaignId === campaign.campaignId ? '#1976d2' : '',
                    boxShadow: selectedCampaign.campaignId === campaign.campaignId ? theme.shadows[2] : 'none',
                    '&:hover': {
                      boxShadow: theme.shadows[4],
                    },
                  }}
                >
                  Select
                </Button>
              )}
            </Stack>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(2),
              padding: theme.spacing(2),
              backgroundColor: '#f9f9f9',
            }}
          >
            {editingCampaign?.campaignId === campaign.campaignId ? (
              <>
                <TextField
                  label="Campaign Name"
                  value={campaignDetails.campaignName}
                  onChange={(e) => setCampaignDetails({ ...campaignDetails, campaignName: e.target.value })}
                  fullWidth
                  size="small"
                  InputProps={{ style: { fontSize: '1rem' } }}
                />
                <TextField
                  label="World Setup"
                  value={campaignDetails.worldSetup}
                  onChange={(e) => setCampaignDetails({ ...campaignDetails, worldSetup: e.target.value })}
                  fullWidth
                  size="small"
                  InputProps={{ style: { fontSize: '1rem' } }}
                  multiline
                />
                <TextField
                  label="Reason For Campaign Existence"
                  value={campaignDetails.reasonForCampaignExistence}
                  onChange={(e) =>
                    setCampaignDetails({ ...campaignDetails, reasonForCampaignExistence: e.target.value })
                  }
                  fullWidth
                  size="small"
                  InputProps={{ style: { fontSize: '1rem' } }}
                  multiline
                />
                <TextField
                  label="Campaign Guidelines"
                  value={campaignDetails.campaignGuidelines}
                  onChange={(e) => setCampaignDetails({ ...campaignDetails, campaignGuidelines: e.target.value })}
                  fullWidth
                  size="small"
                  InputProps={{ style: { fontSize: '1rem' } }}
                  multiline
                />
                <TextField
                  label="Target Audience"
                  value={campaignDetails.targetAudience}
                  onChange={(e) => setCampaignDetails({ ...campaignDetails, targetAudience: e.target.value })}
                  fullWidth
                  size="small"
                  InputProps={{ style: { fontSize: '1rem' } }}
                  multiline
                />
                <TextField
                  label="Campaign Strengths"
                  value={campaignDetails.campaignStrengths}
                  onChange={(e) => setCampaignDetails({ ...campaignDetails, campaignStrengths: e.target.value })}
                  fullWidth
                  size="small"
                  InputProps={{ style: { fontSize: '1rem' } }}
                  multiline
                />
                <TextField
                  label="Campaign Weaknesses"
                  value={campaignDetails.campaignWeaknesses}
                  onChange={(e) => setCampaignDetails({ ...campaignDetails, campaignWeaknesses: e.target.value })}
                  fullWidth
                  size="small"
                  InputProps={{ style: { fontSize: '1rem' } }}
                  multiline
                />
                <TextField
                  label="Campaign Opportunities"
                  value={campaignDetails.campaignOpportunities}
                  onChange={(e) => setCampaignDetails({ ...campaignDetails, campaignOpportunities: e.target.value })}
                  fullWidth
                  size="small"
                  InputProps={{ style: { fontSize: '1rem' } }}
                  multiline
                />
                <TextField
                  label="Campaign Threats"
                  value={campaignDetails.campaignThreats}
                  onChange={(e) => setCampaignDetails({ ...campaignDetails, campaignThreats: e.target.value })}
                  fullWidth
                  size="small"
                  InputProps={{ style: { fontSize: '1rem' } }}
                  multiline
                />
              </>
            ) : (
              <>
                <Typography variant="body2">
                  <strong>World Setup:</strong> {campaign.worldSetup}
                </Typography>
                <Divider />
                <Typography variant="body2">
                  <strong>Description:</strong> {campaign.reasonForCampaignExistence}
                </Typography>
                <Divider />
                <Typography variant="body2">
                  <strong>Strengths:</strong> {campaign.campaignStrengths}
                </Typography>
                <Divider />
                <Typography variant="body2">
                  <strong>Weaknesses:</strong> {campaign.campaignWeaknesses}
                </Typography>
                <Divider />
                <Typography variant="body2">
                  <strong>Opportunities:</strong> {campaign.campaignOpportunities}
                </Typography>
                <Divider />
                <Typography variant="body2">
                  <strong>Threats:</strong> {campaign.campaignThreats}
                </Typography>
                <Divider />
                <Typography variant="body2">
                  <strong>Guidelines:</strong> {campaign.campaignGuidelines}
                </Typography>
                <Divider />
                <Typography variant="body2">
                  <strong>Target Audience:</strong> {campaign.targetAudience}
                </Typography>
              </>
            )}
          </AccordionDetails>
          <AccordionActions>
            {editingCampaign?.campaignId === campaign.campaignId ? (
              <>
                <Button onClick={handleCancelEdit} variant="outlined">
                  Cancel
                </Button>
                <Button onClick={handleSaveCampaign} variant="contained" color="secondary">
                  Save
                </Button>
              </>
            ) : (
              <>
                <Button startIcon={<EditIcon />} variant="outlined" onClick={() => handleEditCampaign(campaign)}>
                  Edit Campaign
                </Button>
                <Button
                  startIcon={<DeleteIcon />}
                  variant="outlined"
                  color="error"
                  onClick={() => handleDeleteCampaign(campaign.campaignId)}
                >
                  Delete Campaign
                </Button>
              </>
            )}
          </AccordionActions>
        </Accordion>
      ))}
</Box>
      <Box sx={{ mt: theme.spacing(2) }}>
        <Button variant="contained" color="primary" onClick={onAddCampaign} startIcon={<AddIcon />}>
          Add Campaign
        </Button>
      </Box>

      <Snackbar open={snackbarOpen} autoHideDuration={1500} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success">
          Campaign Changed successfully!
        </Alert>
      </Snackbar>

    </Box>
  );
};

export default CampaignSelector;