import React, { SyntheticEvent, useState } from 'react';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
  useTheme,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Divider,
  CircularProgress,
} from '@mui/material';
import { Agent, Campaign, RawAgent } from '../CampaignInterface';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { deleteAgent, editAgent } from '../EndpointService/AgentService';

interface AgentSelectorProps {
  selectedAgent: Agent;
  setSelectedAgent: React.Dispatch<React.SetStateAction<Agent>>;
  agents: Agent[];
  selectedCampaign: Campaign;
  onAddAgent: () => void;
  updateAgents: () => Promise<void>;


}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AgentSelector: React.FC<AgentSelectorProps> = ({
  selectedAgent,
  setSelectedAgent,
  agents,
  selectedCampaign,
  onAddAgent,
  updateAgents
}) => {
  const [loading, setLoading] = useState(false);
  const [expandedAgent, setExpandedAgent] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [editingAgent, setEditingAgent] = useState<Agent | null>(null);
  const [agentDetails, setAgentDetails] = useState<RawAgent>({
    agentName: '',
    agentDescription: '',
    agentDomain: '',
    platformName: '',
    model: '',
    agentTools: [],
    agentGuidelines: '',
  });
  const theme = useTheme();

  if (!agents) {
    return <>No Agents Available for this Campaign!</>
  }

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedAgent(isExpanded ? panel : null);
  };

  const handleAgentSelect = (event: React.MouseEvent, agent: Agent) => {
    event.stopPropagation();
    setSelectedAgent(agent);
    setSnackbarOpen(true);
    setExpandedAgent(null);
  };

  const handleSnackbarClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleEditAgent = (agent: Agent) => {
    setEditingAgent(agent);
    setAgentDetails({
      agentName: agent.agentName,
      agentDescription: agent.agentDescription,
      agentDomain: agent.agentDomain,
      platformName: agent.platformName,
      model: agent.model,
      agentTools: agent.agentTools || [""],
      agentGuidelines: agent.agentGuidelines,
    });
  };

  const handleSaveAgent = async () => {
    if (editingAgent) {
      try {
        setLoading(true);
        const updatedAgent = await editAgent(editingAgent.agentId, agentDetails);
        await updateAgents();
        setSelectedAgent(updatedAgent);
        setSnackbarOpen(true);
        setEditingAgent(null);
      } catch (error) {
        console.error('Error editing agent:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCancelEdit = () => {
    setEditingAgent(null);
  };

  const handleDeleteAgent = async (agentId: string) => {
    try {
      setLoading(true);
      await deleteAgent(agentId);
      setSnackbarOpen(true);
      setSelectedAgent({} as Agent); // Reset selected agent
      await updateAgents();
    } catch (error) {
      console.error('Error deleting agent:', error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <Box sx={{ padding: theme.spacing(2) }}>
      <Typography variant="h6" gutterBottom>
        <strong>Current Agent:</strong> {selectedAgent?.agentName || "None selected"}
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ mb: theme.spacing(2) }}>
        Available Agents:
      </Typography>
      {loading &&  (
        <Box
          sx={{
            position: 'fixed',
            top: '30%',
            left:  {xs: 330/2, sm: 600/2, md: 700/2},
            right: 0,
            bottom: 0,
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box sx={{opacity: loading ? 0.7: 1}}>
      {selectedAgent &&
        agents.map((agent) => (
          <Accordion
            key={agent.agentId}
            expanded={expandedAgent === agent.agentId}
            onChange={handleAccordionChange(agent.agentId)}
            sx={{
              backgroundColor:
                selectedAgent.agentId === agent.agentId ? theme.palette.action.selected : 'inherit',
              boxShadow: theme.shadows[1],
              '&:hover': {
                boxShadow: theme.shadows[4],
              },
              transition: theme.transitions.create(['box-shadow'], {
                duration: theme.transitions.duration.shortest,
              }),
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Stack direction="row" alignItems="center" width="100%" justifyContent="space-between">
                <Typography variant="body1">{agent.agentName}</Typography>
                {!(selectedAgent.agentId === agent.agentId) && <Button
                  variant={selectedAgent.agentId === agent.agentId ? 'contained' : 'outlined'}
                  color={selectedAgent.agentId === agent.agentId ? 'primary' : 'secondary'}
                  onClick={(event) => handleAgentSelect(event, agent)}
                  sx={{
                    color: selectedAgent.agentId === agent.agentId ? '#1976d2' : '',
                    boxShadow: selectedAgent.agentId === agent.agentId ? theme.shadows[2] : 'none',
                    '&:hover': {
                      boxShadow: theme.shadows[4],
                    },
                  }}
                >
                  Select
                </Button>}
              </Stack>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(2),
                padding: theme.spacing(2),
                backgroundColor: '#f9f9f9',
              }}
            >
              {editingAgent?.agentId === agent.agentId ? (
                <>
                  <TextField
                    label="Agent Name"
                    value={agentDetails.agentName}
                    onChange={(e) => setAgentDetails({ ...agentDetails, agentName: e.target.value })}
                    fullWidth
                    size="small"
                    InputProps={{ style: { fontSize: '1rem' } }}
                  />
                  <TextField
                    label="Agent Description"
                    value={agentDetails.agentDescription}
                    onChange={(e) =>
                      setAgentDetails({ ...agentDetails, agentDescription: e.target.value })
                    }
                    fullWidth
                    size="small"
                    InputProps={{ style: { fontSize: '1rem' } }}
                    multiline
                  />
                  <TextField
                    label="Agent Domain"
                    value={agentDetails.agentDomain}
                    onChange={(e) => setAgentDetails({ ...agentDetails, agentDomain: e.target.value })}
                    fullWidth
                    size="small"
                    InputProps={{ style: { fontSize: '1rem' } }}
                  />
                  <TextField
                    label="Platform Name"
                    value={agentDetails.platformName}
                    onChange={(e) => setAgentDetails({ ...agentDetails, platformName: e.target.value })}
                    fullWidth
                    size="small"
                    InputProps={{ style: { fontSize: '1rem' } }}
                  />
                  <TextField
                    label="Model"
                    value={agentDetails.model}
                    onChange={(e) => setAgentDetails({ ...agentDetails, model: e.target.value })}
                    fullWidth
                    size="small"
                    InputProps={{ style: { fontSize: '1rem' } }}
                  />
                  <TextField
                    label="Agent Tools"
                    value={agentDetails.agentTools?.join(', ') ?? []}
                    onChange={(e) =>
                      setAgentDetails({
                        ...agentDetails,
                        agentTools: e.target.value.split(',').map((tool) => tool.trim()),
                      })
                    }
                    fullWidth
                    size="small"
                    InputProps={{ style: { fontSize: '1rem' } }}
                  />
                  <TextField
                    label="Agent Guidelines"
                    value={agentDetails.agentGuidelines}
                    onChange={(e) =>
                      setAgentDetails({ ...agentDetails, agentGuidelines: e.target.value })
                    }
                    fullWidth
                    size="small"
                    InputProps={{ style: { fontSize: '1rem' } }}
                    multiline
                  />
                </>
              ) : (
                <>
                  <Typography variant="body2">
                    <strong>Description:</strong> {agent.agentDescription}
                  </Typography>
                  <Divider />
                  <Typography variant="body2">
                    <strong>Domain:</strong> {agent.agentDomain}
                  </Typography>
                  <Divider />
                  <Typography variant="body2">
                    <strong>Platform:</strong> {agent.platformName}
                  </Typography>
                  <Divider />
                  <Typography variant="body2">
                    <strong>Guidelines:</strong> {agent.agentGuidelines}
                  </Typography>
                  <Divider />
                  <Typography variant="body2">
                    <strong>Model:</strong> {agent.model}
                  </Typography>
                  <Divider />
                  <Typography variant="body2">
                    <strong>Tools:</strong> {agent.agentTools ? agent.agentTools.join(', ') : ''}
                  </Typography>
                </>
              )}
            </AccordionDetails>
            <AccordionActions>
              {editingAgent?.agentId === agent.agentId ? (
                <>
                  <Button onClick={handleCancelEdit} variant="outlined">
                    Cancel
                  </Button>
                  <Button onClick={handleSaveAgent} variant="contained" color="secondary">
                    Save
                  </Button>
                </>
              ) : (
                <>
                  <Button startIcon={<EditIcon />} variant="outlined" onClick={() => handleEditAgent(agent)}>
                    Edit Agent
                  </Button>
                  <Button
                    startIcon={<DeleteIcon />}
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteAgent(agent.agentId)}
                  >
                    Delete Agent
                  </Button>
                </>
              )}
            </AccordionActions>
          </Accordion>
        ))}
      </Box>
      <Box sx={{ mt: theme.spacing(2) }}>
        <Button variant="contained" color="primary" onClick={onAddAgent} startIcon={<AddIcon />}>
          Add Agent
        </Button>
      </Box>
    </Box>
  );
};

export default AgentSelector;
