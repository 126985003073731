import { slidersValue } from "../../../utils/interfaces";

export enum Tone {
  LOGICAL = 'logical',
  AGGRESSIVE = 'aggressive',
  SARCASTIC = 'sarcastic',
}

export enum Length {
  ONE_SENTENCE = 'one sentence',
  TWO_SENTENCES = 'two sentences',
  FEW_SENTENCES = 'a few sentences',
  ONE_PARAGRAPH = 'one paragraph',
  FEW_PARAGRAPHS = 'a few paragraphs',
}

export enum Formality {
  FORMAL = 'formal',
  CASUAL = 'casual',
  SLANG = 'slang',
}

export enum Audience {
  PROGRESSIVE = 'progressive',
  MODERATE = 'moderate',
  CONSERVATIVE = 'conservative',
}

export function MapSliderValues(sliderValues: slidersValue){
  return {
    tone: mapTone(sliderValues.toneValue),
    length: mapLength(sliderValues.lengthValue),
    formality: mapLanguageStyle(sliderValues.languageStyleValue),
    targetAudiencePolitics: mapCommunicationTone(sliderValues.communicationToneValue),
  };
}

function mapTone(value: number): Tone {
  if (value == 1) return Tone.LOGICAL;
  if (value == 2) return Tone.AGGRESSIVE;
  return Tone.SARCASTIC;
}

function mapLength(value: number): Length {
  if (value == 1) return Length.ONE_SENTENCE;
  if (value == 2) return Length.TWO_SENTENCES;
  if (value == 3) return Length.FEW_SENTENCES;
  if (value == 4) return Length.ONE_PARAGRAPH;
  return Length.FEW_PARAGRAPHS;
}

function mapLanguageStyle(value: number): Formality {
  if (value == 1) return Formality.FORMAL;
  if (value == 2) return Formality.CASUAL;
  return Formality.SLANG;
}

function mapCommunicationTone(value: number): Audience {
  if (value == 1) return Audience.PROGRESSIVE;
  if (value == 2) return Audience.MODERATE;
  return Audience.CONSERVATIVE;
}