import { Height, Padding } from "@mui/icons-material";
import { theme } from "../../../theme";
import { makeStyles } from "@mui/material";
export const useStyles = {
  appBar: {
    background: "rgba(255,25,21,)",
  },
  title: {
    width: "100px",
    flexGrow: 1,
    fontWeight: "bold",
    color: "#FFF",
  },
  navButton: {
    background: "black",
    m: "5px",

    color: "#FFF",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
  },
};

export const CommentsStyle = {
  headerCell: {},
  tableContainer: {},
  row: {},
  detailText: {},
};

export const CardActionStyles = {
  outerCard: {
    borderRadius: 2,
    boxShadow: 3,
  },

  postAnimation: {
    animation: "fadeIn 2s ease-in-out",
    "@keyframes fadeIn": {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
  },

  cardHeader: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
  },
  cardActions: {
    justifyContent: "space-between",
  },

  AnswerCardActions: {
    display: "flex",
  },

  likeSection: {
    display: "flex",
    alignItems: "center",
  },

  likeIcon: {
    marginRight: { xs: "1px", sm: "0px", md: "4px" },
    width: { xs: "10px", sm: "15px", md: "25px" },
  },

  platformTag: {
    display: "inline-flex",
    alignItems: "center",
    marginLeft: "8px",
    borderRadius: "16px",
    padding: "4px 8px",
    color: "#BAB9B9",
  },

  fightBackButton: {
    backgroundColor: "#2962ff",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#0039cb",
    },
    textTransform: "none",
  },
  showCommentsButton: {
    backgroundColor: "#cdd6ee",
    color: "#000000",
    "&:hover": {
      backgroundColor: "#e2e2e4",
    },
    textTransform: "none",
  },
  SubmitButton: {
    backgroundColor: "black",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "rgb(73, 68, 68)",
    },
    marginRight: "5px",
  },
  ReportButton: {
    backgroundColor: "rgb(43, 0, 255)",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "hsl(250, 100.00%, 67.50%)",
    },
    marginRight: "5px",
  },
  Chip: {
    borderRadius: "3px",
    borderWidth: "0px",
    ml: 1,
  },
  EmbededPost: {
    padding: "10px 10px 10px 10px",
    position: "relative",
    width: "100%",
    height: { xs: `60vh`, sm: `55vh` },
  },
};
export const dialogWidth = { xs: "70vw", sm: "65vw", md: "60vw", lg: "60vw" };

export const AnswerCardStyles = {
  textField: {
    fontSize: "50px",
  },
  sliderMarkStyle: {
    paddingTop: "5px",
    color: "#acaaaa",
    top: { xs: "35px", sm: "20px" },
  },
  outerCard: {
    display: "flex",
    borderRadius: 2,
    boxShadow: 3,
    m: 3,
    width: "50%",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: "550px",
    marginButtom: "10px",
    height: "80%",
  },
  CardContent: {
    display: "flex",
    flexDirection: "column",
  },
  MobileOuterCard: {
    backgroundColor: "white",
    borderRadius: 2,
    boxShadow: 3,
    m: 3,
    width: "90vw",
  },
  cardHeader: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  cardActions: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "30px 16px",
  },
  postEmbedContainer: {
    width: "40%",
    m: "24px",
    height: "550px",
  },
  likeSection: {
    display: "flex",
    alignItems: "center",
  },

  likeIcon: {
    marginRight: "4px",
  },

  platformTag: {
    display: "inline-flex",
    alignItems: "center",
    marginLeft: "8px",
    borderRadius: "16px",
    padding: "4px 8px",
    color: "#BAB9B9",
  },

  fightBackButton: {
    backgroundColor: "#2962ff",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#0039cb",
    },
    textTransform: "none",
  },

  SubmitButton: {
    backgroundColor: "black",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "rgb(73, 68, 68)",
    },
    marginRight: "5px",
  },
  Chip: {
    fontSize: { xs: "", sm: "10px" },
    borderRadius: "3px",
    borderWidth: "0px",
    ml: 1,
  },
  EmbededPost: {
    height: "100%",
    width: "100%",
  },
  EmbededResponsiveHeight: {
    height: { xs: `60vh`, sm: `60vh` },
    width: "95%",
    marginLeft: "2.5%",
  },
  ResponsiveHeight: {
    height: { xs: `60vh`, sm: `60vh` },
  },
};
export const campaignPostStyles = {
  card: {
    mb: 4,
    borderRadius: 2,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  },

  container: {
    marginTop: "50px",
    justifyContent: "center",
  },
  postCardContainer: {
    display: "flex",
    justifyContent: "center",
    height: { xs: "auto", sm: "70vh" },
  },
  commentTableContainer: {
    display: "flex",
    height: { xs: "auto", sm: "70vh" },
  },
  stack: {
    width: "100%",
    direction: "column",
  },
  commentsTitle: {
    m: 0,
  },

  cardAction: {
    display: "flex",
    flexDirection: "row-reverse",
  },
};

export const treeCommentTableStyles = {
  container: {
    maxHeight: "100%",
    overflow: "auto",
    pr: 2,
  },
  authorName: {
    fontWeight: "bold",
    mr: 1,
  },
  treeView: {
    overflow: "auto",
  },
  commentHeader: {
    display: "flex",
    alignItems: "center",
    mb: 0.1,
  },
  commentBox: {
    display: "flex",
    flexDirection: "column",
    p: 0.5,
    borderRadius: 1,
    backgroundColor: "background.paper",
    boxShadow: 1,
    "&:hover": {
      backgroundColor: "action.hover",
    },
  },
  sentimentIcon: {
    marginLeft: "8px",
    fontSize: "1rem",
  },
  commentMetadata: {
    display: "flex",
    alignItems: "center",
    mt: 0.7,
    "& > *": {
      mr: 0.5,
    },
  },
};

export const postCampaignCardStyles = {
  outerCard: {
    display: "flex",
    borderRadius: 2,
    boxShadow: 3,
    m: "10px",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: "550px",
    marginButtom: "10px",
    overFlow: "scroll",
    maxHeight: "800px",
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  likeSection: {
    display: "flex",
    alignItems: "center",
  },
  platformTag: {
    display: "inline-flex",
    alignItems: "center",
    marginLeft: "8px",
    borderRadius: "16px",
    padding: "4px 8px",
    color: "#BAB9B9",
  },

  card: {
    borderRadius: 2,
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    Padding: "0px",
  },
  cardContent: {
    overFlow: "scroll",
  },
  platformText: {
    fontWeight: "bold",
    color: "text.secondary",
  },
  embededPost: {
    overFlow: "scroll",
  },
};

export const campaignAnswerStyles = {
  card: {
    borderRadius: 2,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  },
  contentContainer: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    alignItems: "stretch",
    gap: 2,
  },
  sliderControlsContainer: {
    flex: 1,
  },
  textFieldContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  loadingAnimation: {
    animation: "blink 3s linear infinite",
    "@keyframes blink": {
      "0%": { opacity: 1 },
      "50%": { opacity: 0 },
      "100%": { opacity: 1 },
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row",
    gap: 1,
    m: 1,
  },
  generateButton: {
    textTransform: "none",
  },
  replyButton: {
    textTransform: "none",
  },
  conversationContext: {
    backgroundColor: "background.paper",
    p: 1,
    borderRadius: 1,
    boxShadow: 1,
    mb: 2,
  },
};
export const campaignPageStyles = {
  container: {
    width: "100%",
  },
  loginButton: {
    m: 2,
  },
  contentContainer: {
    width: "90vw",
    minHeight: "350vh",
    background: "#f4f4f4",
    p: 2,
  },
  drawerContent: {
    p: 2,
    width: { xs: 330, sm: 600, md: 700 },
  },
  pageSelect: {
    width: "100%",
  },
  button: { mb: 2 },
};

export const campaignDisplayStyles = {
  container: {
    mb: 2,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    mt: 1,
  },
  button: {
    mr: 1,
  },
};

export const customConversationStyles = {
  card: {
    borderRadius: 2,
    boxShadow: 3,
    margin: 3,
    padding: 2,
    backgroundColor: "#f5f5f5",
  },
  textField: {
    backgroundColor: "#ffffff",
  },
  conversationContainer: {
    maxHeight: 400,
    overflow: "auto",
    padding: 2,
    backgroundColor: "#ffffff",
    borderRadius: 1,
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    minWidth: "100px",
    padding: 2,
  },
  controlsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 1,
  },
  button: {
    minWidth: 200,
  },
};

export const timerStyles = {
  timerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
    padding: "4px 8px",
    marginTop: "8px",
    minWidth: "100px",
  },
  timerText: {
    fontSize: "0.775rem",
    color: "primary.main",
  },
};
