import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, CircularProgress, Checkbox, FormControlLabel, FormGroup, Box } from '@mui/material';
import { fetchPostDetails, addPostToDatabase, addLogsToDatabase } from '../../utils/webServices';
import { getRandomInteger } from '../../utils/PostCardHelperFunctions';
import { Post } from '../../utils/interfaces';
import { User } from '@auth0/auth0-spa-js';
import { LOGSTYPE, POSTS_DB, POSTS_DB_CONTAINER } from '../../utils/constant';

interface AddPostModalProps {
    isAddModalOpen: boolean;
    newPostUrl: string;
    setNewPostUrl: (url: string) => void;
    setIsAddModelOpen: (state: boolean) => void
    closeAddModal: () => void;
    addedPosts: Post[],
    setAddedPosts: (posts: Post[]) => void;
    user: User | undefined;
}

const AddPostModal: React.FC<AddPostModalProps> = ({
    setIsAddModelOpen,
    isAddModalOpen,
    user,
    setAddedPosts,
    newPostUrl,
    addedPosts,
    setNewPostUrl,
    closeAddModal
}) => {

    const [isAddedPro, setIsAddedPro] = useState(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

    const toggleIsAddedPro = () => {
        setIsAddedPro(!isAddedPro);
    };

    const handleAddPost = async () => {
        setIsLoadingSubmit(true)
        if(newPostUrl != ""){
        const details = await fetchPostDetails(newPostUrl);
        const newPost : Post = {
            id: Date.now(),
            url: newPostUrl,
            image_url: details.image_url,
            text: details.text,
            likes: details.likes === -1 ? -1 : details.likes,
            proIsrael: isAddedPro,
            userAdded: true
        };
        console.log("adding post", newPost)
        setIsLoadingSubmit(false);
        addPostToDatabase({...newPost, id: Date.now().toString(), addedBy: user?.email || "not found"}, POSTS_DB_CONTAINER, POSTS_DB)
        addLogsToDatabase({...newPost, id: Date.now().toString(), user:user}, LOGSTYPE.ADD)
        setAddedPosts([newPost, ...addedPosts])
    }
        setIsAddModelOpen(false);
        setNewPostUrl('');
    };

    return (
        <Dialog open={isAddModalOpen} onClose={closeAddModal}>
            <DialogTitle>Add Your Post URL</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="postUrl"
                    label="Post URL"
                    type="url"
                    fullWidth
                    variant="standard"
                    value={newPostUrl}
                    onChange={(e) => setNewPostUrl(e.target.value)}
                />
                <FormGroup row>
                    <FormControlLabel
                        control={<Checkbox
                            checked={isAddedPro}
                            onChange={toggleIsAddedPro}
                            name="pro" />}
                        label="Pro-Israel"
                        value="pro"
                    />
                    <FormControlLabel
                        control={<Checkbox
                            checked={!isAddedPro}
                            onChange={toggleIsAddedPro}
                            name="anti" />}
                        label="Anti-Israel"
                        value="anti"
                    />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Box>
                    {isLoadingSubmit && <CircularProgress size={25}/>}
                    <Button onClick={closeAddModal}>Cancel</Button>
                    <Button onClick={handleAddPost}>Submit</Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default AddPostModal;
