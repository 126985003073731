import { Post } from "./interfaces";


export const getRandomVirality = () => {
  const options = [
  ,
    ,
    
  ];
  const randomIndex = Math.floor(Math.random() * options.length);
  return options[randomIndex];
};

export const getRandomInteger = (): number => {
  const min = 10000;
  const max = 300000;
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const generateLoremIpsum = (lengthType: number): string => {
  return " "}

export function extractTwitterID(url: string) {
  const parts = url.split('/');
  return parts[parts.length - 1];
}

export function getPostUrlId(url: string): string {
  try {
    const parsedUrl = new URL(url);
    const domain = parsedUrl.hostname;
    const path = parsedUrl.pathname;

    const twitterStatusRegex = /\/status\/(\d+)/;

    if ((domain === 'twitter.com' || domain === 'x.com') && path.match(twitterStatusRegex)) {
      const match = path.match(twitterStatusRegex);
      return match ? match[1] : '';
    }

    const instagramRegex = /instagram\.com\/(p|reel)\/([^/?]+)/;
    if (domain === 'instagram.com' && path.match(instagramRegex)) {
      const instagramMatch = path.match(instagramRegex);
      return instagramMatch ? instagramMatch[2] : '';
    }

  } catch (e) {
    console.error("Invalid URL:", e);
  }

  return '';
}

export function shuffleArray(array : Post[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array
}

export function getRandomSampleOfPosts(allPosts : Post[], postToShow: number) {
  shuffleArray(allPosts);
  return allPosts.slice(0, postToShow);
}
