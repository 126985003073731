import React from 'react';
import { Box, Button, FormControl, FormGroup, IconButton, InputLabel, MenuItem, Select, Typography, Slide, FormControlLabel, Checkbox, ToggleButton, ToggleButtonGroup } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import { SelectChangeEvent } from '@mui/material';
import { FilterPosition } from './Feed';

interface FilterMenuProps {
    handleFilterPositionChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    filterPosition: FilterPosition;
    sortCriteria: string;
    filterPlatform: string;
    handleSortChange: (event: SelectChangeEvent) => void;
    handleFilterPlatformChange: (event: SelectChangeEvent) => void;
    showFilterMenu: boolean;
    toggleFilterMenu: () => void;
}

const FilterMenu: React.FC<FilterMenuProps> = ({
    handleFilterPositionChange,
    filterPosition,
    sortCriteria,
    filterPlatform,
    handleSortChange,
    handleFilterPlatformChange,
    showFilterMenu,
    toggleFilterMenu
}) => {

    return (
        <Box>
            <Button variant="outlined" onClick={toggleFilterMenu} startIcon={<FilterListIcon />}>
                Filter
            </Button>
            <Slide direction="left"
                in={showFilterMenu} mountOnEnter unmountOnExit>
                <Box sx={{
                    mt: "40px",
                    background: 'white',
                    boxShadow: 3,
                    borderRadius: 2,
                    padding: 2,
                    position: "absolute",
                    right: 0,
                    zIndex: 20,
                    width: '300px',
                    overflow: 'visible'
                }}>

                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Typography variant="h6" gutterBottom>
                            Sort and Filter
                        </Typography>
                        <IconButton aria-label="close" onClick={toggleFilterMenu}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <FormGroup row>
                      
                        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel>Sort By</InputLabel>
                            <Select
                                value={sortCriteria}
                                onChange={handleSortChange}
                                label="Sort By"
                            >
                                <MenuItem value="likes">Likes</MenuItem>
                                <MenuItem value="date">Date</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel>Social Network</InputLabel>
                            <Select
                                value={filterPlatform}
                                onChange={handleFilterPlatformChange}
                                label="Platform"
                            >
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="user">Added By Users</MenuItem>
                                <MenuItem value="instagram">Instagram</MenuItem>
                                <MenuItem value="twitter">Twitter</MenuItem>
                            </Select>
                        </FormControl>
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={filterPosition.pro}
                                    onChange={handleFilterPositionChange}
                                    name="pro" />}
                                label="Pro-Israel"
                                value="pro"
                            />
                            <FormControlLabel
                                control={<Checkbox
                                    checked={filterPosition.anti}
                                    onChange={handleFilterPositionChange}
                                    name="anti" />}
                                label="Anti-Israel"
                                value="anti"
                            />
                        </FormGroup>
                    </FormGroup>

                    <Button variant="contained" onClick={toggleFilterMenu} startIcon={<SortIcon />} sx={{ mt: 2 }}>
                        Apply Filters
                    </Button>
                </Box>
            </Slide>
        </Box>
    );
};

export default FilterMenu;
