import React from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import background from '../../assets/background3.png'; // Adjust the path as necessary
import { LogoutOptions } from '@auth0/auth0-spa-js';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import { CardActionStyles } from '../Feed/FeedStyle';
import AboutHeader from './AboutHeader';

interface Props {
    isMobile: boolean,
    logout: (options?: LogoutOptions | undefined) => Promise<void>,
    setIsReportModalOpen : (state: boolean) => void
}

const AboutIntroductionSection = ({ isMobile, logout, setIsReportModalOpen }: Props) => {
    const theme = useTheme();
    const styles = CardActionStyles;

    return (
        <Box sx={{
            backgroundImage: `url(${background})`,
            backgroundAttachment: 'fixed',
            backgroundSize: 'cover', zIndex: 0
        }}>
            <IconButton 
            sx={{...styles.ReportButton, position:'fixed', zIndex: 100, top:'93%', left:"30px"}} onClick={() =>
                     setIsReportModalOpen(true)}>
                     <ReportGmailerrorredOutlinedIcon />
            </IconButton>
            <Box sx={{ 
            backgroundColor: "rgba(0, 0, 251, 0.4)",
            color: 'white', zIndex: 1, height: '50vh' }}>
                <AboutHeader logout={logout} isMobile= {isMobile} />
                <Box 
                 sx={{ mt: { xs: '7%', sm: '0%' },
                  padding: {xs:'25px', sm: "20px"} }}>
                    <Typography
                        sx={{
                            fontSize: {
                                xs: theme.typography.h5.fontSize,
                                sm: theme.typography.h3.fontSize,
                                md: theme.typography.h3.fontSize

                            }
                        }}
                        gutterBottom
                    >
                          Shaping the Future of Digital Discourse
                    </Typography>
                   
                    <Typography
                        sx={{
                            fontSize: {
                                xs: theme.typography.subtitle1.fontSize,
                                sm: theme.typography.h5.fontSize,
                                md: theme.typography.h5.fontSize

                            }
                        }}
                    >
                       At Rhetoric AI, we leverage cutting-edge AI to foster a balanced and informed online environment. Join us in our mission to enhance digital narratives and combat misinformation, creating a more empathetic and truthful digital world.
                    </Typography>
                </Box>

            </Box>
        </Box>
    )

};

export default AboutIntroductionSection;